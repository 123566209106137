import { DispatchObject } from './DispatchObject'

export class GroupLink extends DispatchObject {
	static entityName = 'grouplink'

	setGroupId(groupId) {
		return this.set('sgid', groupId)
	}

	getGroupId() {
		return this.get('sgid')
	}
}
