/**
 * WEB Dispatch loading overlay
 * @author Rostovtsev Ruslan <swat@211.ru>
 */

define(
	[
		"dojo/dom",
	   	"dojo/dom-construct",
	   	"dojo/dom-style",
	   	"dojo/_base/lang",
		"./nls/load"
	]
	, function(
		dom,
		domConstruct,
		style,
		lang,
		nls
	) {
		return {
			_overlayId: "loadingOverlay",

			_ensureLoader: function() {
				this._overlay = dom.byId(this._overlayId) || domConstruct.create(
					"div"
					, {
						id: this._overlayId,
						"class": this._overlayId,
						innerHTML: '<div class="loadingMessage">&nbsp;</div>'
					}
					, dojo.body()
				);
				this._overlay.innerHTML = this._overlay.innerHTML.replace('&nbsp;', nls.loading);
			},

			show: function() {

				this._ensureLoader();

				// Show the overlay
				style.set(this._overlay, {
					display: "block",
					opacity: 1
				});

				return this;
			},

			hide: function() {

				// Hide the overlay with fade effect by CSS3
				style.set(this._overlay, "opacity", "0");

				setTimeout(function() {
					style.set(this._overlay, "display", "none");
				}.bind(this), 1000);

				return this;
			}
		};
	}
);
