import config from 'webapi/rpc/config'
import wapi from 'webapi/rpc/web'
import { set as _set } from 'lodash'
import { PromiseIterator } from './PromiseIterator'
import { DispatchObject } from './DispatchObject'
import Repository from './Repository'
import { GroupLink } from './GroupLink'
import { Transaction } from './Transaction'
import { AsyncLoaders } from './AsyncLoaders'
import { Role } from './Role'
import * as session from './Session'

/**
 * @class User
 * @extends DispatchObject
 *
 * FIXME API getTrackEvents(fromTimestamp. toTimestamp: monitoring objects have to be fetched
 * before track events otherwise DispatchEvent.getMonitoringObject
 * can not be synchronous
 */
export class User extends DispatchObject {
	// FIXME implement getOwnRole
	// FIXME implement getRoleGroup
	// FIXME implement getTrackerEvents
	static entityName = 'user'

	/** @fn getLogin()
 * @memberof User
 * @return String
 */
	getLogin() {
		return this.get('login')
	}

	setLogin(login) {
		this.set('login', login)
		return this
	}

	/** @fn getWelcomeName()
 * @memberof User
 * @return String
 */
	getWelcomeName() {
		return this.get('welcome_name')
	}

	setWelcomeName(welcome_name) {
		this.set('welcome_name', welcome_name)
		return this
	}

	getLastLogin() {
		return new Date(this.get('last_login') * 1000)
	}

	setLastLogin(last_login) {
		this.set('last_login', last_login)
		return this
	}

	getLastSession() {
		return new Date(this.get('last_session'))
	}

	setLastSession(last_session) {
		this.set('last_session', last_session)
		return this
	}

	/** @fn getEmail()
 * @memberof User
 * @return String
 */
	getEmail() {
		return this.get('email')
	}

	setEmail(email) {
		this.set('email', email)
		return this
	}

	/** @fn getPhone()
 * @memberof User
 * @return String
 */
	getPhone() {
		return this.get('phone')
	}

	setPhone(phone) {
		this.set('phone', phone)
		return this
	}

	/** @fn getEnabled()
 * @memberof User
 * @return Boolean
 */
	getEnabled() {
		return this.get('enabled')
	}

	setEnabled(enabled) {
		this.set('enabled', enabled)
		return this
	}

	getSrid() {
		return this.get('srid')
	}

	setSrid(srid) {
		this.set('srid', srid)
		return this
	}

	getIPFilter() {
		try {
			const res = this.getJson('ipfilter_json', [])
			if (!Array.isArray(res)) throw new Error('Unable to parse "ipfilter_json", result must be an array of string')
			return res
		} catch (e) {
			console.error(e)
			return []
		}
	}

	setIPFilter(value) {
		const isArrayOfString = Array.isArray(value) && value.every((item) => typeof item === 'string')
		if (!isArrayOfString) throw new Error('"value" argument must be an array of string')
		this.set('ipfilter_json', JSON.stringify(value))
	}

	getGroupLink() {
		return this._groupLinks || this.get('grouplink') || []
	}

	setGroupLink(sgidArray) {
		this._oldGroupLinks = this._oldGroupLinks || {}

		this.getGroupLink()
			.filter(({ id }) => Boolean(id))
			.forEach((groupLink) => {
				if (!this._oldGroupLinks[groupLink.sgid]) this._oldGroupLinks[groupLink.sgid] = []
				this._oldGroupLinks[groupLink.sgid].push(groupLink)
			})

		this._groupLinks = sgidArray
			.map((sgid) => {
				if (this._oldGroupLinks[sgid]?.length) return this._oldGroupLinks[sgid].pop()
				return { sgid }
			})

		Object.entries(this._oldGroupLinks).forEach(([sgid, links]) => {
			if (!links.length) delete this._oldGroupLinks[sgid]
		})

		return this
	}

	/**
	 * @private
	 * @param {*} value
	 */
	_parameters(value) {
		if (typeof (value) !== 'undefined') this._properties._parameters = value
		return this._properties._parameters
	}

	getParameters() {
		try {
			return this._parameters() || JSON.parse(this.get('parameters_json') || '{}')
		} catch (e) {
			return {}
		}
	}

	async changeParameters(p) {
		const oldParameters = this.getParameters()
		this._parameters(p)
		try {
			const result = await wapi.send('repo.setUserParameters', [this.getId(), JSON.stringify(p)])
			this.emit('paramsChanged', this._parameters())
			return result
		} catch (e) {
			this._parameters(oldParameters)
			throw e
		}
	}

	async changeParametersPart(path, params) {
		const result = await wapi.send('repo.setUserParametersPart', [path, params])
		const newParams = this.getParameters()
		_set(newParams, path, params)
		this._parameters(newParams)
		this.emit('paramsChanged', this._parameters())
		return result
	}

	hasChanges() {
		return super.hasChanges() || Boolean(this._oldGroupLinks)
	}

	getRole() {
		if (this._role == null) {
			this._role = new Role(this.getParentId(), this.get('role'))
		}
		return this._role
	}

	/**
	 * @private
	 * @param {string} password
	 */
	_getUserData(password) {
		return {
			login: this.getLogin(),
			password: (password || ''),
			welcomeName: this.getWelcomeName(),
			email: this.getEmail(),
			phone: this.getPhone(),
			enabled: this.getEnabled() || false,
			parentId: this.getParentId(),
			srid: this.getSrid(),
			rootGroupIds: this.getGroupLink().map((grouplink) => grouplink.sgid),
			parameters_json: this._parameters() ? JSON.stringify(this._parameters()) : (this.get('parameters_json') || '{}'),
			ipfilter_json: this.getIPFilter(),
		}
	}

	async new(password) {
		// TODO update id
		const result = await wapi.send('repo.registerNewUser', [this._getUserData(password)])
		if (session.current) session.current.change()
		return result
	}

	async update(transaction) {
		const tx = new Transaction()
		super.save(tx)

		const oldGroupLinks = this._oldGroupLinks || {}

		Object.values(oldGroupLinks).forEach((links) => {
			links.forEach((props) => {
				const groupLink = new GroupLink(this, props)
				groupLink.delete()
				groupLink.save(tx)
			})
		})

		this.getGroupLink()
			.filter((g) => !g.id)
			.forEach((groupLinkProperties) => {
				const groupLink = new GroupLink(this)
				groupLink.setGroupId(groupLinkProperties.sgid)
				groupLink.save(tx)
			})

		return tx.save(transaction).then((result) => {
			this._oldGroupLinks = {}
			return result
		})
	}

	async delete() {
		const tx = new Transaction()
		super.delete()
		super.save(tx)

		return tx.save()
	}

	/**
	 * @throws
	 */
	save() {
		throw new Error("User API object does NOT have 'save' method, use 'update' instead or you do something wrong")
	}

	async changePassword(password) {
		return wapi.send('repo.setUserPassword', [this.getId(), password])
	}
}

function fromJsonRpc(properties) {
	return new User(properties && properties.parent, properties)
}

export function get(recursive, group) {
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.user.bind(null, recursive)),
		fromJsonRpc,
	)
}
User.get = get

export async function getById(userId) {
	const properties = await Repository.byIdGetter.user(userId)
	return fromJsonRpc(properties)
}
User.getById = getById

export async function getUsersCount(args, extraArgs) {
	let allArgs = ['filtered', args]
	if (extraArgs) allArgs = allArgs.concat(extraArgs)
	return wapi.send('user.count', allArgs)
}
User.getUsersCount = getUsersCount

wapi.cacheRegister('user.count', {
	getPath: ([level, args, extra]) => {
		delete args.limit
		delete args.offset
		return [level, JSON.stringify(args), JSON.stringify(extra)].filter(Boolean)
	},
	maxSize: 10,
	expire: 5 * 60 * 1000,
})

function _invalidateCount() {
	wapi.cacheInvalidate('user.count')
}

Repository.registerInvalidator('user', _invalidateCount)

/**
 * Returns a non User objects (fields for grid only)
 * @return {Array<Object>}
 */
export function getAsyncLoaders(group, recursive) {
	return new AsyncLoaders({
		getExtraArguments: AsyncLoaders.extraArguments.groupRecursive.bind(null, group, !!recursive),
		requestGet: 'user.getPortion',
		countGet: 'user.count',
	})
}
User.getAsyncLoaders = getAsyncLoaders

wapi.cacheRegister('user.getPortion', {
	getPath: (params) => {
		return params.map((value) => JSON.stringify(value))
	},
	maxSize: 10,
	expire: config.portionPollingInterval,
})
Repository.registerInvalidator('user', () => wapi.cacheInvalidate('user.getPortion'))

export function getActiveSessionAsyncLoaders(group, recursive) {
	return new AsyncLoaders({
		getExtraArguments: AsyncLoaders.extraArguments.groupRecursive.bind(null, group, Boolean(recursive)),
		requestGet: 'user.session.getPortion',
	})
}
User.getActiveSessionAsyncLoaders = getActiveSessionAsyncLoaders

export async function endSession(sessionId) {
	if (typeof sessionId !== 'string') throw new Error('"sessionId" argument must be a string')
	return wapi.send('user.session.remove', [sessionId])
}
User.endSession = endSession

export async function exportActiveSessions(parameters, group, locale) {
	const params = parameters
	const lang = locale || config.locale

	if (group == null) throw new Error('"group" argument must be provided')
	if (params == null) throw new Error('"params" argument must be an object')
	if (typeof params.timezone !== 'string') throw new Error('"params.timezone" argument must be a string')
	if (typeof params.format !== 'string') throw new Error('"params.format" argument must be a string')

	if (params.filter?.conditions?.length === 0) delete params.filter
	if (params.sort?.length === 0) delete params.sort

	const [extraArgs] = await AsyncLoaders.extraArguments.groupRecursive(group, true)

	const apiParam = {
		timezone: params.timezone,
		format: params.format,
	}

	delete params.timezone
	delete params.format

	apiParam.fetchRequest = params
	apiParam.params = extraArgs
	apiParam.reportType = 'activeSessionsReport'

	return wapi.send('report.buildReport', [apiParam, lang], { withoutTimeout: true })
}
User.exportActiveSessions = exportActiveSessions

export async function current() {
	const properties = await wapi.send('user.getCurrent')
	return fromJsonRpc(properties)
}
User.current = current

Repository.registerListGetter('user', (recursive, groups) => wapi.send('user.get', { recursive, groups }))
Repository.registerByIdGetter('user', (id) => wapi.send('user.getById', id))
