import config from 'webapi/rpc/config'
import wapi from '../rpc/web'

function send(method, addressInfo, pattern, limit) {
	const arg = []
	if (addressInfo) arg.push(addressInfo)
	arg.push(pattern)
	if (limit > 0) arg.push(limit)
	return wapi.send(method, arg)
}

function getLimit(limit) {
	return limit > 0 ? limit : 10
}

export const VehicleType = {
	vehPedestrian: 0,
	vehBicycle: 1,
	vehCar: 2,
	vehTaxi: 3,
	vehBus: 4,
	vehEmergency: 5,
	vehDelivery: 6,
	vehTruck: 7,
}

const _vehicleTypeInverse = Object.entries(VehicleType)
	.reduce((res, [key, value]) => {
		// eslint-disable-next-line no-param-reassign
		res[value] = key
		return res
	}, {})

export class MapService {
	static VehicleType = VehicleType

	VehicleType = VehicleType

	/**
	 * @param {string} pattern
	 * @returns {Promise<object[]>}
	 */
	async getCountries(pattern) {
		return wapi.send('map.findCountries', [pattern])
	}

	/**
	 * @param {object} countryItem
	 * @param {string} pattern
	 * @param {number} limit
	 * @returns {Promise<object[]>}
	 */
	async getCities(countryItem, pattern, limit) {
		return send('map.findCities', countryItem, pattern, getLimit(limit))
	}

	/**
	 * @param {object} cityItem
	 * @param {string} pattern
	 * @param {number} limit
	 * @returns {Promise<object[]>}
	 */
	async getStreets(cityItem, pattern, limit) {
		return send('map.findStreets', cityItem, pattern, getLimit(limit))
	}

	/**
	 * @param {object} streetItem
	 * @param {string} pattern
	 * @param {number} limit
	 * @returns {Promise<object[]>}
	 */
	async getBuildings(streetItem, pattern, limit) {
		return send('map.findBuildings', streetItem, pattern, getLimit(limit))
	}

	/**
	 *
	 * @param {number} longitude
	 * @param {number} latitude
	 * @param {string?} lang
	 * @returns {Promise<AddressInfoEntry>}
	 */
	async getNearestAddress(longitude, latitude, lang) {
		const locale = lang || config.locale
		return wapi.send('map.address', [longitude, latitude, locale])
	}

	/**
	 * @param {LatLng[]} coordList
	 * @param {string} lang
	 * @returns {Promise<AddressInfoEntry[]>}
	 */
	async getNearestAddressList(coordList, lang) {
		if (!coordList || !coordList.length) return Promise.resolve([])
		const locale = lang || config.locale
		return wapi.send('map.addressList', [coordList, locale])
	}

	/**
	 * @param {LatLng[]} coordList
	 * @param {string} lang
	 * @returns {Promise<AddressInfoEntry[]>}
	 */
	async getNearestAddressListFast(coordList, lang) {
		if (!coordList || !coordList.length) return Promise.resolve([])
		const locale = lang || config.locale
		return wapi.send('map.addressList.fast', [coordList, locale])
	}

	/**
	 * @param {Position[]} wayPointList - < Array[Position]
	 * @param {Number} vehicleType - < enum MapService.VehicleType
	 * @param {Object} kvArgs - {width, height, zoom}
	 * @param {Object} viewPoint - {lon, lat}
	 * */
	async buildRoute(wayPointList, vehicleType, kvArgs, viewPoint) {
		if (!(vehicleType in _vehicleTypeInverse)) {
			throw new TypeError('MapService.buildRoute: invalid vehicleType')
		}
		return wapi.send('map.buildRoute', [
			wayPointList.map((point) => ({
				lon: point.longitude,
				lat: point.latitude,
			})),
			vehicleType,
			{
				width: kvArgs.width,
				height: kvArgs.height,
				zoom: kvArgs.zoom,
				geopoint: { lon: viewPoint.longitude, lat: viewPoint.latitude },
			},
		])
	}

	/**
	 * @param {string} pattern
	 * @returns {Promise<AddressInfoEntry>}
	 */
	async patternToAddress(pattern) {
		return wapi.send('map.completeAddress', [pattern])
	}
}

wapi.cacheRegister('map.addressList', {
	getPath: (params) => [JSON.stringify(params)],
	maxSize: 1000,
})

wapi.cacheRegister('map.addressList.fast', {
	getPath: (params) => [JSON.stringify(params)],
	maxSize: 1000,
})

wapi.cacheRegister('map.address', {
	getPath: (params) => {
		const prec = 100000
		return [`${Math.floor(params[0] * prec)}:${Math.floor(params[1] * prec)}`]
	},
	maxSize: 1000,
})

export default new MapService()
