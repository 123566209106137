import { StoreScheme } from './StoreScheme'
import { Store } from './Store'

export function extensionPropertyMixin(SuperClass, entityName) {
	if (entityName.length < 1) {
		throw Error('Trying to add invalid extensionName property, empty extensionName')
	}

	return class ExtensionProperty extends SuperClass {
		async getExtensionScheme() {
			const schemeList = await StoreScheme.getByExtensionFor(this.getParentId(), entityName)
			return schemeList && schemeList.length === 1 ? schemeList[0] : null
		}

		updateAdditionalFields(scheme, store, storeData, transaction) {
			if (!scheme || !storeData) return this

			const newStore = store || new Store(scheme.getId())
			newStore.setExtensionFor(this.getId())
				.setData(storeData)
				.save(transaction)
			return this
		}

		getAdditionalFields() {
			return Store.getExtentionForData(entityName, [this.getId()])
		}

		delete(...args) {
			Store.deleteObjWithExtention(this)
			return super.delete(...args)
		}
	}
}
