/* eslint-disable import/no-named-as-default */
import wapi from 'webapi/rpc/web'
import { PromiseIterator } from './PromiseIterator'
import { DispatchObject } from './DispatchObject'
import * as Company from './Company'
import * as User from './User'
import * as Role from './Role'
import * as Tracker from './Tracker'
import * as MonitoringObject from './MonitoringObject'
import * as ReportTemplate from './ReportTemplate'
import * as Command from './Command'
import * as Task from './Task'
import Repository from './Repository'

export class Group extends DispatchObject {
	static entityName = 'group'

	isRoot = () => false

	/**
	 * @returns {string}
	 */
	getName() {
		return this.get('title')
	}

	/**
	 * @param {string} name
	 * @returns {this}
	 */
	setName(name) {
		return this.set('title', name)
	}

	/**
	 * @returns {Promise<string[]>}
	 */
	async getIds() {
		return [this.getId()]
	}

	/**
	 * @deprecated this is service related code
	 */
	getGroupRecursive() {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getCompany() {
		return Company.get(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getCompanyRecursive() {
		return Company.get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getRole() {
		return Role.getByGroup(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getRoleRecursive() {
		return Role.getByGroup(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getUserRecursive() {
		return User.get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getTracker() {
		return Tracker.get(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getTrackerRecursive() {
		return Tracker.get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getMonitoringObject() {
		return MonitoringObject.get(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getMonitoringObjectRecursive() {
		return MonitoringObject.get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getMonitoringObjectLoadersRecursive(options) {
		return MonitoringObject.asyncLoadersRecursive(this, options)
	}

	/**
	 * @deprecated this is service related code
	 */
	getMonitoringObjectLoaders(options) {
		return MonitoringObject.asyncLoaders(this, options)
	}

	/**
	 * @deprecated this is service related code
	 */
	getReportTemplate() {
		return ReportTemplate.get(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getReportTemplateRecursive() {
		return ReportTemplate.get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getCommand() {
		return Command.get(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getCommandRecursive() {
		return Command.get(true, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getTask() {
		return Task.get(false, this)
	}

	/**
	 * @deprecated this is service related code
	 */
	getTaskRecursive() {
		return Task.get(true, this)
	}
}

function fromJsonRpc(properties) {
	return new Group(properties && properties.parent, properties)
}

export async function getRoots() {
	const reqAggregator = wapi.getRequestAggregator({ depth: 5 }, 'group.roots')
	return wapi.send('group.roots', null, { requestAggregator: reqAggregator })
}
Group.getRoots = getRoots

export function get(recursive, rootGroup) {
	return new PromiseIterator(
		rootGroup.getIds()
			.then((groupIdList) => Promise.all([
				groupIdList,
				Repository.listGetter.group(groupIdList, recursive),
			]))
			.then(([groupIdList, result]) => {
				if (recursive) {
					const groupData = Repository.data('group')
					const recursiveResult = {}

					Object.values(groupData).forEach((group) => {
						let data = group

						while (data) {
							// eslint-disable-next-line no-loop-func
							const a = groupIdList.some((id) => data.parent === id || data.id === id)
							if (a) {
								recursiveResult[group.id] = group
								break
							}
							if (data.parent == null) break
							data = groupData[data.parent]
						}
					})

					return Object.values(recursiveResult)
				}

				return result
			}),
		fromJsonRpc,
	)
}
Group.get = get

export async function getById(id) {
	const props = await Repository.byIdGetter.group(id)
	return fromJsonRpc(props)
}
Group.getById = getById

Repository.registerListGetter('group', (groupsIdList, recursive) => wapi.send('group.get', { groups: groupsIdList, recursive }))
Repository.registerByIdGetter('group', (id) => wapi.send('group.getById', id))

wapi.cacheRegister('group.get', {
	getPath: (params) => [params.groups, params.recursive],
})

wapi.cacheRegister('group.roots', {
	getPath: () => [],
})

function _invalidate() {
	wapi.cacheInvalidate('group.roots', [])
	wapi.cacheInvalidate('group.get', [])
}

Repository.registerInvalidator('group', _invalidate)
