const _defaultConfig = {
	groupId: 'allObjectsRoot',
	eventsOnMapVisible: true,
	chat: {
		pollUnread: false,
	},
	vehicleHistory: {
		pageSize: 10,
	},
	notification: {
		closeListOnEmpty: false,
		openListOnNew: false,
	},
	trackChart: {
		count: 1,
	},
	objectMap: {
		icon: true,
		message: true,
		position: true,
		speed: true,
		hours: true,
		height: true,
		mileage: true,
		satellites: true,
		sensors: true,
		tracker: true,
		objectFields: true,
		group: true,
	},
}

type _DefaultConfig = typeof _defaultConfig

interface DefaultConfig extends _DefaultConfig {
	objectMap: _DefaultConfig['objectMap'] & {
		companyFields?: boolean
	}
}

const defaultConfig = _defaultConfig as DefaultConfig

if (VERSION_CONSUMER.includes('GLONASS')) {
	defaultConfig.objectMap.companyFields = true
}

export {
	defaultConfig
}
