import config from 'webapi/rpc/config'
import { JsonRpcClient } from './JsonRpcClient'

const jsonRpcClient = new JsonRpcClient({
	rpcUrl: 'rpc',
	wsUrl: 'ws',
	dispatchLongRequestDuration: config.dispatchLongRequestDuration,
	webSocketReconnectDuration: config.webSocketReconnectDuration,
	apiPrefix: 'api1.',
	get isDebug() {
		return config.isDebug
	},
})

export default jsonRpcClient
