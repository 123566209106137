function _convertDate(value, type) {
	let result = null
	if (value == null) return null
	if (typeof value !== 'string' && !Number.isNaN(parseInt(value, 10))) {
		result = new Date(value * 1000)
	} else {
		result = new Date(value)
	}

	if (type === 'date') result = new Date(result.getFullYear(), result.getMonth(), result.getDate())

	return Number.isNaN(result.valueOf()) ? null : result
}

/**
 * @param {DispatchObject} SuperClass
 * @param {string} propertyName
 * @param {string?} methodSuffix
 * @param {'date' | 'time'} type
 */
export function dateTimePropertyMixin(SuperClass, propertyName, methodSuffix, type) { // in case type="date"|"time"
	if (propertyName.length < 1) throw Error('Trying to add invalid dateTime property, empty name')
	const suffix = methodSuffix ?? propertyName.charAt(0).toUpperCase() + propertyName.slice(1)

	const comparator = (savedValue, newValue) => {
		const oldVal = _convertDate(savedValue, type)
		const newVal = _convertDate(newValue, type)
		if (oldVal && newVal) {
			return oldVal.valueOf() === newVal.valueOf()
		}
		if (!oldVal && !newVal) return true
		return false
	}
	const getter = function getter() {
		return _convertDate(this.get(propertyName), type)
	}
	const setter = function setter(date) {
		return this.set(
			propertyName,
			(date && date.toISOString) ? date.toISOString() : date,
			comparator,
		)
	}

	return class DateTimeProperty extends SuperClass {
		[`__getter__${propertyName}`] = getter.bind(this);
		[`__setter__${propertyName}`] = setter.bind(this);
		[`get${suffix}`] = getter.bind(this);
		[`set${suffix}`] = setter.bind(this)
	}
}
