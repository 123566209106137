/* eslint-disable no-param-reassign */
import config from 'webapi/rpc/config'
import { ReportTemplate } from './ReportTemplate'
import * as Utils from './Utils'
import webapi from '../rpc/web'

function tripStatusAndOverlapsHandler(params, template) {
	params.statusFilter = template.getTripStatusFilter()
	params.overlapsFilter = template.getOverlapsFilter()
	return params
}

function eventTypesHandler(params, template) {
	params.eventTypes = template.getEventTypes()
	return params
}

function fenceListHandler(params, template) {
	params.fences = template.getFenceList() || []
	return params
}

function eventReactionReport2Handler(params, template, filterMap) {
	const includeMap = { 'Принадлежность': 'subdivision' } // map of filterId -> key
	if (filterMap) {
		Object.entries(filterMap).forEach(([fid, value]) => {
			const key = includeMap[fid]
			if (!key) return
			params[key] = value
		})
	}
	return params
}

function convertEventsByPeriodFilter(filterList) {
	if (!Array.isArray(filterList)) return filterList
	const retval = {
		limit: 0,
		offset: 0,
		filter: { operator: 'and', conditions: [] },
		customFilter: {},
	}
	const extFieldsFilter = { operator: 'and', conditions: [] }
	filterList.forEach((filter) => {
		if (filter.isCustom) {
			if (filter.value) {
				retval.customFilter[filter.id] = filter.value
			}
		} else if (filter.isAdditional) {
			extFieldsFilter.conditions.push({
				condition: filter.condition || 'contain',
				columns: [filter.id],
				value: filter.value,
			})
		} else {
			retval.filter.conditions.push({
				condition: filter.condition || 'contain',
				columns: [filter.id],
				value: filter.value,
			})
		}
	})
	if (extFieldsFilter.conditions.length) {
		retval.customFilter.tripAdditionalFields = extFieldsFilter
	}
	return retval
}

function eventsByPeriodHandler(params, template) {
	params.filter = convertEventsByPeriodFilter(template.getFilter() || [])
	params.filter.customFilter = params.filter.customFilter || {}
	params.filter.customFilter.datePeriod = { from: params.time.begin, to: params.time.end }
	return params
}

const ReportTypeMap = {
	'general': {
		normal: true,
		hnd(params, template) {
			// FIXME: Use it for all normal reports?
			const skip = template.getSkip()
			if (skip) {
				params.skip = skip
			}
			const workingHours = template.getWorkingHours()
			if (workingHours) {
				params.workingHours = workingHours
			}
			const selectionMode = params.selectionMode || template.getSelectionMode()
			if (selectionMode != null) {
				params.selectionMode = selectionMode
			}
			const subtotal = template.getSubtotalColumn()
			if (subtotal) {
				params.aggregate = subtotal
			}
			// NOTE: Only general report use inverted agg type
			params.group = ReportTemplate.AggregationType[template.getAggregationType()]
			return params
		},
	},
	'byParamsReport': {},
	'alarmReport': {},
	'transportUsageWithReactionCountsReport': {},
	'transportUsageWithReactionCountsReport2': {},
	'dailyTransportUsageReport': {},
	'eventReactionReport': {},
	'eventReactionReport2': { hnd: eventReactionReport2Handler },
	'eventReactionReport3': { hnd: eventReactionReport2Handler },
	'monitoringObjectsRegionalReportByRegNumber': {},
	'monitoringObjectsRegionalReportByInn': {},
	'relayReport': {},
	'takingUnderControlReport': {},
	'userActions2Report': {},
	'maintenanceReport': {},
	'streetsDetailedReport': {
		normal: true,
		hnd(params, template) {
			params.streets = template.getStreetList()
			return params
		},
	},

	'speedingReport': {},
	'speedingInGeofencesReport': {
		hnd(params) {
			params.withGeofences = true
			return params
		},
	},

	'drivingQualitySummaryReport': {},
	'drivingQualityDetailedReport': {},

	'exportEventsWithReactionReport': {},
	'exportEventsWithoutReactionReport': {},

	'pairEventsDetailedReport': { hnd: eventTypesHandler },
	'pairEventsSummaryReport': { hnd: eventTypesHandler },

	'eventsDetailedReport': { hnd: eventTypesHandler },

	'routeTripReport': { hnd: tripStatusAndOverlapsHandler },
	'routeTripGeneralReport': {},

	'fencesDetailedReport': {
		normal: true,
		hnd(params, template) {
			params = fenceListHandler(params, template)
			params.detailed = true
			return params
		},
	},
	'generalFenceReport': { normal: true, hnd: fenceListHandler },

	'vehicleTripReport': { hnd: tripStatusAndOverlapsHandler },
	'vehicleTripGeneralReport': {},

	'vehiclesTableReport' : { normal: true },
	'trackersTableReport': { normal: true },

	'scheduleTripReport': { hnd: tripStatusAndOverlapsHandler },
	'scheduleTripGeneralReport': {},
	'comingMaintenanceReport': {},
	'tripPointDetailedReport': {},
	'activeVehiclesReport': {},
	'royaltyFeesReport': {},
	'agentReport': {},
	'streetsPassingReport': {},
	'eventsByPeriodDetailedReport': { hnd: eventsByPeriodHandler },
	'eventsByPeriodGeneralReport': { hnd: eventsByPeriodHandler },
	'eventsByPeriodNotSoDetailedReport': { hnd: eventsByPeriodHandler },
	'companyListReport': { req: 'report.companyListReport' },
	'geofenceVisitingReport': {},

	'trackDetailsReport': {},
	'shortTrackDetailsReport': {},
	'trackGroupedDetailsReport': {},
}

export class ReportService {
	/**
	 * parameters fields:
	 * @param {Object} time parameters (eg interval with begin/end properties)
	 * @param {Object} parameters
	 * @param {String} parameters.tz time zone IANA code e.g. 'Asia/Novosibirsk' or 'UTC+03:00'
	 * @param {String} parameters.lang e.g. 'ru-ru' or 'en-en'
	 * @param {String} parameters.format optional 'pdf'. Get base64-encoded PDF-file
	 * @param {String} parameters.title optional, should be set if options = 'pdf'
	 * @param {ReportTemplate || String} templateOrType
	 * @return {Promise} for objects
	 *  - {Array[String]} header (or columns) column keys
	 *  - {Array[Array[mixed]} rows report result
	 */
	async createReport(time, parameters, templateOrType, filterMap) {
		const template = (templateOrType instanceof ReportTemplate ? templateOrType : null)
		const type = (template ? template.getType() : templateOrType)

		await this._checkBaseReportParams(time, parameters)

		if (ReportTypeMap[type] == null) {
			throw new Error(`Unknown report type: ${type}`)
		}

		const reqParam = []
		const isNormalReport = (ReportTypeMap[type].normal || false)
		const lang = (parameters.lang || config.locale)
		let apiParam = {}

		apiParam.reportType = type

		if (parameters.title) {
			apiParam.title = parameters.title
		}

		if (parameters.objects) {
			apiParam.objects = parameters.objects
		}

		if (time != null) {
			apiParam.time = time
		}

		// used in alertControl module on export
		if (parameters.filter) {
			apiParam.filter = parameters.filter
		}

		if (parameters.filters) {
			apiParam.filters = parameters.filters
		}

		if ('sortDesc' in parameters) {
			apiParam.sortDesc = parameters.sortDesc
		}

		if (parameters.columns) {
			apiParam.columns = parameters.columns
		}

		if (template && isNormalReport && template.getFieldList instanceof Function) {
			apiParam.columns = template.getFieldList()
		}

		if (template && template.getAggregationType instanceof Function) {
			apiParam[isNormalReport ? 'group' : 'groupBy'] = template.getAggregationType()
		}

		if (parameters.format) {
			apiParam.format = parameters.format

			if (!apiParam.title && isNormalReport) {
				apiParam.title = template ? template.getName() : ''
				if (parameters.visibleColumns) {
					apiParam.visibleColumns = parameters.visibleColumns
				}
			}
		}

		const timezone = parameters.tz || parameters.timezone
		if (timezone) {
			// FIXME: temp solution - let backend choose itself
			apiParam.tz = timezone
			apiParam.timezone = timezone
		}

		if (parameters.selectedGroupName) {
			apiParam.selectedGroupName = parameters.selectedGroupName // HACK see Redmine #92324
		}

		if (parameters.columnsOrder) {
			apiParam.columnsOrder = parameters.columnsOrder
		}

		if (parameters.params) {
			apiParam.params = parameters.params
		}

		if (parameters.fetchRequest) {
			apiParam.fetchRequest = parameters.fetchRequest
		}

		let methodName = 'report.buildReport'

		if (parameters.emails) {
			apiParam.emails = parameters.emails
			methodName = 'report.byEmail'
		}

		if (ReportTypeMap[type].hnd instanceof Function) {
			apiParam = ReportTypeMap[type].hnd(apiParam, template, filterMap)
		}

		reqParam.push(apiParam)

		if (isNormalReport) {
			apiParam.lang = lang
		}
		reqParam.push(lang)

		return webapi.send(methodName, reqParam, { withoutTimeout: true })
	}

	/**
	 * @private
	 * @param {number} time
	 * @param {object} parameters
	 */
	async _checkBaseReportParams(time, parameters) {
		if (time != null && !Utils.isObject(time)) {
			return this._makeError('time is not an Object (key-value)')
		}
		if (!Utils.isObject(parameters)) {
			return this._makeError('parameters is not an Object (key-value)')
		}
		if (parameters.objects != null) {
			if (!Utils.isArray(parameters.objects)) {
				return this._makeError('parameters.objects is not an Array')
			}
			if (parameters.objects.length <= 0) {
				return this._makeError('parameters.object: no items')
			}
		}
		return null
	}

	/**
	 * @deprecated
	 * @private
	 * @param {string} message
	 */
	async _makeError(message) {
		throw new Error(message)
	}

	async getDeniableReportTypes() {
		return webapi.send('report.deniableReportTypes')
	}

	async getVehicleSummaryReport(params, language) {
		params.reportType = 'vehicleSummaryReport'
		return webapi.send('report.buildReport', [params, language])
	}
}
