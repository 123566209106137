import { Factory } from './Factory'
import { ReportTemplate } from './ReportTemplate'
import { ReportTemplateGeneral } from './ReportTemplateGeneral'
import { ReportTemplateDrivingQualitySummary } from './ReportTemplateDrivingQualitySummary'
import { ReportTemplateEventsByPeriod } from './ReportTemplateEventsByPeriod'
import { ReportTemplateEventsDetailed } from './ReportTemplateEventsDetailed'
import { ReportTemplateFencesDetailed } from './ReportTemplateFencesDetailed'
import { ReportTemplateFencesSummary } from './ReportTemplateFencesSummary'
import { ReportTemplatePairEventsDetailed } from './ReportTemplatePairEventsDetailed'
import { ReportTemplatePairEventsSummary } from './ReportTemplatePairEventsSummary'
import { ReportTemplateStreetsDetailed } from './ReportTemplateStreetsDetailed'
import { ReportTemplateRouteTrip } from './ReportTemplateRouteTrip'
import { ReportTemplateRouteTripGeneral } from './ReportTemplateRouteTripGeneral'
import { ReportTemplateScheduleTrip } from './ReportTemplateScheduleTrip'
import { ReportTemplateScheduleTripGeneral } from './ReportTemplateScheduleTripGeneral'
import { ReportTemplateVehicleTrip } from './ReportTemplateVehicleTrip'
import { ReportTemplateVehicleTripGeneral } from './ReportTemplateVehicleTripGeneral'
import { ReportTemplateMORegionReportByRegNumb } from './ReportTemplateMORegionReportByRegNumb'
import { ReportTemplateMORegionReportByInn } from './ReportTemplateMORegionReportByInn'

const templates = [
	ReportTemplate,
	ReportTemplateGeneral,
	ReportTemplateDrivingQualitySummary,
	ReportTemplateEventsByPeriod,
	ReportTemplateEventsDetailed,
	ReportTemplateFencesDetailed,
	ReportTemplateFencesSummary,
	ReportTemplatePairEventsDetailed,
	ReportTemplatePairEventsSummary,
	ReportTemplateStreetsDetailed,
	ReportTemplateRouteTrip,
	ReportTemplateRouteTripGeneral,
	ReportTemplateScheduleTrip,
	ReportTemplateScheduleTripGeneral,
	ReportTemplateVehicleTrip,
	ReportTemplateVehicleTripGeneral,
	ReportTemplateMORegionReportByRegNumb,
	ReportTemplateMORegionReportByInn
]

const ReportTemplateFactory = new Factory()

templates.forEach((template) => ReportTemplateFactory.register(template))

export default ReportTemplateFactory
