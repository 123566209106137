import wapi from 'webapi/rpc/web'
import { uuid } from '../utils/uuid'
import { DispatchObject } from './DispatchObject'
import Repository from './Repository'

export class StoreScheme extends DispatchObject {
	static entityName = 'store_scheme'

	constructor(...args) {
		super(...args)
		this.getFields()// enforce fields
	}

	getInheritStaticId() {
		return this.get('inherit_staticid')
	}

	getInheritsFrom() {
		return this.get('inheritsFrom')
	}

	isInherited() {
		return Boolean(this.getInheritsFrom())
	}

	mustInherit() {
		return this.get('mustInherit')
	}

	inherit() {
		if (!this.mustInherit()) return this

		const result = new StoreScheme(this.getParentId())
		result._setScheme(this.getOwnScheme(), this.getInheritsScheme())
		result.setName(
			this.getExtensionFor()
				? `extension for: ${this.getExtensionFor()}` : this.getName(),
		)// for debug

		if (this.get('inheritsFrom')) result.set('inherit_staticid', this.get('inheritsFrom'))

		if (this.getExtensionFor()) result.setExtensionFor(this.getExtensionFor())

		return result
	}

	inheritToGroup(groupId) {
		const clone = this.clone()
		clone._properties.mustInherit = true
		clone._properties.parent = groupId
		clone._properties.scheme_json = '{}'
		return clone.inherit()
	}

	getName() {
		return this.get('name')
	}

	setName(value) {
		if (this.getExtensionFor()) return this

		return this.set('name', value)
	}

	getDescription() {
		return this.get('description')
	}

	setDescription(value) {
		return this.set('description', value)
	}

	getExtensionFor() {
		return this.get('extension_for')
	}

	setExtensionFor(value) {
		this.setName(`Extention for: ${value}`)// for debug
		return this.set('extension_for', value)
	}

	setParentId() {
		// decline to change parent
		throw new Error('not allowed')
	}

	/*
	store scheme structure example:
	{
		"uuid_1" : {//field id
			"title": "driver name",//field title
			"descrition": "driver first name",//field description
			"type": "string",//field type
			"visible": true,//visibility
			"index": 0// sequence number
		},
		"uuid_2": {
			"title": "driver surname",
			"descrition": "driver second name",
			"type": "string",
			"visible": true,
			"index": 1
		},
		"uuid_3": {
			"title": "driver birthday",
			"type": "date",//TODO: not implemented
			"visible": true,
			"index": 2
		},
		"uuid_4": {
			"title": "country for example",// country_scheme: {id:"c2e9cae9-24ca-45ed-871c-c4e0151c0aff", scheme_json: {"0": {"title": "name", "type": "string"}}}
			"relation": {
				"schemeId": "c2e9cae9-24ca-45ed-871c-c4e0151c0aff",// other scheme staticid uuid
				"fieldId": "0",// other scheme field id, optional, if not set then full related object
			}
			"visible": true,
			"index": 3
		}
	}
	*/
	getScheme() {
		try {
			return JSON.parse(this.get('scheme_json') || '{}')
		} catch (e) {
			return {}
		}
	}

	/**
	 * @private
	 * @param {Scheme} ownScheme
	 * @param {Scheme} inheritsScheme
	 */
	_setScheme(ownScheme, inheritsScheme) { // only private usage
		const inherits = inheritsScheme || this.getInheritsScheme()
		this.set('scheme_json', JSON.stringify((ownScheme)))// create patch
		this._properties.scheme_json = JSON.stringify({ ...inherits, ...ownScheme })
		return this
	}

	getOwnScheme() {
		const result = this.getScheme()

		Object.keys(result).forEach((id) => {
			if (result[id].inherited) delete result[id]
		})

		return result
	}

	getInheritsScheme() {
		const result = this.getScheme()

		Object.keys(result).forEach((id) => {
			if (!result[id].inherited) delete result[id]
		})

		return result
	}

	getFields() {
		if (!this._properties._fields) {
			const scheme = this.getScheme()

			const result = Object.entries(scheme).map(([id, value]) => {
				const field = JSON.parse(JSON.stringify(value))
				field.id = id
				return field
			})

			const fields = result.sort((a, b) => a.index - b.index)
			this._properties._fields = fields
			this._properties._lastIndex = fields.length ? fields[fields.length - 1].index : -1
		}

		return this._properties._fields
	}

	getNewField(title) {
		const id = uuid()
		// eslint-disable-next-line no-plusplus
		const index = ++this._properties._lastIndex
		return {
			id,
			title,
			description: '',
			type: StoreScheme.Type.string,
			visible: true,
			index,
		}
	}

	putField(field) {
		const ownScheme = this.getOwnScheme()
		ownScheme[field.id] = field
		delete this._properties._fields
		return this._setScheme(ownScheme)
	}

	deleteField(fieldId) {
		const ownScheme = this.getOwnScheme()
		delete ownScheme[fieldId]
		delete this._properties._fields
		return this._setScheme(ownScheme)
	}

	delete(transaction) {
		if (this.getExtensionFor() || this.isInherited()) return undefined
		return super.delete(transaction)
	}

	canEditScheme() {
		return Boolean(this.get('editScheme'))
	}

	canEditData() {
		return Boolean(this.get('editData'))
	}
}

/**
 * @private
 */
function _invalidate() {
	wapi.cacheInvalidate('store.getScheme')
	wapi.cacheInvalidate('store.getSchemeByExtensionFor')
	wapi.cacheInvalidate('store.getFieldsByExtensionFor')
}

function fromJsonRpc(parameters) {
	return parameters ? new StoreScheme(parameters && parameters.parent, parameters) : null
}

export async function get(recursive, group) {
	const groups = await group.getIds()
	const list = await Repository.listGetter.store_scheme(recursive, groups)
	return list.map((props) => fromJsonRpc(props))
}
StoreScheme.get = get

export async function getUnique(recursive, group) {
	const schemeList = await get(recursive, group)

	const schemeMap = new Map()
	schemeList.forEach((scheme) => {
		const schemeId = scheme.getExtensionFor() || scheme.getId()
		schemeMap.set(schemeId, scheme)
	})
	return [ ...schemeMap.values() ]
}
StoreScheme.getUnique = getUnique

export async function getById(id) {
	const properties = await Repository.byIdGetter.store_scheme(id)
	return fromJsonRpc(properties)
}
StoreScheme.getById = getById

export async function getByExtensionFor(groupId, extensionFor, recursive) {
	const list = await Repository.customGetter.store_scheme.byExtensionFor(groupId, extensionFor, recursive)
	return list.map((props) => fromJsonRpc(props))
}
StoreScheme.getByExtensionFor = getByExtensionFor

export async function getExtensionFields(groupIds, extension, recursive) {
	return wapi.send('store.getExtensionFields', [extension, groupIds, recursive])
}
StoreScheme.getExtensionFields = getExtensionFields

export async function getAllAvailableExtensionFields(extension) {
	return wapi.send('store.getFieldsByExtensionFor', [extension])
}
StoreScheme.getAllAvailableExtensionFields = getAllAvailableExtensionFields

Repository.registerListGetter('store_scheme', (recursive, groups) => wapi.send('store.getScheme', { recursive, groups }))
Repository.registerByIdGetter('store_scheme', (id) => wapi.send('store.getSchemeById', id))
Repository.registerCustomGetter('store_scheme', 'byExtensionFor', (groupId, extensionFor, recursive) => (
	wapi.send('store.getSchemeByExtensionFor', [groupId, extensionFor, Boolean(recursive)])
))

wapi.cacheRegister('store.getScheme', {
	getPath: (params) => [params.recursive, params.groups.join(':')],
	maxSize: 100,
})

wapi.cacheRegister('store.getSchemeByExtensionFor', {
	getPath: (params) => params,
	maxSize: 100,
})

wapi.cacheRegister('store.getFieldsByExtensionFor', {
	getPath: (params) => params,
	maxSize: 100,
})

Repository.registerInvalidator('store_scheme', _invalidate)

export const Type = {
	string: 'string', // to be continued
	relation: 'relation',
}
StoreScheme.Type = Type

export const ExtensionFor = {
	vehicle: 'vehicle',
	driver: 'driver',
	eventReactionComment: 'eventReactionComment',
	fence: 'fence',
	customStatus: 'customStatus',
	company: 'company',
	trip: 'trip',
}
StoreScheme.ExtensionFor = ExtensionFor

export const RelationType = {
	general: 'general',
}
StoreScheme.RelationType = RelationType
