import wapi from 'webapi/rpc/web'
import Repository from './Repository'

const noop = () => undefined

export class AsyncLoaders {
	constructor(options) {
		this.objectFactory = options?.objectFactory
		this.getExtraArguments = options?.getExtraArguments || noop
		this.applyArguments = options?.applyArguments || noop
		this.requestGet = options?.requestGet
		this.countGet = options?.countGet
		this.getCountArguments = options?.getCountArguments ?? ((params) => params)
		this.requestGetById = options?.requestGetById
		this.requestGetByIdList = options?.requestGetByIdList
		this.entityName = options?.entityName
		this.objectsKey = options?.objectsKey || 'objects'
	}

	/**
	 * @param {Function} cb
	 */
	setExtraArgumentsGetter(cb) {
		if (typeof cb !== 'function') return
		this.getExtraArguments = cb
	}

	/**
	 * @param {*} _args
	 * @returns {Promise<[object, ...any]>}
	 */
	async getParams(_args) {
		const args = { ..._args }
		this.applyArguments(args)

		const extraArgs = await this.getExtraArguments() ?? []
		return [{ ...args }].concat(extraArgs)
	}

	/**
	 * @template {Object} T
	 * @param {*} args
	 * @returns {Promise<T[]>}
	 */
	async get(args, withCount) {
		const params = await this.getParams(args)

		const requestCount = () => this.getCount(args, withCount ? 'filtered' : undefined)

		let [result, total] = await Promise.all([
			wapi.send(this.requestGet, params),
			requestCount(),
		])

		// NOTE: we can't determine which request is more fresh, so reload both
		if (total.commitId != null && result.commitId !== total.commitId) {
			wapi.cacheInvalidate(`${this.countGet}`)
			wapi.cacheInvalidate(`${this.requestGet}`)
			return this.get(args, withCount)
		}

		if (!result) throw new Error('invalid result')

		if (this.entityName && result[this.objectsKey]) {
			result[this.objectsKey] = result[this.objectsKey].map((item) => {
				Repository.setData(this.entityName, item)
				return item
			}, this)
		}

		let res = (() => {
			if (Array.isArray(result)) return result
			if (typeof result !== 'object') {
				console.error(`Unexpected response type: ${result}`)
				return []
			}
			if (result[this.objectsKey]) return result[this.objectsKey]
			return []
		})()

		if (this.objectFactory) res = res.map(this.objectFactory)
		res.commitId = result.commitId ?? total.commitId

		res.actual = total.actual
		res.filtered = total.filtered ?? result.totalCount
		res.total = total.total ?? result.count

		// back compat, remove it some day
		res.count = res.total
		res.totalCount = res.filtered

		return res
	}

	async getCount(args, level) {
		if (!this.countGet || !level) return {}
		const params = await this.getParams(args)
		return wapi.send(this.countGet, this.getCountArguments([level, ...params]))
	}

	async getById(id) {
		const request = (() => {
			if (this.entityName && Repository.byIdGetter[this.entityName]) {
				return Repository.byIdGetter[this.entityName]
			}
			if (this.requestGetById) return wapi.send.bind(wapi, this.requestGetById)
			throw new Error("Called AsyncLoaders.getById on object without 'requestGetById'")
		})()

		const res = await request(id)
		if (!res) return null
		return typeof this.objectFactory === 'function'
			? this.objectFactory(res)
			: res
	}

	async getByIdList(ids) {
		const request = (() => {
			if (this.entityName && Repository.byIdListGetter[this.entityName]) {
				return Repository.byIdListGetter[this.entityName]
			}
			if (this.requestGetById) return wapi.send.bind(wapi, this.requestGetByIdList)
			throw new Error("Called AsyncLoaders.getById on object without 'requestGetByIdList'")
		})()

		const res = await request(ids)
		if (!res) return null
		return typeof this.objectFactory === 'function' && Array.isArray(res)
			? res.map((item) => this.objectFactory(item))
			: res
	}

	static extraArguments = {
		async groupRecursive(group, recursive = true) {
			const groupIds = await group.getIds()
			const args = {}
			args.recursive = Boolean(recursive)
			args.groups = groupIds
			return [args]
		},
	}
}
