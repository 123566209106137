import { ReportTemplate } from './ReportTemplate'

export class ReportTemplateFencesDetailed extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.FencesDetailed)
		this._setSetting('detailed', true)
	}

	getFenceList() {
		return this._getSetting('fences')
	}

	setFenceList(fences) {
		return this._setSetting('fences', fences)
	}

	getFieldList() {
		return this._getSetting('columns')
	}

	setFieldList(list) {
		return this._setSetting('columns', list)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.FencesDetailed
	}
}
