/**
 * @typedef {Object} RequestAggregatorOptions
 * @property {number} depth - depth of aggregation
 * @property {number} delay - delay of aggregation timeout (ms)
 */

export class RequestAggregator {
	/**
	 *
	 * @param {import("./JsonRpcClient").JsonRpcClient} jsonRpc
	 * @param {RequestAggregatorOptions} options
	 */
	constructor(jsonRpc, options) {
		this._jsonRpc = jsonRpc
		this.depth = options?.depth
		this.delay = options?.delay
		this._requestList = []
	}

	/**
	 * @param {*} request
	 */
	push(request) {
		this._requestList.push(request)

		if (this.depth) {
			if (this._requestList.length < this.depth) {
				this._schedule()
			} else {
				this.send()
			}
		} else if (this.delay !== null) {
			this._schedule()
		}
	}

	/**
	 * @private
	 */
	_schedule() {
		if (!this._timer) {
			this._timer = setTimeout(this.send.bind(this), this.delay || 50)
		}
	}

	/**
	 * @private
	 */
	_resetSchedule() {
		if (!this._timer) return
		clearTimeout(this._timer)
		delete this._timer
	}

	/**
	 * Send aggregated requests
	 */
	send() {
		this._resetSchedule()
		const r = this._requestList.slice()
		this._requestList = []

		if (r.length > 0) {
			this._jsonRpc._send(r)
		}
	}

	/**
	 *
	 * @param {*} jsonRpc
	 * @param {*} options
	 * @param {string} key
	 * @returns {RequestAggregator}
	 */
	static get(jsonRpc, options, key) {
		if (!key || key.length < 1) return new RequestAggregator(jsonRpc, options)
		let aggregator = this._requestAggregatorMap.get(key)
		if (!aggregator) {
			aggregator = new RequestAggregator(jsonRpc, options)
			this._requestAggregatorMap.set(key, aggregator)
		}
		return aggregator
	}

	/**
	 * @private
	 */
	static _requestAggregatorMap = new Map()
}
