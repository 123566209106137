/* eslint-disable no-console */
import xhr from 'lib/xhr'

// TODO: use npm module version instead of local
import LRUCache from 'lib/LRUCache'

import { uuid } from '../utils/uuid'
import { EventEmitter } from '../utils/EventEmitter'
import { RequestAggregator } from './RequestAggregator'

/**
 * @template T
 * @typedef {Object} JsonRpcRequest<T>
 * @property {"2.0"} jsonrpc
 * @property {string|number|null} id
 * @property {string} method
 * @property {Array<T>} params
 */

/**
 * @typedef {Object} JsonRpcClientOptions
 * @property {string} rpcUrl
 * @property {string} wsUrl
 * @property {boolean?} serverPush
 * @property {number?} dispatchLongRequestDuration
 * @property {number?} webSocketReconnectDuration
 * @property {boolean?} isDebug
 * @property {string?} apiPrefix
 */

// TODO: move socket client logic to separate class
export class JsonRpcClient extends EventEmitter {
	/**
	 * @private
	 */
	lastId = 0
	sessionId = ''
	changeId = ''
	apiPrefix = ''
	subdomains = ''

	socket = null
	socketReady = false
	socketSubscriptions = {}

	_cache = {}

	_timeout = {}
	_longRequests = {}
	_longRequestLength = 0
	_callbackList = {}
	_subdomainIndex = 0

	_pendingRequests = new Map/* string, params */()

	/**
	 * @param {JsonRpcClientOptions} options
	 */
	constructor(options) {
		super()
		// NOTE: required options must be provided in constructor
		if (!options) throw new Error('"options" argument must be provided')
		this.url = options.rpcUrl
		this.wsUrl = options.wsUrl.replace(/^http/i, 'ws')
		this.apiPrefix = options.apiPrefix ?? ''

		// Setting up WebSocket (temporarily disabled)
		if (options.serverPush) {
			this._setupWebSocket()
		}

		Object.defineProperty(this, 'isDebug', {
			get: () => Boolean(options.isDebug),
		})
	}

	/**
	 * @private
	 * @template {Request} R
	 * @param {string} method
	 * @param {object[]} params
	 * @param {string} prefix
	 * @returns {R} // TODO: describe Request type
	 */
	_buildRequest(method, params, prefix) {
		const apiPrefix = prefix || this.apiPrefix

		const request = {
			'jsonrpc': '2.0',
			'method': apiPrefix + method,
		}

		this.lastId += 1
		request.id = this.lastId
		request.buildVersion = this.isDebug ? '*' : BUILD_VERSION

		if (this.changeId) request.changeId = this.changeId

		if (params) {
			if (params.length > 0) {
				request.params = params
			} else {
				request.params = [params]
			}
		} else {
			request.params = []
		}
		return request
	}

	/**
	 * @param {JsonRpcRequest<any>} req
	 * @returns {string}
	 */
	_getRequestKey(req) {
		return JSON.stringify({
			method: req.method,
			params: req.params,
		})
	}

	/**
	 * @private
	 */
	_createSocket() {
		return new WebSocket(this.wsUrl)
	}

	/**
	 * @private
	 */
	_setupWebSocket() {
		this.socket = this._createSocket()

		this.socket.addEventListener('open', () => {
			this.socketReady = true

			const subscriptions = this.socketSubscriptions
			this.socketSubscriptions = {}
			Object.values(subscriptions).forEach((s) => {
				this.subscribe(s.method, s.params, s.callback)
			})
		})

		this.socket.addEventListener('close', (event) => {
			this.socketReady = false
			if (!event.wasClean) {
				console.log(`Code: ${event.code} reason: ${event.reason}`)
				setTimeout(
					this._reconnectWebSocket.bind(this),
					this.webSocketReconnectDuration || 10000,
				)
			}
		})

		this.socket.addEventListener('message', this._onSocketMessage.bind(this))
		this.socket.addEventListener('error', this._onSocketError.bind(this))
	}

	/**
	 * NOTE: borrowed from dojo
	 * @private
	 * @param {boolean} listenForOpen
	 */
	_reconnectWebSocket(listenForOpen) {
		const newSocket = this._createSocket()
		const { socket } = this

		// make the original socket a proxy for the new socket
		socket.send = socket.send.bind(newSocket)
		socket.close = socket.close.bind(newSocket)

		const proxyEvent = (type) => {
			newSocket.addEventListener.call(newSocket, type, (event) => {
				this.socket.dispatchEvent(event)
			}, true)
		}

		if (listenForOpen) proxyEvent('open')

		// redirect the events as well
		const types = ['message', 'close', 'error']
		types.forEach((type) => proxyEvent(type))
	}

	/**
	 * @private
	 * @param {WebSocketEventMap['message']} event
	 */
	_onSocketMessage(event) {
		const { data } = event

		if (data.id) {
			const cb = this.socketSubscriptions[data.id] || null

			if (cb) {
				cb(data.result)
			} else {
				console.error('Received message for unknown sender', ':', data)
			}
		} else {
			console.error('Received wrong message', ':', data)
		}
	}

	/**
	 * @private
	 * @param {WebSocketEventMap['error']} event
	 */
	_onSocketError(error) {
		console.error(error)
	}

	setAuthToken(sessionId) {
		if (sessionId && this.sessionId !== sessionId) {
			this.sessionId = sessionId
			this.emit('authTokenChanged', sessionId)
		} else if (this.sessionId) {
			// NOTE: this.sessionId is there but new sessionId is empty
			this.emit('sessionExpired')
		} else {
			console.log('seems auth error')
		}
		// ++this.lastId;
	}

	getChangeId() {
		return this.changeId
	}

	setChangeId(id) {
		this.changeId = id
	}

	setSubdomains(value) {
		this.subdomains = value.trim()
		this._subdomainIndex = 0
	}

	/**
	 * @returns {boolean}
	 */
	isAuthorized() {
		return (!!this.sessionId)
	}

	/**
	 * Get JSON-RPC request by method name and parameters
	 * @private
	 * @param {string} method
	 * @param {object[]} params
	 * @param {string} prefix
	 */
	_getRequest(method, params, prefix) {
		const result = this._buildRequest(method, params, prefix)
		result.cachePath = this._getCachePath(method, params)
		result.cacheMethod = method
		return result
	}

	/**
	 * @param {import("./RequestAggregator").RequestAggregatorOptions} options
	 * @param {string} key
	 */
	getRequestAggregator(options, key) {
		return RequestAggregator.get(this, options, key)
	}

	/**
	 *
	 * @param {string} method
	 * @param {object[]} params
	 * @param {*} opts
	 */
	sendAnonymously(method, params, opts) {
		// NOTE: build the request
		const req = this._getRequest(method, params)
		return this._sendRequest(req, opts)
	}

	/**
	 *
	 * @param {string} method
	 * @param {object[]} params
	 * @param {*} opts
	 */
	sendPublic(method, params, opts) {
		// NOTE: build the request
		const req = this._getRequest(method, params, 'public-1.0-')
		return this._sendRequest(req, opts)
	}

	/**
	 * Send JSON-RPC request over HTTP POST by method name and parameters
	 * opts - request options
	 *    * requestAggregator - object that aggregate multiple requests to one. Use this.getRequestAggregator(aggregatorOpts, key) for create it (key is null) or get old instance by key
	 */
	send(method, params, opts) {
		// NOTE: build the request
		const req = this._getRequest(method, params)
		if (this.isAuthorized()) {
			// NOTE: inject stssion id
			req.params = [this.sessionId].concat(req.params)
			return this._sendRequest(req, opts)
		}
		// NOTE: prevent unauthorized access
		const error = new Error('Unauthorized')
		error.code = 401
		return Promise.reject(error)
	}

	_getCachePath(method, params) {
		const cacheSettings = this._cache[method] ? this._cache[method].settings : null
		return cacheSettings && typeof cacheSettings.getPath === 'function'
			? cacheSettings.getPath(params)
			: null
	}

	/**
	 * @typedef {Object} SendRequestOptions
	 * @property {boolean?} withoutTimeout
	 * @property {RequestAggregator?} requestAggregator
	 */

	/**
	 * @template T
	 * @param {JsonRpcRequest<any>} request
	 * @param {SendRequestOptions?} opts
	 * @returns {Promise<T>}
	 */
	_sendRequest(request, opts) {
		const reqKey = this._getRequestKey(request)
		const pending = this._pendingRequests.get(reqKey)
		if (pending) return pending

		const req = request
		// NOTE: create request handler object
		const obj = { id: req.id }

		obj.promise = new Promise((resolve, reject) => {
			obj.resolve = resolve
			obj.reject = reject
		})

		this._pendingRequests.set(reqKey, obj.promise)
		obj.promise.finally(() => {
			this._pendingRequests.delete(reqKey)
		})

		// NOTE: when request can be cached
		if (req.cachePath) {
			const res = this._cache[req.cacheMethod]
				? this._cache[req.cacheMethod].get(req.cachePath)
				: null

			if (res) {
				// NOTE: cache hit, immediate return
				obj.resolve(res)
				return obj.promise
			}
			// NOTE: may need a key to cache a value upon resolve
			obj.cacheKey = req.cachePath
			obj.cacheMethod = req.cacheMethod
		}

		delete req.cachePath
		delete req.cacheMethod

		const hasSimilar = Boolean(this._getSimilarRequests(obj).length)

		// NOTE: memorize request handler object till the xhr finished
		this._callbackList[req.id] = obj

		if (opts && opts.withoutTimeout) {
			req.isLong = true
		}

		if (opts && opts.requestAggregator instanceof RequestAggregator) {
			opts.requestAggregator.push(req)
		} else if (!hasSimilar) {
			// NOTE: direct requests, each API method produces own xhr request
			this._send(req, opts && opts.withoutTimeout)
		}

		return obj.promise
	}

	/**
	 *
	 * @param {string} methodName
	 * @param {object} cacheSettings
	 * @param {LRUCache} CacheClass - interface of cache class
	 */
	cacheRegister(methodName, cacheSettings, CacheClass) {
		const Cache = CacheClass || LRUCache
		this._cache[methodName] = new Cache(cacheSettings)
	}

	/**
	 * @param {string} method
	 * @param {string[]} path
	 */
	cacheInvalidate(method, path) {
		// NOTE: arguments to cache path (as array)
		if (this._cache[method]) this._cache[method].clear(path)
	}

	/**
	 * xhr request physical send
	 * @private
	 * @param {Request} request
	 * @param {boolean} withoutTimeout
	 */
	_send(request, withoutTimeout) {
		const jsonRequest = JSON.stringify(request)
		const reqList = request instanceof Array ? request : [request]

		reqList.forEach((req) => {
			if (this._timeout[req.id]) {
				clearTimeout(this._timeout[req.id])
				delete this._timeout[req.id]
			}
			if (withoutTimeout) return
			this._timeout[req.id] = setTimeout(
				this._onTimeout.bind(this, req),
				this.dispatchLongRequestDuration || 20 * 1000, // 20 sec
			)
		})

		const methodKey = reqList.length > 1 ? 'bunchOf' : 'method'
		const methodValue = new Set(
			reqList.map(({ method }) => method.substring(this.apiPrefix.length))
		)
		let url = `${this.url}?${methodKey}=${[...methodValue.values()]}`

		const subdomain = this._getNextSubdomain()
		if (subdomain) {
			const { protocol, host, pathname } = window.location
			url = [
				protocol,
				`${subdomain}.${host}/${pathname}/${url}`.replace(/\/+/g, '/'),
			].join('//')
		}

		const headers = {
			'X-Requested-With': null,
			'Content-Type': 'application/json',
			'X-B3-TraceId': uuid().replaceAll('-', ''),
		}
		if (withoutTimeout) {
			headers['DP-params-isLong'] = true
		}

		xhr(url, {
			data: jsonRequest,
			method: 'POST',
			handleAs: 'json-comment-optional',
			headers,
		}).then(this._onResponse.bind(this, request))
			// NOTE: in case _onResponse fails the generic error handler will work
			.then(null, this._onError.bind(this, reqList))
	}

	/**
	 * @private
	 * @param {Request} req
	 * @emits JsonRpcClient#longRequest
	 */
	_onTimeout(req) {
		delete this._timeout[req.id]
		this._longRequests[req.id] = true
		this._longRequestLength += 1

		if (this.isDebug) {
			console.warn('Long request:', req.id, req)
		}

		this.emit('longRequest')
	}

	/**
	 * @private
	 * @param {string | number} reqId
	 * @emits JsonRpcClient#endLongRequest
	 */
	_endLongRequest(reqId) {
		if (!this._longRequests[reqId]) return

		delete this._longRequests[reqId]
		this._longRequestLength -= 1
		if (!this._longRequestLength) this.emit('endLongRequest')

		if (this.isDebug) {
			console.warn('End long request:', reqId)
		}
	}

	/**
	 * @private
	 * @param {string | number} reqId
	 */
	_clearTimeout(reqId) {
		if (this._timeout[reqId]) {
			clearTimeout(this._timeout[reqId])
			delete this._timeout[reqId]
		}
		this._endLongRequest(reqId)
	}

	/**
	 * when entire xhr, either combined or direct, succeed
	 * @private
	 * @template {object} T
	 * @param {any} request
	 * @param {T} response
	 */
	_onResponse(request, response) {
		const resp = (() => {
			try {
				return JSON.parse(response)
			} catch (e) {
				if (this.isDebug) {
					console.error(e, request)
				}
				throw new Error('Internal error')
			}
		})()

		let hasError = true
		if (Array.isArray(resp)) {
			resp.forEach((r) => this._clearTimeout(r.id))
			const processed = resp.filter((r) => this._onResult(r))
			hasError = resp.length !== processed.length
		} else {
			this._clearTimeout(resp.id)
			hasError = !this._onResult(resp)
		}
		if (hasError) {
			// NOTE: Should never happen. Individual RPC failures already handled.
			throw new Error('Internal error')
		}
	}

	/**
	 * succeed xhr response demultiplexer: choose handlers by request id and calls it
	 * @private
	 * @template {object} T
	 * @param {T} response
	 * @emits JsonRpcClient#sessionExpired
	 * @emits JsonRpcClient#serverError
	 * @returns {true}
	 */
	_onResult(response) {
		// TODO: cleanup handlers map...
		if ('result' in response) {
			// NOTE: update token expires datetime
			this.emit('authTokenRefresh')
			this.__resolveRequest(response.result, response.id)
		} else {
			if (response.error && response.error.code === 1 && this.sessionId) {
				// NOTE: advise to reload
				this.emit('sessionExpired')
			} else {
				// NOTE: stay same page and show error
				this.emit('serverError', response.error)
			}
			this.__rejectRequest(response.error, response.id)
		}
		// this.__dumpResultHandlers();//DEBUG: dump awaiting requests
		return true // result and error are processed, promises are resolved or rejected. No paths assumes false.
	}

	/**
	 * @private
	 * @template {object} T
	 * @param {*} cbObj
	 * @returns {T}
	 */
	_getSimilarRequests(cbObj) {
		const result = []
		if (!cbObj.cacheMethod) return result

		const key = this._cache[cbObj.cacheMethod].getDataKey(cbObj.cacheKey)

		Object.values(this._callbackList).forEach((o) => {
			if (
				cbObj.id !== o.id
				&& cbObj.cacheMethod === o.cacheMethod
				&& key === this._cache[o.cacheMethod].getDataKey(o.cacheKey)
			) {
				result.push(o)
			}
		})

		return result
	}

	/**
	 * @private
	 * @template {object} T
	 * @param {T} result
	 * @param {string | number} id
	 */
	__resolveRequest(result, id) {
		const o = this._callbackList[id]
		if (o) { // NOTE: if handle is there reject and delete it
			if (o.cacheMethod && o.cacheKey) {
				// NOTE: cache hint is set
				if (this._cache[o.cacheMethod]) this._cache[o.cacheMethod].put(o.cacheKey, result)
			}

			delete this._callbackList[id]

			if (o.resolve instanceof Function) {
				o.resolve(result)
			}

			this._getSimilarRequests(o).forEach((r) => {
				if (typeof r.resolve === 'function') {
					r.resolve(result)
					delete this._callbackList[r.id]
				}
			})
		}
	}

	/**
	 * @private
	 * @param {JsonRpcError} error
	 * @param {string | number} id
	 */
	__rejectRequest(error, id) {
		const o = this._callbackList[id]
		if (o) { // NOTE: if handle is there reject and delete it
			delete this._callbackList[id]

			if (o.reject instanceof Function) {
				o.reject(error)
			}

			this._getSimilarRequests(o).forEach((r) => {
				if (typeof r.reject === 'function') {
					r.reject(error)
					delete this._callbackList[r.id]
				}
			})
		}
	}

	/**
	 * @private
	 */
	__dumpResultHandlers() {
		let l = 'Known handlers:'
		Object.keys(this._callbackList).forEach((a) => {
			l += `${a} `
		})
		l += ';'
		console.log(l)
	}

	/**
	 * when entire xhr, either combined or direct, failed.
	 * @private
	 * @param {Request[]} reqList
	 * @param {ErrorResponse} errorResponse
	 * @emits JsonRpcClient#serverError
	 */
	_onError(reqList, errorResponse) {
		const { error, code } = errorResponse
		reqList.forEach((req) => {
			this._clearTimeout(req.id)
			this.__rejectRequest(error, req.id)
		})
		this.emit('serverError', error, code)
		console.log(`Server Error (${code}): ${error}`)
	}

	_getNextSubdomain() {
		if (!this.subdomains.length) return ''
		let currentIndex = this._subdomainIndex
		const result = this.subdomains.split('')[currentIndex]
		currentIndex += 1
		this._subdomainIndex = currentIndex < this.subdomains.length ? currentIndex : 0
		return result
	}

	/**
	 * Send JSON-RPC request over WebSocket
	 * @param {string} method
	 * @param {object[]} params
	 * @param {Function} onResult
	 */
	subscribe(method, params, onResult) {
		if (!this.socketReady) {
			console.error('WebSocket is disconnected')
			return null
		}

		// TODO: fix message to server
		const req = this._buildRequest('subscribe', [method].concat(params || []), false)
		if (this.sessionId) {
			req.params = [this.sessionId].concat(req.params)
		}
		const request = JSON.stringify(req)

		this.socketSubscriptions[req.id] = {
			method,
			params,
			callback: onResult,
		}

		this.socket.send(request)
		return req.id
	}

	/**
	 * Unsubscribe from request over WebSocket
	 * @param {string | number} id
	 */
	unsubscribe(id) {
		// TODO: fix message to server
		const req = this._buildRequest('unsubscribe', [id], false)
		if (this.sessionId) {
			req.params = [this.sessionId].concat(req.params)
		}
		const request = JSON.stringify(req)
		this.socket.send(request)

		if (this.socketSubscriptions[id]) delete this.socketSubscriptions[id]
	}
}
