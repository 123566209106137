/* eslint-disable no-bitwise */
import wapi from 'webapi/rpc/web'
import { PromiseIterator } from './PromiseIterator'
import { DispatchObject } from './DispatchObject'
import DBStructure from './DBStructure'
import repository from './Repository'
import { AsyncLoaders } from './AsyncLoaders'
import { Company } from './Company'

/**
 * @typedef ExtensionPermission
 * @property {string} extensionId
 * @property {number | string} key
 * @property {(role: Role) => boolean} hasCallback
 *
 * @typedef ParentPermission
 * @property {string} id
 * @property {string} textTrKey
 * @property {number} weight
 *
 * @typedef ChildPermission
 * @property {string} parent
 * @property {string} textTrKey
 * @property {number | undefined} mask
 * @property {string[] | ExtensionPermission} perm
 *
 * @typedef {ParentPermission | ChildPermission} Permission
 */

export class Role extends DispatchObject {
	static entityName = 'role'

	/**
	 * @private
	 */
	_parentConstructor = Company

	/**
	 * @returns {string}
	 */
	getName() {
		return this.get('name')
	}

	/**
	 *
	 * @param {string} name
	 * @returns {this}
	 */
	setName(name) {
		return this.set('name', name)
	}

	/**
	 *
	 * @param {string} kind
	 * @returns {number}
	 */
	getPermissionMask(kind) {
		return this.get(kind) || 0
	}

	/**
	 *
	 * @param {string} kind
	 * @param {number} mask
	 * @returns {this}
	 */
	setPermissionMask(kind, mask) {
		return this.set(kind, mask)
	}

	/**
	 * @param {string} reportType
	 * @returns {boolean}
	 */
	getReportTypePermission(reportType) {
		return Boolean(this._getReportPermissions()[reportType])
	}

	/**
	 *
	 * @param {string} reportType
	 * @param {boolean} value
	 * @returns {this}
	 */
	setReportTypePermission(reportType, value) {
		const tmp = this._getReportPermissions()
		tmp[reportType] = Boolean(value)
		return this.set('report_permissions_json', JSON.stringify(tmp))
	}

	/**
	 * @returns {unknown}
	 */
	getTripPermission() {
		return this.get('trip')
	}

	/**
	 * @param {unknown} value
	 */
	setTripPermission(value) {
		return this.set('trip', value)
	}

	/**
	 * @returns {unknown}
	 */
	getTrackerStatusJsonPermission() {
		return this.get('tracker_status_json')
	}

	/**
	 * @param {unknown} value
	 */
	setTrackerStatusJsonPermission(value) {
		return this.set('tracker_status_json', value)
	}

	/**
	 * @private
	 * @returns {object}
	 */
	_getExtensionPermissions() {
		try {
			return JSON.parse(this.get('extension_permissions')) || {}
		} catch (e) {
			return {}
		}
	}

	/**
	 * @param {string} name
	 * @returns {number | undefined}
	 */
	getExtensionPermission(name) {
		const perms = this._getExtensionPermissions()
		return perms[name]
	}

	/**
	 * @param {string} name
	 * @param {number} mask
	 */
	setExtensionPermission(name, mask) {
		const perms = this._getExtensionPermissions()
		return this.set('extension_permissions', JSON.stringify({ ...perms, [name]: mask }))
	}

	/**
	 * @private
	 */
	_getReportPermissions() {
		if (!this._properties._reportPermissions) {
			this._properties._reportPermissions = {}
			try {
				const permJsonString = this.get('report_permissions_json')
				if (permJsonString && permJsonString.length) {
					this._properties._reportPermissions = JSON.parse(permJsonString) || {}
				}
			} catch (e) {
				return {}
			}
		}
		return this._properties._reportPermissions
	}

	/**
	 * @returns {object}
	 */
	getParameters() {
		return this.getJson('parameters_json', {})
	}

	/**
	 * @param {object} p
	 */
	setParameters(p) {
		return this.setJson('parameters_json', p)
	}

	/**
	 * @returns {object}
	 */
	getExtensionPermissions() {
		return this.getJson('extension_permissions', {})
	}

	setExtensionPermissions(p) {
		return this.setJson('extension_permissions', p)
	}

	/**
	 * @deprecated
	 */
	getCommands() {
		// FIXME: do we need this?
		throw new Error('Unimplemented Role.getCommands')
	}
}

/**
 * @param {Company} company
 * @param {object} parameters
 * @returns {Role}
 */
function fromJsonRpc(company, parameters) {
	return new Role(company || (parameters && parameters.parent), parameters)
}

/**
 * @param {boolean} recursive
 * @param {Company} company
 * @returns {PromiseIterator}
 */
export function get(recursive, company) {
	return new PromiseIterator(
		repository.listGetter.role(recursive, [company.getParentId()]),
		fromJsonRpc.bind(null, company),
	)
}
Role.get = get

/**
 * @param {boolean} recursive
 * @param {Group} group
 * @returns {PromiseIterator}
 */
export function getByGroup(recursive, group) {
	return new PromiseIterator(
		group.getIds().then((idList) => repository.listGetter.role(recursive, idList)),
		fromJsonRpc.bind(null, null),
	)
}
Role.getByGroup = getByGroup

/**
 * @param {string} id
 * @returns {Promise<Role>}
 */
export async function getById(id) {
	const params = await repository.byIdGetter.role(id)
	return fromJsonRpc(null, params)
}
Role.getById = getById

/**
 * @param {string[]} idList
 * @returns {Promise<Role[]>}
 */
export async function getByIds(idList) {
	const list = await repository.byIdListGetter.role(idList)
	return list.map((params) => fromJsonRpc(null, params))
}
Role.getByIds = getByIds

/**
 * @param {Group} group
 * @param {boolean} recursive
 * @returns {AsyncLoaders<Role>}
 */
export function getAsyncLoaders(group, recursive) {
	return new AsyncLoaders({
		getExtraArguments: () => AsyncLoaders.extraArguments.groupRecursive(group, recursive),
		requestGet: 'role.getListPortion',
	})
}
Role.getAsyncLoaders = getAsyncLoaders

/**
 * @private
 */
function _invalidate() {
	wapi.cacheInvalidate('role.getListPortion')
}

repository.registerListGetter('role', (recursive, groups) => wapi.send('role.get', { recursive, groups }))
repository.registerByIdGetter('role', (id) => wapi.send('role.getById', id))
repository.registerByIdListGetter('role', (idList) => wapi.send('role.getByIds', [idList]))
repository.registerInvalidator('role', _invalidate.bind(Role))

wapi.cacheRegister('role.getListPortion', {
	getPath: (params) => [JSON.stringify(params[0]), params[1].groups.join('/'), params[1].recursive],
	maxSize: 3,
})

export const PermissionType = {
	ReadFields: 0x01,
	EditPermissions: 0x02,
	EditChildren: 0x04,
	WriteFields: 0x08,
}
Role.PermissionType = PermissionType

export const TripPermissionType = {
	Read: 0x01,
	Edit: 0x02,
	TripControlTab: 0x04,
}
Role.TripPermissionType = TripPermissionType

export const ExtensionId = {
	KPSG: 'kpsg',
}
Role.ExtensionId = ExtensionId

export const ExtensionPermission = {
	KPSG: {
		Monitoring: 0x01,
		Registration: 0x02,
		TakeOnControl: 0x04,
		PauseControl: 0x08,
		StopControl: 0x10,
		Deletion: 0x20,
	},
}
Role.ExtensionPermission = ExtensionPermission

export const EventFilterExtendedPermissions = {
	ReactOnEvents: 0x10,
	EventTab: 0x20,
	CustomReactionComment: 0x40,
}
Role.EventFilterExtendedPermissions = EventFilterExtendedPermissions

export const TrackerExtendedPermssions = {
	ViewLocation: 0x10,
	ViewTrack: 0x20,
	EditLoadCoordinates: 0x40,
	ViewDeleted: 0x80,
	ViewStore: 0x100,
	EditStore: 0x200,
}
Role.TrackerExtendedPermssions = TrackerExtendedPermssions

export const VehicleExtendedPermssions = {
	DeactivateObject: 0x10,
	ReportRoadAccident: 0x20,
	Regenerate: 0x40,
	AttachTracker: 0x80,
	ViewDeleted: 0x100,
	ViewStore: 0x200,
	EditStore: 0x400,
}
Role.VehicleExtendedPermssions = VehicleExtendedPermssions

export const VehicleCatalogPermissions = {
	Edit: 0x01,
	Import: 0x02,
}
Role.VehicleCatalogPermissions = VehicleCatalogPermissions

export const TrackerStatusJsonPermissionType = {
	Read: 0x01,
	SetInstallationDone: 0x02,
	SetCheckDone: 0x04,
	SetIdentificationDone: 0x08,
	SetRequiredAutoIdentification: 0x10,
	SetAutoIdentificationDone: 0x20,
}
Role.TrackerStatusJsonPermissionType = TrackerStatusJsonPermissionType

export const GeneralStorePermissions = {
	EditScheme: 0x01,
	EditData: 0x02,
}
Role.GeneralStorePermissions = GeneralStorePermissions

export const ActiveSessions = {
	View: 0x10,
	Manage: 0x20,
}
Role.ActiveSessions = ActiveSessions

export const IconsCatalogPermissions = {
	Edit: 0x01,
}
Role.IconsCatalogPermissions = IconsCatalogPermissions

export const TrackerStatusJsonPermissionTypeArray = [
	TrackerStatusJsonPermissionType.SetInstallationDone,
	TrackerStatusJsonPermissionType.SetCheckDone,
	TrackerStatusJsonPermissionType.SetIdentificationDone,
]
Role.TrackerStatusJsonPermissionTypeArray = TrackerStatusJsonPermissionTypeArray

// Callback is function(role)
const creatorCallbacks = []

export function registerCreatorCallback(cb) {
	if (typeof cb === 'function') creatorCallbacks.push(cb)
}
Role.registerCreatorCallback = registerCreatorCallback

// 'desc' : {
//     name : String
//     adminPerm : Number
//     otherPerm : Number
// }
export function create(parentId, { name, perms }) {
	const role = new Role(parentId)
	role.setName(name)

	Object.keys(DBStructure).forEach((kind) => {
		role.setPermissionMask(kind, perms[kind] ?? perms.otherPerm)
	})

	creatorCallbacks.forEach((cb) => cb(role))
	return role
}
Role.create = create
