import wapi from 'webapi/rpc/web'
import config from 'webapi/rpc/config'
import { DispatchObject } from 'webapi/main/DispatchObject'
import { AsyncLoaders } from 'webapi/main/AsyncLoaders'
import Repository from 'webapi/main/Repository'
import { DriverAssignment } from './DriverAssignment'
import { extensionPropertyMixin } from '../main/_extensionPropertyMixin'

export class Driver extends extensionPropertyMixin(DispatchObject, 'driver') {
	static entityName = 'driver'

	getName() {
		return this.get('name')
	}

	setName(value) {
		return this.set('name', value || '')
	}

	getCardId() {
		return this.get('card_id')
	}

	setCardId(value) {
		return this.set('card_id', value)
	}

	getDriverAssignmentList(fromTimestamp, toTimestamp) {
		return wapi.send('driverAssignment.getByDriverId', [this.getId(), fromTimestamp.valueOf() / 1000, toTimestamp.valueOf() / 1000]).then(
			(driverAssignmentList) => (driverAssignmentList || []).map((properties) => new DriverAssignment(properties && properties.parent, properties)),
		)
	}
}

function fromJsonRpc(properties) {
	return new Driver(properties && properties.parent, properties)
}

export function asyncLoadersRecursive(group) {
	return new AsyncLoaders({
		objectFactory: fromJsonRpc,
		getExtraArguments: AsyncLoaders.extraArguments.groupRecursive.bind(null, group),
		requestGet: 'driver.getPortion',
		entityName: Driver.prototype.entityName,
	})
}
Driver.asyncLoadersRecursive = asyncLoadersRecursive

wapi.cacheRegister('driver.getPortion', {
	getPath: (params) => {
		return params.map((value) => JSON.stringify(value))
	},
	maxSize: 10,
	expire: config.portionPollingInterval,
})
Repository.registerInvalidator('driver', () => wapi.cacheInvalidate('driver.getPortion'))

export async function getList(idList) {
	const list = await Repository.byIdListGetter.driver(idList)
	return list.map(fromJsonRpc)
}
Driver.getList = getList

asyncLoadersRecursive.FilterColumns = {
	Name: 'name',
} // NOTE: sort columns is the same

Repository.registerByIdListGetter('driver', (idList) => wapi.send('driver.getByIdList', [idList]))
