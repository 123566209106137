/**
 * Helper for user parameters
 */
define([
	'dojo/Evented',
	'webapi/main/Session',
	'core/settings/nls/settings',
	'core/settings/userAppConfig',
	'core/ui/widget/MessageBox',
	'lodash',
], (
	Evented,
	{ Session },
	nls,
	{ defaultConfig },
	MessageBox,
	{
		get: _get,
		set: _set,
		isEqual,
		cloneDeep,
		forEach,
		merge,
	},
) => {
	const separator = '.'
	const timersMap = {}
	const settings = {}

	function getParams() {
		return Session.current ? Session.current.getUser()?.getParameters() : null
	}

	function getMergedParams() {
		const params = cloneDeep(getParams())
		const result = merge(cloneDeep(defaultConfig), params)
		Object.keys(settings).forEach((key) => {
			_set(result, key, cloneDeep(_get(settings, key)))
		})
		return result
	}

	function setupTimer(path) {
		const pathArr = path.split(separator)
		if (!pathArr?.length) return

		const oldKey = Object.keys(timersMap).find((pathString) => {
			return pathString.split(separator)[0] === pathArr[0]
		})

		let newPath = pathArr

		if (oldKey) {
			newPath = []
			const oldPath = oldKey.split(separator)
			forEach(pathArr, (key, i) => {
				if (key !== oldPath[i]) return false
				newPath.push(key)
			})

			clearTimeout(timersMap[oldKey]?.timer)
			delete timersMap[oldKey]
		}

		const newKey = newPath.join(separator)
		const handler = () => saveSettings(newKey)

		timersMap[newKey] = {
			handler,
			timer: setTimeout(handler, 1000),
		}
	}

	async function saveSettings(path) {
		const params = _get(settings, path)

		const pathArr = path.split(separator)
		delete timersMap[path]

		try {
			await Session.current.getUser().changeParametersPart(pathArr, params)
		} catch (error) {
			console.error(error)
			new MessageBox({
				title: nls.dialogSettingsTitle,
				content: nls.serverErrorBalloon
			}).show()
		} finally {
			delete settings[pathArr[0]]
		}
	}

	function UserSettings(path, value) {
		const mergedParams = getMergedParams()
		const mergedValue = _get(mergedParams, path)

		if (value === undefined) return path ? mergedValue : mergedParams
		if (isEqual(mergedValue, value)) return

		value = cloneDeep(value)

		// Save settings locally because the User instance can be changed =(
		_set(settings, path, value)
		_set(mergedParams, path, value)

		const rootKey = path.split(separator)[0]
		UserSettings.emit(rootKey, _get(mergedParams, rootKey))

		// Save local settings every N seconds
		setupTimer(path)
	}

	UserSettings.on = Evented.prototype.on
	UserSettings.emit = Evented.prototype.emit

	UserSettings.saveSettings = async (path) => {
		const rootKey = path.split(separator)[0]

		const key = Object.keys(timersMap).find((pathString) => {
			return pathString.split(separator)[0] === rootKey
		})

		const item = timersMap[key]
		if (!item) return

		UserSettings.emit(rootKey, _get(getMergedParams(), rootKey))

		clearTimeout(item.timer)
		return item.handler()
	}

	return UserSettings
})
