/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { chunk } from 'lodash'

const delay = (time = 0) => new Promise((resolve) => setTimeout(resolve, Math.max(time, 0)))

/**
 * @deprecated
 */
export class PromiseIterator {
	chunkSize = 1000

	constructor(promise, mapFunction) {
		this.promise = promise
		this.mapFunction = mapFunction
	}

	async foreach(onItem, onDone) {
		try {
			// NOTE: split the data into chunks to avoid long freezes, when process massive array at once
			const data = await this.promise
			const dataChunks = chunk(data, this.chunkSize)
			const result = []

			const mapFunction = (item) => {
				const res = typeof this.mapFunction === 'function'
					? this.mapFunction(item)
					: item
				onItem(res)
				return res
			}

			await Promise.all(
				dataChunks.map(async (dataChunk) => {
					await delay()
					const items = dataChunk.map(mapFunction)
					result.splice(result.length, 0, ...items)
					return items
				}),
			)
		} finally {
			if (typeof onDone === 'function') onDone()
		}
	}

	async toPromise() {
		const data = await this.promise
		const { mapFunction } = this
		if (typeof mapFunction === 'function') {
			return data.map((item) => mapFunction(item))
		}
		return data
	}
}
