import { ReportTemplate } from './ReportTemplate'
import { ReportTemplatePairEventsDetailed } from './ReportTemplatePairEventsDetailed'

export class ReportTemplateEventsDetailed extends ReportTemplatePairEventsDetailed {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.EventsDetailed)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.EventsDetailed
	}
}
