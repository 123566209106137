/* eslint-disable no-shadow */
import config from 'webapi/rpc/config'
import wapi from 'webapi/rpc/web'
import { DispatchServer } from './DispatchServer'
import { RootGroup } from './RootGroup'
import { DispatchObject } from './DispatchObject'
import mapService from './MapService'
import { ReportService } from './ReportService'
import chatService from './ChatService'
import DBStructure from './DBStructure'
import { User } from './User'
import Repository from './Repository'
import { EventEmitter } from '../utils/EventEmitter'

/**
 * @injectable
 */
export class Session extends EventEmitter {
	/**
	 * @param {DispatchServer} server - injectable
	 * @param {User} user - injectable
	 */
	constructor(server, user) {
		super()
		if (!(server instanceof DispatchServer)) {
			throw new TypeError(`Session: argument is not a DispatchServer: ${server}`)
		}

		if (!(user instanceof User)) {
			throw new TypeError('Session: user is not an Object')
		}

		this.dispatchServer = server
		this.currentUser = user

		Object.defineProperty(DispatchObject.prototype, 'session', {
			value: this,
			enumerable: true,
			configurable: false,
			writable: false,
		})

		this._createTime = Date.now()
		this._headPollingDelay = config.dispatchHeadPollingInterval || 60000

		this.change()
	}

	/**
	 * @returns list of known types from DBStructure
	 * The list is to be used mainly to manage permissions
	 */
	getKnownTypes() {
		return Object.keys(DBStructure)
	}

	/**
	 * @private
	 */
	_headRequest(delay) {
		if (this._pollingDisabled || this._pollingTimeout) return

		if (delay == null) {
			this._startTime = Date.now()
		}

		this._pollingTimeout = setTimeout(this.change.bind(this), delay ?? this._headPollingDelay)
	}

	disablePolling() {
		this._pollingDisabled = true
		if (this._pollingTimeout) {
			clearTimeout(this._pollingTimeout)
			this._pollingTimeout = null
		}
	}

	async enablePolling() {
		if (this._pollingDisabled) {
			this._pollingDisabled = false

			const timeLeft = Date.now() - this._startTime

			if (timeLeft >= this._headPollingDelay) {
				return this.change()
			}
			this._headRequest(this._headPollingDelay - timeLeft)
		}
		return undefined
	}

	/**
	 * @fn getRoot() : Array, synchronous // FIXME: update API
	 * @memberof Session
	 * Get list of top level groups.
	 * User may have access to groups that do not belong to his company branch.
	 *
	 * @return DispatchIterator of Group objects having no parent
	 */
	getRoot() {
		return new RootGroup()
	}

	/**
	 * @fn getUser
	 * @memberof Session
	 * FIXME Since this method is synchronous,
	 * User storage have to be initialized with login user properties
	 * before Session constructor invocation.
	 * @return User
	 */
	getUser() {
		return this.currentUser
	}

	/**
	 * @deprecated use DI container istead
	 */
	getMapService() {
		return mapService
	}

	/**
	 * @deprecated use DI container istead
	 */
	getReportService() {
		this._reportService = this._reportService || new ReportService()
		return this._reportService
	}

	/**
	 * @deprecated use DI container istead
	 */
	getChatService() {
		return chatService
	}

	async changeToCommit(commitId, changeId, force) {
		if (!force && this.head === commitId && wapi.getChangeId() === changeId) return

		this.head = commitId
		wapi.setChangeId(changeId)
		// NOTE: drop the cached repository state
		// TODO: clear repository by object type

		Repository.clearData('group')
		Repository.clearData('vehicle')
		Repository.clearData('tracker')
		Repository.clearData('fence')
		Repository.clearData('place')
		Repository.clearData('event_filter')
		Repository.clearData('relaying')
		Repository.clearData('route')
		Repository.clearData('schedule')
		Repository.clearData('trip')
		Repository.clearData('command')
		Repository.clearData('reporttemplate')
		Repository.clearData('task')
		Repository.clearData('license')
		Repository.clearData('role')
		Repository.clearData('user')
		Repository.clearData('sensors_set')
		Repository.clearData('store_scheme')
		Repository.clearData('store')
		Repository.clearData('driver')
		Repository.clearData('maintenance_schedule')
		Repository.clearData('general_store')

		// NOTE: notify anyone to reload data
		try {
			const user = await User.current()
			this.currentUser = user
		} finally {
			this.emit('changed')
		}
	}

	async forceUpdate() {
		await this.changeToCommit(this.head, wapi.getChangeId(), true)
	}

	async change() {
		if (this._pollingTimeout) {
			clearTimeout(this._pollingTimeout)
			this._pollingTimeout = null
		}

		try {
			const result = await wapi.send('repo.head', null)
			if (!result) return
			const { commitId, changeId } = result
			await this.changeToCommit(commitId, changeId)
		} finally {
			this._headRequest()
		}
	}

	async changeCurrentUserPassword(oldPassword, newPassword) {
	// NOTE: user id is gotten from session
		return wapi.send('repo.changePassword', [oldPassword, newPassword])
	}

	async getApiKey() {
		return wapi.send('getApiKey')
	}

	async newApiKey() {
		return wapi.send('newApiKey')
	}

	async removeApiKey() {
		return wapi.send('removeApiKey')
	}

	getClientUiSettings() {
		return this._clientUiSettings || {}
	}

	isFeatureEnabled(featureName) {
		const featureSettings = this.getFeatureSettings(featureName)
		return !(featureSettings?.disabled === true)
	}

	getFeatureSettings(featureName) {
		const uiSettings = this.getClientUiSettings()
		return uiSettings?.features?.[featureName] ?? {}
	}

	getBackendLicensePermissions() {
		return this._backendLicensePermissions || {}
	}

	getCreateTime() {
		return this._createTime
	}

	/**
	 * @return {string}
	 */
	getAuthToken() {
		return wapi.sessionId
	}

	getExtModuleList() {
		return (this.getClientUiSettings().modules || [])
	}

	async getClientUiSettingsPromise() {
		this._clientUiSettings = await wapi.send('getClientUiSettings')
		return this._clientUiSettings
	}

	async sendEmailToSupport(subject, body) {
		return wapi.send('sendEmailToSupport', [subject, body])
	}

	/**
	 * @deprecated not used with the arrival of webpack
	 */
	getExtModuleUrlList() {
		return this.getExtModuleList().map((m) => {
			const isString = (typeof (m) === 'string')
			const version = (isString || !m.version ? Date.now() : m.version)
			let url = (isString ? m : m.url)
			url += (url.lastIndexOf('.js') === url.length - 3 ? '' : '.js')
			/**
		 * FIXME: Parameter "e" is not needed in fact,
		 * it's needed because the "require" added ".js"
		 * if it does not exist at the end of the path
		 * */
			return `${url}?v=${version}&e=`
		})
	}

	getExtModuleDesc(nameOrUrl) {
		const module = this.getExtModuleList().find((m) => {
			if (typeof (m) === 'string') {
				return m === nameOrUrl
			}
			return (m.name === nameOrUrl || m.url === nameOrUrl)
		})

		if (typeof module === 'string') {
			return {
				name: module,
				url: module,
				version: Date.now().toString(),
			}
		}

		return module
	}

	async checkLicenseExpiration(timezone) {
		return wapi.send('license.checkExpiration', [timezone])
	}
}

// NOTE: a promise resolver handle, kind of defferred
const sessionDef = {
	fn(resolve, reject) {
		this.resolve = resolve
		this.reject = reject
	},
}

// NOTE: current session promise, to be resolved when session instance will be created elsewhere with create call
const sessionPromise = new Promise((resolve, reject) => {
	sessionDef.fn(resolve, reject)
})

/**
 * @type {Session}
 */
// eslint-disable-next-line import/no-mutable-exports
export let current = null

export async function create({ server, user, silent }) {
	if (!current) {
		try {
			current = new Session(server, user)
		} catch (e) {
			sessionDef.reject(e)
		}

		if (silent) return current

		await wapi.send('getBackendLicensePermissions')
			.then((permissions) => {
				current._backendLicensePermissions = permissions
				return current
			})
			.catch(() => current)

		const settingsPromise = await current.getClientUiSettingsPromise()
			.then(({ subdomains }) => {
				if (subdomains != null) wapi.setSubdomains(subdomains)
				return current
			})
			.catch(() => current)

		sessionDef.resolve(settingsPromise)
	}

	return current
}

export function reject(...args) {
	if (current) {
		console.error('Failed to reject already created Session')
		return
	}
	sessionDef.reject(...args)
}

export async function ready() {
	return sessionPromise
}

// FIXME: backward compatibility with AMD
Session.create = create
Session.reject = reject
Session.ready = ready

Object.defineProperty(Session, 'current', {
	get: () => current,
})
