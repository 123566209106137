import { ReportTemplate } from './ReportTemplate'

export const AggregationType = {
	Day: 'gb_day',
	Month: 'gb_month',
}

const AggregationTypeInverse = {}
Object.entries(ReportTemplate.AggregationType)
	.forEach(([key, value]) => {
		AggregationTypeInverse[value] = key
	})

export class ReportTemplatePairEventsSummary extends ReportTemplate {
	static AggregationType = AggregationType

	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.PairEventsSummary)
	}

	setAggregationType(type) {
		if (!(type in AggregationTypeInverse)) {
			throw new TypeError(`ReportTemplatePairEventsSummary.setAggregationType: invalid type: ${type}`)
		}
		this._setSetting('groupBy', type)
		return this
	}

	getAggregationType() {
		return this._getSetting('groupBy')
	}

	getEventTypes() {
		return this._getSetting('eventTypes')
	}

	setEventTypes(types) {
		return this._setSetting('eventTypes', types)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.PairEventsSummary
	}
}
