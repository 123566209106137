import Vue from 'vue'
import { watchEffect } from '@vue/composition-api'
import moment from 'moment'
import { getTranslationVariants } from 'core/jed'
import Vue2Jed, { Messages } from './vue2jed'
import { config } from '@/core/config'

// import all available translations
const PO_EXPR = /\.?([\w@]+)\.po$/ // eslint-disable-next-line
const importLocales = require.context('@/locales/external/', true, /\.?([\w@]+)\.po$/)

const messages = importLocales.keys()
	.reduce((res: Messages, path: string) => {
		const [, key] = PO_EXPR.exec(path) as RegExpMatchArray
		return {
			...res,
			[key]: importLocales(path),
		}
	}, {} as Messages)

const { locale } = config
const languageVariant = getTranslationVariants()[locale] || ''

export const i18n = Vue2Jed.create({
	messages: { en: {}, ...messages }, // add en lang in language list if it not defined
	locale,
	languageVariant,
})

Vue.use(Vue2Jed, { instance: i18n })

watchEffect(async () => {
	try {
		const localization = await import(`moment/locale/${i18n.locale}`)
		moment.updateLocale(i18n.locale, localization)
	} catch(e) {
		console.error(`Can't load "${i18n.locale}" moment locale`)
	}
})

export default i18n
