import { ReportTemplate } from './ReportTemplate'

export class ReportTemplateScheduleTripGeneral extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.ScheduleTripGeneral)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.ScheduleTripGeneral
	}
}
