/**
 * @deprecated
 */
export class DispatchIterator {
	constructor(getArray, transformItem) {
		this.getArray = getArray
		this.transformItem = transformItem
	}

	async foreach(onItem, onDone) {
		const transform = this.transformItem ?? ((item) => item)
		return this.getArray((item) => onItem(transform(item)), onDone)
	}

	async toPromise() {
		const items = []
		const res = [items]
		await this.foreach((item) => {
			items.push(item)
		}, (...args) => {
			args.forEach((arg) => {
				res.push(arg)
			})
		})
		return res
	}
}
