import wapi from 'webapi/rpc/web'
import { DispatchObject } from './DispatchObject'
import { PromiseIterator } from './PromiseIterator'
import Repository from './Repository'

const has = Object.prototype.hasOwnProperty.call.bind(Object.prototype.hasOwnProperty)

function assertArray(prefix, list) {
	if (list != null && list.length == null) {
		throw new Error(`${prefix}: argument not an array: ${list}`)
	}
}

/// @return String ISO date representation
function normalizeDate(date) {
	// TODO Temporal solution, need to save timezone as well
	// TODO Maybe dojo independent polyfill (see also Company)
	if (date instanceof Date) {
		return date.toISOString(date)
	}
	return date
}

export class ReportTemplate extends DispatchObject {
	static entityName = 'reporttemplate'

	getName() { return this.get('title') }

	setName(name) { return this.set('title', name) }

	isDefault() { return this._getSetting('default') }

	setDefault(isDefault) {
		return this._setSetting('default', isDefault)
	}

	getType() {
		return this.get('type')
	}

	notCopy() {
		return this._getSetting('notCopy') || false
	}

	// Note may be obsolete: Not stored on the server otherwise must return DispatchIterator
	/// Deprecated: objects may be groups (drivers are expected), not only vehicles
	/// See setSelectionMode()
	getMonitoringObjectList() {
		return this.getObjectList()
	}

	getObjectList() {
		return this._getSetting('objects') || []
	}

	/// Deprecated: objects may be groups (drivers are expected), not only vehicles
	/// See setSelectionMode()
	setMonitoringObjectList(list) {
		return this.setObjectList(list)
	}
	// Maybe slice to store copy of list
	setObjectList(list) {
		assertArray('ReportTemplate.setMonitoringObjectList', list)
		return this._setSetting('objects', list)
	}
	getFenceList() {
		return this._getSetting('fences')
	}
	setFenceList(list) {
		assertArray('ReportTemplate.setFenceList', list)
		this._setSetting('fences', list)
		return this
	}

	getSelectionMode() {
		return this._getSetting('selectionMode')
	}

	setSelectionMode(mode) {
		if (mode != null && !has(ReportTemplate.SelectionMode, mode)) {
			throw new TypeError(`ReportTemplate.setSelectionMode: invalid mode: ${mode}`)
		}
		return this._setSetting('selectionMode', mode)
	}

	isSameSelectionMode(/** String */ mode) {
		const templateMode = this.getSelectionMode()
		return (templateMode == null && (
			mode == null || mode === ReportTemplate.SelectionMode.monitoringObject
		)) || (
			mode == null && (templateMode === ReportTemplate.SelectionMode.monitoringObject)
		)
				|| mode === templateMode
	}

	isEmptySelectionAllowed() {
		return this._getSetting('emptySelectionAllowed') || false
	}

	getFieldList() {
		return this._getSetting('columns')
	}

	setFieldList(list) {
		assertArray('ReportTemplate.setFieldList', list)
		this._setSetting('columns', list)
		return this
	}

	getTimeType() {
		return this._getSetting('timeType') || 'interval'
	}

	// FIXME update API add getTimeInterval
	getTimeInterval() {
		return this._getSetting('timeInterval')
	}

	// FIXME update API add setTimeInterval
	setTimeInterval(timeInterval) {
		if (!has(ReportTemplate.TimeInterval, timeInterval)) {
			throw new TypeError(`ReportTemplate.setTimeInterval: invalid time interval: ${timeInterval}`)
		}
		return this._setSetting('timeInterval', timeInterval)
	}

	/// @return String for a while
	getBeginDate() {
		return this._getSetting('begin')
	}

	setBeginDate(date) {
		return this._setSetting('begin', normalizeDate(date))
	}

	/// @return String for a while
	getEndDate() {
		return this._getSetting('end')
	}

	setEndDate(date) {
		return this._setSetting('end', normalizeDate(date))
	}

	save(transaction) {
		if (this.getTimeInterval() !== 'ti_fixed') {
			if (this.getBeginDate()) {
				this.setBeginDate(null)
			}
			if (this.getEndDate()) {
				this.setEndDate(null)
			}
		}
		return super.save(transaction)
	}

	_getSetting(field) {
		const json = this.get('settings')
		if (!json) {
			return null
		}
		const settings = JSON.parse(json)
		return settings && settings[field]
	}

	_setSetting(field, value) {
		const json = this.get('settings')
		const settings = json ? JSON.parse(json) : {}
		if (value == null) {
			delete settings[field]
		} else {
			settings[field] = value
		}
		return this.set('settings', JSON.stringify(settings))
	}
}

export function get(recursive, group) {
	const { default: ReportTemplateFactory } = require('./ReportTemplateFactory')
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.reporttemplate.bind(null, recursive)),
		ReportTemplateFactory.create.bind(ReportTemplateFactory),
	)
}
ReportTemplate.get = get

function _invalidate() {
	wapi.cacheInvalidate('reporttemplate.get', [])
}

// FIXME: export enums separately of class
export const AggregationType = {
	Month: 'gb_month',
	Day: 'gb_day',
	Trip: 'gb_trip',
	Engine: 'gb_engine',
	Fueling: 'gb_fuel',
	WorkingHours: 'gb_workingHours',
	ExternalPowerOn: 'gb_power',
}
ReportTemplate.AggregationType = AggregationType

export const SelectionMode = {
	monitoringObject: 'monitoringObject',
	group: 'group',
	driver: 'driver',
	route: 'route',
	schedule: 'schedule',
}
ReportTemplate.SelectionMode = SelectionMode

// FIXME update API: add  TimeInterval
export const TimeInterval = {
	ti_fixed: true,
	ti_today: true,
	ti_yesterday: true,
	ti_currentWeek: true,
	ti_lastWeek: true,
	ti_currentMonth: true,
	ti_lastMonth: true,
	ti_currentQuarter: true,
	ti_lastQuarter: true,
}
ReportTemplate.TimeInterval = TimeInterval

export const Type = {
	General: 'general',
	PairEventsSummary: 'pairEventsSummaryReport',
	PairEventsDetailed: 'pairEventsDetailedReport',
	RouteTrip: 'routeTripReport',
	RouteTripGeneral: 'routeTripGeneralReport',
	VehicleTrip: 'vehicleTripReport',
	VehicleTripGeneral: 'vehicleTripGeneralReport',
	ScheduleTrip: 'scheduleTripReport',
	ScheduleTripGeneral: 'scheduleTripGeneralReport',
	EventsDetailed: 'eventsDetailedReport',
	DrivingQualitySummary: 'drivingQualitySummaryReport',
	GeneralFence: 'generalFenceReport',
	FencesDetailed: 'fencesDetailedReport',
	StreetsDetailed: 'streetsDetailedReport',
	EventsByPeriodGeneral: 'eventsByPeriodGeneralReport',
	EventsByPeriodDetailed: 'eventsByPeriodDetailedReport',
	EventsByPeriodNotSoDetailed: 'eventsByPeriodNotSoDetailedReport',
	GeofenceVisitingReport: 'geofenceVisitingReport',
	UserActions2Report: 'userActions2Report',
	MonitoringObjectsRegionalReportByRegNumber: 'monitoringObjectsRegionalReportByRegNumber',
	MonitoringObjectsRegionalReportByInn: 'monitoringObjectsRegionalReportByInn',
}
ReportTemplate.Type = Type

export const TimeType = {
	None: 'none',
	Interval: 'interval',
	Moment: 'moment',
	YearMonth: 'yearMonth',
}
ReportTemplate.TimeType = TimeType

Repository.registerListGetter('reporttemplate', (recursive, groups) => wapi.send('reporttemplate.get', { recursive, groups }))
Repository.registerInvalidator('reporttemplate', _invalidate)

wapi.cacheRegister('reporttemplate.get', {
	getPath: (params) => [params.groups, params.recursive],
})
