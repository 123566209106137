import { DispatchObject } from './DispatchObject'
import { Role } from './Role'

export class RoleCommand extends DispatchObject {
	static entityName = 'role_command'

	getCommandId() {
		return this.get('command_id')
	}

	setCommandId(commandId) {
		return this.set('command_id', commandId)
	}

	/**
	 * @private
	 */
	_parentConstructor = Role
}
