define(
	[
		"core/component",
		"exports",
		"core/BaseAuth",
		"webapi/main/DispatchServer",
		"webapi/main/Session",

		"core/load",
		"core/util/UserSettings",
		'lodash',

		"core/nls/common",

		"dojo/cookie",
		"core/config",
		"dojo/Evented",
		"dojo/_base/declare",
		"dojo/_base/lang",
		"dojo/hash",
		"dojo/io-query",
		"dojo/string",
		"dojo/on",

		'quasar',
		'i18n',
	], function(
		component,
		exports,
		BaseAuth,

		{ default: server },
		{ Session },

		load,
		UserSettings,
		{ assign },

		nlsCommon,

		cookie,
		{ config },
		Evented,
		declare,
		lang,
		hash,
		ioQuery,
		string,
		on,

		{ Notify },
		{ i18n },
	) {
		var AUTH_COOKIE = BaseAuth.AUTH_COOKIE;
		var AUTHTOKEN_REFRESHTIME = 60 * 1000; // one minute in miliseconds
		var AUTHTOKEN_EXPIRE_DAYS = 365; // store session "forever", the expiration will be managed propertly by the server

		var LayoutHandler = declare([Evented], {

			layout: null,

			constructor: function (args) {
				declare.safeMixin(this, args);
			},

			show: function() {
				if(!this.isVisible()) {
					this.layout.visible = true;
					this.emit('show', this.layout);
				}
			},

			hide: function() {
				if(this.isVisible()) {
					this.emit('hide', this.layout);
					this.layout.visible = false;
				}
			},

			isVisible: function() {
				return !!this.layout.visible;
			},

			// NOTE: Just hide layout regions without destroy views
			showRegion: function(region) {
				this.emit('showRegion', this.layout, region);
			},

			hideRegion: function(region) {
				this.emit('hideRegion', this.layout, region);
			}
		});

		var ViewHandler = declare([Evented], {

			view: null,

			constructor: function (args) {
				declare.safeMixin(this, args);
			},

			show: function() {
				if(!this.isVisible()) {
					this.view.visible = true;
					this.emit('show', this.view);
				}
			},

			hide: function(destroy) {
				if (!this.isVisible()) return
				this.emit('hide', this.view, destroy || this.view.menuId)
				this.view.visible = false
			},

			focus: function() {
				if(!this.isVisible()) {
					this.show();
				}

				this.emit('focus', this.view);
			},

			blur: function() {
				this.emit('blur', this.view);
			},

			isVisible: function() {
				return !!this.view.visible;
			},

			isCreated: function() {
				return !!this.view.container;
			}
		});

		var MenuHandler = declare([Evented], {

			item: null,

			_decorationAttrs: [
				'disabled',
				'checked',
				'label',
				'title',
				'style',
				'iconClass',
			],

			constructor: function (args) {
				declare.safeMixin(this, args);
			},

			set: function(params) {
				var hasChanges = false;

				for (var key in params) {
					if (params.hasOwnProperty(key) && this._decorationAttrs.indexOf(key) == -1) {
						// NOTE: This method do nothing if you try to change parent or smth else that not in list
						return;
					}

					if (params.hasOwnProperty(key) &&
						(!this.item.hasOwnProperty(key) || params[key] != this.item[key]))
						hasChanges = true;

				}

				if(!hasChanges)
					return;

				this.item = lang.mixin(this.item, params);
				this.emit('decorationChanged', { handler: this, params })
			},

			setVisible: function(visible) {
				if(this.item.visible == visible)
					return;

				this.set({
					'style': {display: (visible ? "" : "none") }
				});

				this.item.visible = visible;
				this.emit("visibilityChanged", this);
			},

			getVisible: function() {
				return typeof(this.item.visible) == 'undefined' ? true : this.item.visible;
			},

			isChecked: function () {
				return this.item.checked;
			},

			isDisabled: function() {
				return Boolean(this.item.disabled)
			},

			update: function(params) {
				// NOTE: parent may changed here
				this.item = lang.mixin(this.item, params);
				this.emit("changed", this);
			},

			remove: function() {
				this.emit("removed", this);
			}
		});

		//FIXME cannot define and preserve exports the same, maybe a mixin?
		exports.on = Evented.prototype.on;
		exports.emit = Evented.prototype.emit;
		exports._rememberAuthToken = false;
		exports._authTokenUpdateTime = 0;
		exports._hashParamList = {};
		exports._selectedViewHandler = null;

		exports.parseHashParamList = function() {

			var hs = hash();

			if(hs) {
				// Make object from query string
				this._hashParamList = ioQuery.queryToObject(hs);
				// Clear hash
				hash('', true);
			}
		};

		exports.getHashParam = function(param) {

			if(!param) {
				return this._hashParamList;
			}

			if(typeof(this._hashParamList[param]) !== 'undefined') {
				return this._hashParamList[param];
			}

			return null;
		};

		exports.onInit = function() {

			this._menuItemList = [];
			this._subMenuItemList = []
			this._layoutList = [];
			this._viewList = [];
			this._styleSheetList = [];
			this._permissionList = [];
			this._taskTypes = [];
			this._catalogMap = {};
			this._catalogTabMap = {}
			this._lastPermId = 0;
			this._pollingState = true;

			server.on("authTokenRefresh", on_authTokenRefresh);
			server.on("serverError", on_serverError.bind(this));
			server.on("longRequest", on_longRequest);
			server.on("endLongRequest", on_endLongRequest);
			server.on("sessionExpired", on_sessionExpired);

			Session.ready().then(function(current) {
				const { showForbiddenError } = current.getClientUiSettings()
				this._showForbiddenError = Boolean(showForbiddenError)

				on(window, 'focus', function() {
					if (this._pollingState) {
						return;
					}
					this._pollingState = true;
					Session.current.enablePolling().then(function() {
						this.emit("pollingState", true);
					}.bind(this));
				}.bind(this));
				on(window, 'blur', function() {
					if (!this._pollingState) {
						return;
					}
					this._pollingState = false;
					Session.current.disablePolling();
					this.emit("pollingState", false);
				}.bind(this));
			}.bind(this), () => {});

			this.messages = nlsCommon;

			const user = Session.current?.getUser()
			if (user) {
				this.registerMenuItem({
					label: string.substitute(nlsCommon.userNameNodeText, [user.getLogin()]),
					region: 'right',
					sort: 600,
				})
			}

			this.menuSettings = UserSettings('menuSettings') || {};
			this._menuItemIds = {};

			this.registerMenuItem({
				class: 'menuLogoIcon',
				region: 'left',
				sort: 0
			});

			this.registerMenuItem({
				title: this.messages.menuItemsSettingsTitle,
				topic: "menuSettings",
				iconClass: 'menuSettingsIcon',
				region: 'left',
				sort: 100,
				onClose: function() {
					UserSettings('menuSettings', this.menuSettings);
				}.bind(this)
			});

			this.registerMenuItem({
				title: this.messages.toolsMenuLabel,
				topic: 'tools',
				icon: 'mdi-tools',
				place: 'map',
				class: 'atMenuItemTools',
			});

			//add menu items to layout
			this.registerMenuItem({
				title: this.messages.exitLabel,
				iconClass: 'mdi mdi-logout-variant',
				onClick: this.logout.bind(this),
				'class': 'atLogoutButton',
				region: 'right',
				sort: 1000
			});

			this.registerLayout({ id: this.mainLayoutId() })
		};

		exports.initTitle = function(title) {
			document.title = title || nlsCommon.appTitle.short
		}

		exports.connectAuthTokenChanged = function() {
			server.on('authTokenChanged', (token) => this.setAuthToken(token, true))
		}

		exports.isPollingEnabled = function() {
			return this._pollingState;
		};

		function on_authTokenRefresh() {
			if (!exports._rememberAuthToken) {
				return;
			}
			var curtime = (new Date()).valueOf();
			if (curtime - exports._authTokenUpdateTime < AUTHTOKEN_REFRESHTIME) {
				return;
			}
			exports._authTokenUpdateTime = curtime;
			var _token = cookie(AUTH_COOKIE);
			var options = {};
			options.expires = AUTHTOKEN_EXPIRE_DAYS;
			cookie(AUTH_COOKIE, _token, options);
		}

		function on_longRequest() {
			exports.emit("longRequest");
		}

		function on_endLongRequest() {
			exports.emit("endLongRequest");
		}

		function on_serverError(message, httpCode) {
			// FIXME  notification dialog from here ?
			exports.emit("serverError", message);
			if (httpCode === 418 && !this._reloadTimer) {
				// use timer just in case if server returns 418 but after reloading user get the same page
				console.log("Got 418 http code from server, schedule reload (1 second timeout)")
				this._reloadTimer = setTimeout(() => {
					location.reload(false)
				}, 1000)
			}

			// Forbidden handler
			if (httpCode === 403 && this._showForbiddenError) {
				Notify.create({
					message: [
						i18n.gettext('One or more calls to the server were blocked.'),
						i18n.gettext('The system may not work correctly.'),
						i18n.gettext('Contact to administrator of the system.'),
					].join('<br>'),
					html: true,
					type: 'negative',
					group: 'network:error:403',
					timeout: 0,
					badgeColor: 'warning',
					badgeTextColor: 'dark',
					actions: [{
						icon: 'mdi-refresh',
						color: 'white',
						fab: true,
						handler: () => location.reload(),
					}]
				})
			}
		}

		function on_sessionExpired() {
			cookie(AUTH_COOKIE, ""); //NOTE: reset auth token
			location.reload(false);
		}

		exports.onStart = function() {
			this._started = true;

			if (this._styleSheetList.length) {
				var head = document.getElementsByTagName('head')[0];
				var idPrefix = 'css-';

				this._styleSheetList.forEach(function(item) {
					if (!document.getElementById(idPrefix + item.id)) {
						var module = (item.moduleName ? Session.current.getExtModuleDesc(item.moduleName) : null);
						var link   = document.createElement('link');
						link.id    = idPrefix + item.id;
						link.rel   = 'stylesheet';
						link.type  = 'text/css';
						link.href  = item.url + '?v=' + (module && module.version ? module.version : Date.now());
						link.media = 'all';
						head.appendChild(link);
					}
				});
			}
		};

		///NOTE: attach to component life cycle
		component.attach(exports);

		exports.leave = function() {
			history.go(-1);
		};

		///NOTE: public interface
		///TODO: describe the "item" argument structure
		/// Add new menu item
		exports.registerMenuItem = function(item) {

			if (typeof(item.label) == 'undefined') {
				item.label = '';
			}

			if (typeof(item.sort) == 'undefined') {
				// Set default sort index
				item.sort = 10000;
			}

			if (typeof(item.region) == 'undefined') {
				item.region = 'center';
			}

			if(item.region == 'center' && typeof(item.visible) == 'undefined') {
				item.visible = true;
			}

			var hnd = new MenuHandler({ item: item });
			hnd.on("removed", function(hnd) {
				var itemId = hnd.item.id;
				this._menuItemList.slice().some(function(h, i) {
					if (itemId === h.item.id) {
						delete this.menuSettings[itemId];
						if (this.menuSettings._selectedItemId === itemId) {
							delete this.menuSettings._selectedItemId;
						}
						UserSettings('menuSettings', this.menuSettings);
						delete this._menuItemIds[itemId];
						return this._menuItemList.splice(i, 1);
					}
				}, this);
			}.bind(this));

			hnd.on('decorationChanged', ({ handler: hnd }) => {
				const list = this.menuItemList()
				list.forEach((handler) => {
					if (handler === hnd) return
					if (handler.item.type !== 'radio') return
					if (handler.item.group !== hnd.item.group) return
					if (!hnd.item.checked) return
					handler.set({ checked: false })
					if (typeof handler.item.onClick === 'function') {
						handler.item.onClick(handler.item)
					}
				})
			})

			this._menuItemList.push(hnd);

			if (item.optional) {
				if (!item.id) {
					console.error("Optional menu item should have unique id property." , item.label || item.title);
				}
				if (item.id && this._menuItemIds[item.id]) {
					var old = this._menuItemIds[item.id];
					console.error("Optional menu already exists." , old.id, old.label || old.title);
					console.error("Optional menu item should have unique id property." , item.id, item.label || item.title);
				} else {

					var isItemVisible = this.isMenuItemVisible(item);
					hnd.setVisible(isItemVisible);

					const updateSubMenuState = () => {
						const visibleMenuHandlers = this._subMenuItemList
							.filter((hnd) => hnd.isChecked())

						const enabled = visibleMenuHandlers.length > 1

						visibleMenuHandlers.forEach((hnd) => {
							hnd.set({ disabled: !enabled })
						})
					}

					var subMenuHnd = this.registerMenuItem({
						id: item.id,
						parent: "menuSettings",
						label: item.label || item.title,
						checked: isItemVisible,
						sort: item.sort,
						onClick: (item) => {
							this.menuSettings[item.id] = { visible: item.checked }
							hnd.setVisible(item.checked)
							updateSubMenuState()
						},
					});

					this._subMenuItemList.push(subMenuHnd)
					updateSubMenuState()

					hnd.on("visibilityChanged", function(subMenuHnd, parentHnd) {
						subMenuHnd.set({"checked": parentHnd.getVisible()});
						this.menuSettings[subMenuHnd.item.id] = { visible: parentHnd.getVisible() };
						UserSettings('menuSettings', this.menuSettings);
					}.bind(this, subMenuHnd));

					hnd.on("removed", () => {
						subMenuHnd.remove()
						const index = this._subMenuItemList.indexOf(subMenuHnd)
						if (index >= 0) this._subMenuItemList.splice(index, 1)
					})
				}
			}

			if (item.id) {
				this._menuItemIds[item.id] = item;
			}

			if (this._started)
				this.emit("newMenuItem", hnd);

			return hnd;
		};

		exports.isMenuItemVisible = function(item) {
			var id = (typeof(item) === 'string' ? item : item.id);
			return typeof(this.menuSettings[id]) == 'undefined' ||
					(typeof(this.menuSettings[id]).visible == 'undefined' ?
						true : this.menuSettings[id].visible);
		};

		exports.menuItemList = function() {
			return this._menuItemList;
		};

		exports.getMenuItem = function(itemId) {
			return this.menuItemList().find(({ item }) => item.id === itemId)
		}

		exports.ViewType = { Form: "Form", Dialog: "Dialog" };

		/// Provide application with view, embeddable to application's layout
		exports.registerView = function(item) {

			item.title = item.title || "Unknown";
			item.region = item.region || "center";
			item.viewType = item.viewType || this.ViewType.Form;
			item.layoutId = item.layoutId || this.mainLayoutId();

			item.visible = Boolean(item.visible)

			const hnd = new ViewHandler({ view: item })

			if (item.menu) {
				item.menu.id = item.menu.id ?? item.id
				item.menu.label = item.menu.label ?? item.title

				if (!item.menu.onClick) {
					item.menu.onClick = () => {
						const { id, layoutId } = hnd.view

						this._selectedViewHandler?.blur()

						this.switchLayout(layoutId)

						this._viewList.forEach((handler) => {
							if (handler.view.menuId && handler.view.menuId !== id) handler.hide()
							else if (handler.view.id === id) handler.focus()
							else if (handler.view.menuId === id) {
								handler.view.focus ? handler.focus() : handler.show()
							}
						})
					}
				}
			}

			if (item.menu || item.menuId) {

				const menuHnd = item.menuId
					? this.getMenuItem(item.menuId)
					: this.registerMenuItem(item.menu)
				if (!menuHnd) return

				if (menuHnd.item.optional) {
					menuHnd.on('visibilityChanged', function(viewHnd, menuHnd) {
						if (!menuHnd.getVisible()) viewHnd.hide(true)
					}.bind(this, hnd))
				}

				if (item.menu?.optional) {
					hnd.on('focus', function(viewHnd, menuHnd) {
						menuHnd.setVisible(true);

						this._selectedViewHandler = viewHnd;
						this.switchLayout(viewHnd.view.layoutId);

						this.menuSettings._selectedItemId = menuHnd.item.id;
						UserSettings('menuSettings', this.menuSettings);

						this.emit('menuFocusChanged', menuHnd.item);
					}.bind(this, hnd, menuHnd));

					if(!menuHnd.getVisible())
						hnd.hide();
				}
			}

			this._viewList.push(hnd);
			return hnd;
		};

		exports.selectedViewHandlerId = function() {

			var layoutHnd = this._selectedLayoutHandler,
				viewHnd = this._selectedViewHandler;

			if(layoutHnd && layoutHnd.layout.id != this.mainLayoutId()) {
				return layoutHnd.layout.id;
			} else if (viewHnd && viewHnd.view.id) {
				return viewHnd.view.id;
			} else {
				return null;
			}
		};

		exports.viewList = function() {
			return this._viewList;
		};

		exports.focusView = function(id) {
			if((!this._selectedViewHandler || this._selectedViewHandler.view.id != id) && this._menuItemIds[id]) {
				return this._viewList.some(function(hnd) {
					if(hnd.view.id == id)  {
						hnd.focus();
						return true;
					}
				});
			}
			return false;
		};

		exports.registerLayout = function(item) {
			var hnd = new LayoutHandler({ layout: item });
			this._layoutList.push(hnd);

			if (item.menu) {

				if (!item.menu.onClick) {
					item.menu.onClick = function(layoutHnd) {
						this.switchLayout(layoutHnd.layout.id);

						this.menuSettings._selectedItemId = item.menu.id;
						UserSettings('menuSettings', this.menuSettings);
					}.bind(this, hnd);
				}

				if (!item.menu.label) {
					item.menu.label = item.title;
				}

				if(!item.menu.id) {
					item.menu.id = item.id;
				}

				var menuHnd = hnd.menu = this.registerMenuItem(item.menu);

				if (item.menu.optional) {

					menuHnd.on("visibilityChanged", function(layoutHnd, menuHnd) {
						if(!menuHnd.getVisible()) {
							layoutHnd.hide();
							this.hideLayoutViews(layoutHnd.layout.id, true)
						}
					}.bind(this, hnd));

					hnd.on("show", function(menuHnd) {
						menuHnd.setVisible(true);
						this.emit('menuFocusChanged', menuHnd.item);
					}.bind(this, menuHnd));
				}
			}
			return hnd;
		};

		exports.layoutList = function() {
			return this._layoutList;
		};

		exports.mainLayoutId = function() {
			return 'main';
		};

		exports.switchLayout = function(layoutId) {
			const selectedLayoutId = this._selectedLayoutHandler?.layout?.id

			if (selectedLayoutId === layoutId) return

			if (selectedLayoutId) {
				this.hideLayoutViews(selectedLayoutId)
				this._selectedLayoutHandler.hide()
			} else {
				this._layoutList.forEach((hnd) => {
					if (hnd.layout.id !== layoutId) hnd.hide()
				})
			}

			this._selectedLayoutHandler = this._layoutList
				.find((hnd) => hnd.layout.id === layoutId)

			this._selectedLayoutHandler.show()
			this.showLayoutViews(layoutId)
		}

		exports.showLayoutViews = function(layoutId) {
			this._viewList.forEach(function(viewHandler) {

				if (viewHandler.view.layoutId == layoutId &&
					viewHandler.isVisible() &&
					!viewHandler.isCreated()
				) {
					viewHandler.view.visible = false;
					viewHandler.show();
				}
			}, this);
		};

		exports.hideLayoutViews = function(layoutId, destroy) {
			this._viewList.forEach(function(viewHandler) {

				if (viewHandler.view.layoutId == layoutId) {

					var visible = viewHandler.isVisible();

					if (visible) {
						viewHandler.hide(destroy)
						viewHandler.view.visible = true;
					}
				}
			}, this);
		};

		/**
		 * @param perm expects an object of following structure:
		 *     - id: uniqie identity, optional
		 *     - parent: reference ot unique identity, optional, top level object if not supplied
		 *     - textTrKey: display text
		 *     1)
		 *     - perm: an array of permissions to manage with given item, together with _mask_ founds optional group of properties
		 *     - mask: a permission mask to turn on or off, together with _perm_ founds optional group of properties
		 *     2)
		 *     - perm: an custom permission object realized as extensions to SecurityContext with following fields:
		 *         - extensionId: id of extension
		 *         - key: permission key related to extension
		 *         - hasCallback: function(role)  callback returning true if given role has this permission
		 *         - setCallback: function(role, value)  set this permission for given role
		 */
		exports.registerPermission = function(perm) {
			if (perm && perm.forEach) {
				//NOTE: permission group mass registration
				perm.forEach(function(p) {
					this._addPerm(p);
				}, this);
			} else {
				//NOTE: single permission group registration
				this._addPerm(perm);
			}
		};

		exports._addPerm = function(p) {
			this._permissionList.push(p);
		};

		/**
		 * @returns: array of permission tree leaves and branches
		 * returns a forest (no single root) and only explicitly added permission objects
		 * @see registerPermission
		 */
		exports.permissionList = function() {
			return lang.clone(this._permissionList);
		};

		/*
		 * - taskType: class that implements Task interface (see core/task.js)
		 */
		exports.registerTaskType = function(taskType) {
			this._taskTypes.push(taskType);
		};

		exports.taskTypes = function() {
			return this._taskTypes;
		};

		/// Register new stylesheet to be loaded
		exports.registerStyleSheet = function(item) {

			if (!item || !item.url || !item.id) {
				throw new Error('Missed id or url for stylesheet');
			}

			this._styleSheetList.push(item);
		};

		exports.createCatalog = function(params) {
			return Object.assign({
				confirmDelete: true,
				uiParams: {},
				reset: () => {},
			}, params);
		},

		/**
		 * Register catalog entity
		 */
		exports.registerCatalog = function(name, params) {
			this._catalogMap[name] = assign({
				id: name,
				title: name // fallback
			}, this.createCatalog(params));
		};

		/**
		 * @returns {Object} - map of all registered catalogs
		 */
		exports.getCatalogs = function() {
			return this._catalogMap
		};

		exports.setAuthToken = function(authToken, reload) {
			const token = authToken || cookie(AUTH_COOKIE)
			const options = {}
			if (exports._rememberAuthToken) {
				exports._authTokenUpdateTime = (new Date()).valueOf()
				options.expires = AUTHTOKEN_EXPIRE_DAYS
			}
			cookie(AUTH_COOKIE, token, options)
			if (reload) location.reload(false)
		}

		exports.registerCatalogTab = function(catalogId, params) {
			this._catalogTabMap[catalogId] = this._catalogTabMap[catalogId] || []
			if (typeof params.title !== 'string') throw new Error('parameter "title" is required and must be a string')
			if (typeof params.perms !== 'object') throw new Error('parameter "perms" is required and must be an object')
			if (typeof params.getView !== 'function') throw new Error('parameter "getView" is required and must be a function')
			this._catalogTabMap[catalogId].push({
				...params,
			})
		}

		exports.getCatalogTabs = function(catalogId) {
			return this._catalogTabMap[catalogId] || []
		}

		exports.login = function(data) {
			//NOTE: a result it will fire error event or reload the page
			this._rememberAuthToken = data.rememberMe;
			return server.login(data.username, data.password, data.rememberMe).then(function(authToken) {
				this.checkLocale();
				this.setAuthToken(authToken, true) // NOTE: cause reload
			}.bind(this));
		};

		// NOTE: returns true if locale is changed
		exports.checkLocale = function() {
			var serverLocale = UserSettings('language');
			if (serverLocale && serverLocale != config.locale) {
				// cookie(config.localeCookie, serverLocale);
				config.locale = serverLocale
				return true;
			}
			return false;
		};

		exports.endSession = function() {
			const sessionId = cookie(AUTH_COOKIE)
			if (!sessionId) return
			cookie(AUTH_COOKIE, null)
			return server.endSession(sessionId)
		}

		exports.logout = function() {
			load.show();
			Session.current.disablePolling();
			cookie(AUTH_COOKIE, null, {expires: -1});

			var logout = function() {
				server.logout().then(function() { location.reload() }, function() { location.reload() });
			};

			if(window.onbeforeunload) {
				window.onbeforeunload(logout);
			} else {
				logout();
			}
		};
	}
);
