import { DispatchObject } from 'webapi/main/DispatchObject'
import { dateTimePropertyMixin } from 'webapi/main/_dateTimePropertyMixin'
import { Vehicle } from 'webapi/dispatch/Vehicle'
import wapi from 'webapi/rpc/web'
import Repository from '../main/Repository'

const entityName = 'maintenance_schedule'
const byVehicleIdsGetter = 'byVehicleIds'

export class MaintenanceSchedule extends dateTimePropertyMixin(DispatchObject, 'initial_timestamp', 'InitialDateTime') {
	static entityName = entityName

	/**
	 * @private
	 */
	_parentConstructor = Vehicle

	getName() {
		return this.get('name')
	}
	setName(value) {
		return this.set('name', value)
	}

	hasPeriodMileageKm() {
		return typeof this.get('period_mileage_km') === 'number'
	}
	getPeriodMileageKm() {
		return this.get('period_mileage_km')
	}
	setPeriodMileageKm(value) {
		return this.set('period_mileage_km', value)
	}

	getInitialMileageKm() {
		return this.get('initial_mileage_km')
	}
	setInitialMileageKm(value) {
		return this.set('initial_mileage_km', value)
	}

	hasPeriodDays() {
		return typeof this.get('period_days') === 'number'
	}
	getPeriodDays() {
		return this.get('period_days')
	}
	setPeriodDays(value) {
		return this.set('period_days', value)
	}

	registerMaintenanceDone(
		date /* = null */,
		scheduledDate /* = null */,
		mileageKm /* = null */,
		scheduledMileageKm /* = null */,
	) {
		return wapi.send('Tracks.registerMaintenanceDone', [
			this.getId(),
			date ? Math.floor(date.valueOf() / 1000) : null,
			scheduledDate ? Math.floor(scheduledDate.valueOf() / 1000) : null,
			typeof mileageKm === 'number' && !Number.isNaN(mileageKm) ? mileageKm : null,
			typeof scheduledMileageKm === 'number' && !Number.isNaN(mileageKm) ? scheduledMileageKm : null,
		])
	}
}

export async function getByVehicleIds(ids) {
	const propsList = await Repository.customGetter[entityName][byVehicleIdsGetter](ids)
	return propsList.map((properties) => new MaintenanceSchedule(properties.parent, properties))
}
MaintenanceSchedule.getByVehicleIds = getByVehicleIds

export async function getByVehicle(object) {
	const propsList = await Repository.customGetter[entityName][byVehicleIdsGetter]([object.getId()])
	return propsList.map((properties) => new MaintenanceSchedule(object, properties))
}
MaintenanceSchedule.getByVehicle = getByVehicle

Repository.registerCustomGetter(entityName, byVehicleIdsGetter, (vehicleIds) => (
	wapi.send('maintenance_schedule.getByVehicleIds', [vehicleIds])
))
