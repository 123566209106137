/* eslint-disable max-classes-per-file */
import wapi from 'webapi/rpc/web'
import { PromiseIterator } from './PromiseIterator'
import { DispatchObject } from './DispatchObject' // "./Group" // FIXME API circular dependencies
import { Role } from './Role'
import { dateTimePropertyMixin } from './_dateTimePropertyMixin'
import repository from './Repository'
import { extensionPropertyMixin } from './_extensionPropertyMixin'

class LicenseLimit extends dateTimePropertyMixin(DispatchObject, 'license_limit', 'LicenseExpires', 'date') {}
class Created extends dateTimePropertyMixin(LicenseLimit, 'created', 'Created') {}
class Activation extends dateTimePropertyMixin(Created, 'activation', 'Activation') {}
class CompanyExtensionProperty extends extensionPropertyMixin(Activation, 'company') {}

export class Company extends CompanyExtensionProperty {
	static entityName = 'license'

	getName() {
		return this.$name || this._getParentName()
	}

	setName(name) {
		return this._setParentName(name)
	}

	/**
	 * @private
	 */
	_getParentName() {
		const p = this.getParent()
		if (p) {
			return p.getName()
		}
		return ''
	}

	/**
	 * @private
	 */
	_setParentName(name) {
		const p = this.getParent()
		if (p) p.setName(name)

		return this
	}

	getVehicleLimit() {
		return this.get('vehicle_limit')
	}

	/// clarify value for "unlimited"
	setVehicleLimit(maxNumber) {
		return this.set('vehicle_limit', Number(maxNumber))
	}

	getVehicleCount() {
		return this.get('vehicle_count')
	}

	getUserLimit() {
		return this.get('user_limit')
	}

	setUserLimit(maxNumber) {
		return this.set('user_limit', Number(maxNumber))
	}

	getLicenseAutorenew() { /// FIXME DB value may be null
		return this.get('autorenew')
	}

	setLicenseAutorenew(isAutorenew) {
		return this.set('autorenew', isAutorenew)
	}

	getLicenseTrial() { /// FIXME incorrect field mapping
		return this.get('license_type')
	}

	setLicenseTrial(isTrial) {
		return this.set('license_type', isTrial)
	}

	getActivationKey() {
		return this.get('activation_key')
	}

	getRole() {
		return Role.get(false, this)
	}

	async getIds() {
		return [this.getId()]
	}
}

function fromJsonRpc(parameters) {
	return new Company(parameters && parameters.parent, parameters)
}

export function get(recursive, group) {
	return new PromiseIterator(
		group.getIds().then((idList) => getByGroupIds(idList, recursive)),
	)
}
Company.get = get

export async function getByGroupIds(idList, recursive = false) {
	const list = await repository.listGetter.license(recursive, idList)
	return list.map(fromJsonRpc)
}
Company.getByGroupIds = getByGroupIds

export async function getById(id) {
	const props = await repository.byIdGetter.license(id)
	return fromJsonRpc(props)
}
Company.getById = getById

/**
 * @private
 */
function _invalidate() {
	wapi.cacheInvalidate('license.get', [])
}

repository.registerListGetter('license', (recursive, groups) => wapi.send('license.get', { recursive, groups }))
repository.registerByIdGetter('license', (id) => wapi.send('license.getById', [id]))
repository.registerInvalidator('license', _invalidate)

wapi.cacheRegister('license.get', {
	getPath: ({ recursive, groups }) => [recursive, groups],
})
