define(/* "LRUCache", */ [], function() {
	function LRUCache(cacheSettings) {
		this._index = {};
		this._lruKeyList = {length: 0, counter: 0};
		this._lruKeyMap = {};
		this.settings = cacheSettings;
		this._data = {};
	};

	LRUCache.prototype.getDataKey = function(key) {
		return key.map(function(k){ return k.toString(); }).join("/");
	};

	LRUCache.prototype.put = function(key, value) {
		var holder = this._index;

		key = key.map(function(k){ return k.toString(); });
		var _prev = holder;
		var _key = null;

		key.forEach(function(k) {
			_key = k;
			_prev = holder;
			if(!holder.hasOwnProperty(k)) {
				holder[k] = {};
			}
			holder = holder[k];
		});

		var dataKey = this.getDataKey(key);
		holder._dataKey = dataKey;
		this._data[dataKey] = value;

		//Update LRU
		var it = this._lruKeyMap[dataKey];

		if(it >= 0) {//replace old
			delete this._lruKeyMap[dataKey];
			delete this._lruKeyList[it];
			this._lruKeyList.length--;
		}

		var counter = ++(this._lruKeyList.counter);
		this._lruKeyList[counter] = {key: dataKey, index: {obj: _prev, key: _key}, date: Date.now()};
		this._lruKeyMap[dataKey] = counter;
		this._lruKeyList.length++;

		if(this.settings && this.settings.maxSize > 0 && this._lruKeyList.length > this.settings.maxSize) {
			var minIt = null;
			for(var i in this._lruKeyList) {
				if(i >= 0 && (minIt == null || minIt > i)){
					minIt = i;
				}
			}

			if(minIt != null) {
				var first = this._lruKeyList[minIt];
				delete this._lruKeyList[minIt];
				var dataKey = first.key;
				delete this._lruKeyMap[dataKey];
				delete first.index.obj[first.index.key];
				delete this._data[dataKey];
				this._lruKeyList.length--;
			}
		}
	};

	LRUCache.prototype.get = function(key) {
		var dataKey = this.getDataKey(key);
		this.clearExpired();

		if(this._lruKeyMap) {
			var it = this._lruKeyMap[dataKey];
			if(it >= 0) {
				var item = this._lruKeyList[it];
				delete this._lruKeyList[it];
				var counter = ++(this._lruKeyList.counter);
				this._lruKeyList[counter] = item;
				this._lruKeyMap[dataKey] = counter;
			}
		}

		return this._data[dataKey];
	};

	LRUCache.prototype.clearExpired = function() {

		if (!this.settings.expire) {
			return;
		}

		for (var dataKey in this._data) {
			if (this._data.hasOwnProperty(dataKey)) {

				var item = this._lruKeyList[this._lruKeyMap[dataKey]];

				if (Math.abs(Date.now() - item.date) >= this.settings.expire) {
					this.clear(dataKey);
				}
			}
		}
	};

	LRUCache.prototype.clear = function(key) {
		function traverseIndex(o, func) {
			for (var i in o) {
				if (!Object.prototype.hasOwnProperty.call(o, i))
					continue;

				var value = o[i];
				if(i === "_dataKey") {
					func.call(this, value);
				} else if (typeof(value) !== "undefined") {
					traverseIndex(value, func);
				}
			}
		}

		var result = this._index;
		var _prev = result;
		var _key = null;

		if(key) {
			if(typeof key === 'string')
				key = [key];

			_key = key[0];

			key.forEach(
				function(k) {
					_key = k.toString();
					_prev = result;
					if(result && result.hasOwnProperty(_key)) {
						result = result[_key];
					}
					else {
						result = null;
					}
				}
			);
		}

		if(_key && _prev && _prev.hasOwnProperty(_key)) {
			traverseIndex(_prev[_key], function(key) {
				delete this._data[key];
				var it = this._lruKeyMap[key];
				delete this._lruKeyMap[key];
				delete this._lruKeyList[it];
				this._lruKeyList.length--;
			}.bind(this));

			delete _prev[_key];
		}
		else { // if key is undefined then clear all
			this._index = {};
			this._lruKeyList = {length: 0, counter: 0};
			this._lruKeyMap = {};
			this._data = {};
		}
	};

	return LRUCache;
});
