import { ReportTemplate } from './ReportTemplate'

export class ReportTemplateVehicleTripGeneral extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.VehicleTripGeneral)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.VehicleTripGeneral
	}
}
