import wapi from 'webapi/rpc/web'
import { DispatchObject } from './DispatchObject'
import Repository from './Repository'
import { Transaction } from './Transaction'
import { StoreScheme } from './StoreScheme'
import { AsyncLoaders } from './AsyncLoaders'
import * as Session from './Session'
import { Group } from './Group'

let extendedDataCache = {}

export class Store extends DispatchObject {
	static entityName = 'store'

	/**
	 * @private
	 */
	_parentConstructor = StoreScheme

	/*
	store data example: //see scheme example in StoreScheme.js
	{
		"scheme_field_uuid_1" : "Ivan",
		"scheme_field_uuid_2": "Pupkin",
		"scheme_field_uuid_3": "1985-03-15",
		"scheme_field_uuid_4": "11eba9d4-2f4b-4dc1-8df1-8c2223ec0e27",//static id of other store data object with parent scheme "country"
		"scheme_field_uuid_5": {//TODO: not implemented
				"scheme_field_uuid_6": "abracadabra",
				"scheme_field_uuid_7": "91b4b6d7-4f39-48f8-a7fe-00c354f1a6cd"//static id of other store data object with parent scheme "country"
			}
		}
	}
	*/
	getData() {
		return this.getJson('data_json', {})
	}

	setData(data) {
		return this.setJson('data_json', data, (old, val) => old && JSON.stringify(JSON.parse(old)) === val)
	}

	isEmpty() {
		return JSON.stringify(this.getData()) === '{}'
	}

	/**
	 * @deprecated
	 * Parent may not be in cache
	 */
	getScheme() {
		return this.getParent()
	}

	getSchemePromise() {
		return this.getParentPromise()
	}

	getExtensionFor() {
		return this.get('extension_for')
	}

	setExtensionFor(value) {
		return this.set('extension_for', value)
	}

	// TODO: make server method
	async getAvailableGroups() {
		const scheme = await this.getParentPromise()
		const groupList = []
		if (!scheme) return groupList

		if (scheme.getExtensionFor()) {
			return Session.current.getRoot()
				.getGroupRecursive()
				.toPromise()
		}

		if (scheme.getInheritStaticId()) {
			const parentScheme = await StoreScheme.getById(scheme.getInheritStaticId())
			if (!parentScheme) {
				return Session.current.getRoot()
					.getGroupRecursive()
					.toPromise()
			}
			const group = await Group.getById(parentScheme.getParentId())
			return group.getGroupRecursive()
				.toPromise()
		}
		const group = await Group.getById(scheme.getParentId())
		return group.getGroupRecursive()
			.toPromise()
	}
}

function fromJsonRpc(parameters) {
	return parameters ? new Store(parameters && parameters.parent, parameters) : null
}

export async function getByScheme(scheme, forOwnScheme) {
	const list = await Repository.customGetter.store.bySchemeId(scheme, forOwnScheme)
	return list.map((props) => fromJsonRpc(props))
}
Store.getByScheme = getByScheme

export async function getBySchemeId(schemeId, forOwnScheme) {
	const scheme = await StoreScheme.getById(schemeId)
	return scheme ? Store.getByScheme(scheme, forOwnScheme) : []
}
Store.getBySchemeId = getBySchemeId

export async function getByExtendedObjectId(scheme, extendedObjectId) {
	const properties = await Repository.customGetter.store.byExtendedObjectId(scheme, extendedObjectId)
	return fromJsonRpc(properties)
}
Store.getByExtendedObjectId = getByExtendedObjectId

export async function getById(id) {
	const properties = await Repository.byIdGetter.store(id)
	return fromJsonRpc(properties)
}
Store.getById = getById

export function getAsyncLoaders() {
	return new AsyncLoaders({ requestGet: 'store.getBySchemeIdPortion' })
}
Store.getAsyncLoaders = getAsyncLoaders

/**
 * @private
 */
function _invalidate() {
	wapi.cacheInvalidate('store.getBySchemeId')
	wapi.cacheInvalidate('store.byExtendedObjectId')
	wapi.cacheInvalidate('store.getObjectsExtendedData')
	extendedDataCache = {}
}

// returns map: {fieldId: {data: dataArray}
export async function getRelatedData(scheme, forOwnScheme) {
	const relFields = scheme ? scheme.getFields().filter((f) => f.relation) : null
	if (!relFields || !relFields.length) return {}

	const dataReq = []
	const relatedSchemeIdMap = {}

	relFields.forEach((f) => { // to skip duplicates
		if (!relatedSchemeIdMap[f.relation.schemeId]) {
			relatedSchemeIdMap[f.relation.schemeId] = [f]
			dataReq.push(f.relation.schemeId)
		} else {
			relatedSchemeIdMap[f.relation.schemeId].push(f)
		}
	})

	const promiseList = dataReq.map((id) => {
		if (id == null) return undefined
		return Store.getBySchemeId(id, Boolean(forOwnScheme))
	})

	const dataList = await Promise.all(promiseList)
	const result = {}
	dataReq.forEach((schemeId, i) => {
		const data = dataList[i]

		relatedSchemeIdMap[schemeId].forEach((field) => {
			result[field.id] = data
		})
	})

	return result
}
Store.getRelatedData = getRelatedData

// returns map: {fieldId: {data: [{id, name}, ...]}
export async function getRelatedIdNameData(scheme, forOwnScheme) {
	const relDataMap = await Store.getRelatedData(scheme, forOwnScheme)

	const scm = scheme.getScheme()
	const result = {}

	Object.entries(relDataMap).forEach(([fieldId, values]) => {
		result[fieldId] = []
		if (values) {
			values.forEach((s) => {
				const name = s.getData()[scm[fieldId].relation.fieldId]
				if (name) {
					result[fieldId].push({
						id: s.getId(),
						name,
					})
				}
			})
		}
	})

	return result
}
Store.getRelatedIdNameData = getRelatedIdNameData

export async function getExtentionForData(extensionForName, extensionForIdList) {
	const reqIds = []
	const cachedResult = {}
	extensionForIdList.forEach((id) => {
		if (extendedDataCache[id]) {
			cachedResult[id] = extendedDataCache[id]
		} else {
			reqIds.push(id)
		}
	})

	if (!reqIds.length) return cachedResult

	const data = await wapi.send('store.getObjectsExtendedData', [extensionForName, reqIds], { withoutTimeout: true })

	Object.assign(cachedResult, data)
	Object.keys(cachedResult).forEach((id) => {
		extendedDataCache[id] = cachedResult[id]
	})

	return cachedResult
}
Store.getExtentionForData = getExtentionForData

export async function getAvailableEventReactionComments() {
	return wapi.send('store.getAvailableEventReactionComments')
}
Store.getAvailableEventReactionComments = getAvailableEventReactionComments

export function deleteObjWithExtention(object) {
	const tx = new Transaction()
	const storeData = Repository.data('store')

	if (!storeData || typeof storeData !== 'object') return
	Object.values(storeData).forEach((data) => {
		const store = new Store(null, data)
		if (store.getExtensionFor() === object.getId()) store.delete(tx)
	})

	object.addExternalTransaction(tx)
}
Store.deleteObjWithExtention = deleteObjWithExtention

Repository.registerCustomGetter('store', 'bySchemeId', async (scheme, forOwnScheme) => {
	const forOwn = Boolean(forOwnScheme)
	const id = scheme.mustInherit() ? scheme.get('inheritsFrom') : scheme.getId()

	if (!id) return [] // when scheme doesn't exists, don't send request

	return wapi.send('store.getBySchemeId', [id, scheme.getParentId(), forOwn])
})

Repository.registerCustomGetter('store', 'byExtendedObjectId', (scheme, extendedObjectId) => (
	wapi.send('store.byExtendedObjectId', [scheme.getId(), extendedObjectId])
))
Repository.registerInvalidator('store', _invalidate.bind(Store))
Repository.registerByIdGetter('store', (id) => wapi.send('store.getById', id))

wapi.cacheRegister('store.getBySchemeId', {
	getPath(params) {
		return params
	},
	maxSize: 1000,
})

wapi.cacheRegister('store.byExtendedObjectId', {
	getPath(params) {
		return params
	},
	maxSize: 1000,
})

wapi.cacheRegister('store.getObjectsExtendedData', {
	getPath(params) {
		return [params[0], params[1].join('/')]
	},
	maxSize: 1000,
})
