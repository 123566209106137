import { ReportTemplate } from './ReportTemplate'

export const AggregationType = {
	Day: 'gb_day',
	Month: 'gb_month',
}

const AggregationTypeInverse = {}
Object.entries(ReportTemplate.AggregationType)
	.forEach(([key, value]) => {
		AggregationTypeInverse[value] = key
	})

export class ReportTemplateFencesSummary extends ReportTemplate {
	static AggregationType = AggregationType

	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.GeneralFence)
	}

	setAggregationType(type) {
		if (!(type in AggregationTypeInverse)) {
			throw new TypeError(`ReportTemplateFencesSummary.setAggregationType: invalid type: ${type}`)
		}
		this._setSetting('groupBy', type)
		return this
	}

	getAggregationType() {
		return this._getSetting('groupBy')
	}

	getFenceList() {
		return this._getSetting('fences')
	}

	setFenceList(fences) {
		return this._setSetting('fences', fences)
	}

	getFieldList() {
		return this._getSetting('columns')
	}

	setFieldList(list) {
		return this._setSetting('columns', list)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.GeneralFence
	}
}
