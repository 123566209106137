import { ReportTemplate, AggregationType } from './ReportTemplate'

function assertArray(prefix, list) {
	if (list != null && list.length == null) {
		throw new Error(`${prefix}: argument not an array: ${list}`)
	}
}

const AggregationTypeInverse = {}
Object.entries(AggregationType)
	.forEach(([key, value]) => {
		AggregationTypeInverse[value] = key
	})

export class ReportTemplateGeneral extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.General)
	}

	getFieldList() {
		const list = this.$fieldList || this._getSetting('columns')
		// FIXME update column names in DB
		return list && list.map((c) => {
			if (c === 'deviceName') {
				return 'objectName'
			}
			return c
		})
	}

	setFieldList(list) {
		assertArray('ReportTemplateGeneral.setFieldList', list)
		this._setSetting('columns', list)
		return this
	}

	// Day, month, trip, etc.
	getAggregationType() {
		return AggregationTypeInverse[this._getSetting('groupBy')]
	}

	setAggregationType(type) {
		if (!(type in AggregationType)) {
			throw new TypeError(`ReportTemplateGeneral.setAggregationType: invalid type: ${type}`)
		}
		this._setSetting('groupBy', AggregationType[type])
		return this
	}

	getSubtotalColumn() {
		return this._getSetting('aggregate')
	}

	setSubtotalColumn(columnKey) {
		if (columnKey != null && typeof columnKey !== 'string') {
			throw TypeError(`ReportTemplateGeneral.setSubtotalColumn: not a String ${typeof (columnKey)}`)
		}
		this._setSetting('aggregate', columnKey)
	}

	/// FIXME update API
	/// Skip filter key-value object
	getSkip() {
		return this._getSetting('skip')
	}

	setSkip(skip) {
		this._setSetting('skip', skip)
		return this
	}

	/** Default charts
	* @return Array[String]
	* FIXME Update API
	*/
	getChart() {
		return this._getSetting('chart')
	}

	setChart(chartArray) {
		this._setSetting('chart', chartArray)
		return this
	}

	/** workingHours object: {
	 * 		begin: Number seconds,
	 * 		end: Number seconds,
	 * 		dayOfWeekMask: Number bitmask
	 */
	getWorkingHours() {
		return this._getSetting('workingHours')
	}

	setWorkingHours(workingHoursObject) {
		this._setSetting('workingHours', workingHoursObject)
		return this
	}

	static testProperties(properties) {
		return !properties.type || properties.type === ReportTemplate.Type.General
	}
}
