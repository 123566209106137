define([
	"exports",
	"webapi/main/DispatchServer",
	"dojo/cookie",
], (
	exports,
	{ default: DispatchServer },
	cookie,
) => {
	const AUTH_COOKIE = "sessionId";

	exports.AUTH_COOKIE = AUTH_COOKIE

	// must be called only once at the beginning of app life
	exports.isAuthenticated = () => {
		var authToken = cookie(AUTH_COOKIE)
		if (authToken) {
			return DispatchServer.loginToken(authToken)
		}
	}
})
