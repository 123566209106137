// TODO: make server method to get db structure
export const dbStructure = {
	group: {
		parent: ['commit', 'group'],
		fields: ['title'],
	},

	license: {
		parent: ['group'],
		fields: [
			{ name: 'vehicle_limit', mask: 2 },
			{ name: 'user_limit', mask: 2 },
			{ name: 'driver_limit', mask: 2 },
			{ name: 'license_limit', mask: 2 },
			{ name: 'autorenew', mask: 2 },
			{ name: 'license_type', mask: 2 },
			'created',
			{ name: 'enabled', mask: 2 },
		],
	},

	role: {
		parent: ['license'],
		fields: [
			'name',
			'command',
			'driver',
			'driver_assignment',
			'event_filter',
			'fence',
			'geolayer',
			'group',
			'grouplink',
			'license',
			'maintenance_schedule',
			'parameters_json',
			'place',
			'relaying',
			'reporttemplate',
			'role',
			'role_command',
			'route',
			'schedule',
			'schedule_point',
			'sensor_condition',
			'task',
			'tracker',
			'user',
			'vehicle',
			'vehicle_fence',
			'waypoint',
			'store_scheme',
			'store',
			'sensors_set',

			'trip',
			'report_permissions_json',
			'tracker_status_json',
			'extension_permissions',
			'catalog_vehicle',
			'icons',
		],
	},

	role_command: {
		parent: ['role'],
		fields: ['command_id'/* staticid */],
	},

	user: {
		parent: ['group'],
		hasLimit: true,
		fields: [
			'login',
			'welcome_name',
			'last_login',
			'last_session',
			'email',
			'phone',
			'enabled',
			'srid',
			'ipfilter_json',
			'grouplink',
			{
				name: 'parameters_json',
				mask: 16,
			},
		],
	},
	grouplink: {
		parent: ['user'],
		fields: ['sgid'],
	},

	driver: {
		parent: ['group'],
		hasLimit: true,
		fields: ['name', 'card_id', 'additional_fields_json'],
	},

	driver_assignment: {
		parent: ['driver'],
		fields: ['vehicle_id' /* staticid */, 'source', 'from_timestamp', 'to_timestamp', 'activity'],
	},

	vehicle: {
		parent: ['group'],
		hasLimit: true,
		fields: [
			'name',
			'displaycolor',
			'displayicon',
			'winterbeginmonth',
			'winterbeginday',
			'wintercityrate',
			'winteroutrate',
			'summerbeginmonth',
			'summerbeginday',
			'summercityrate',
			'summeroutrate',
			'fuelwindowduration',
			'eventgenerator_settings_json',
			'description',
			'model_id',
			'vin',
			'parameters_json',
			'mapicon',
			'deleted',
			{ name: 'enabled', mask: 16 },
		],
	},

	tracker: {
		parent: ['vehicle'],
		fields: [
			'deviceident',
			'kind',
			'vendor',
			'model',
			'licensekey',
			'imei',
			'hardware_id',
			'phonenumber',
			'position',
			'config',
			'configstatus',
			'parameters_json',
			'from_timestamp',
			'to_timestamp',
			'deleted',
			{
				name: 'status_json',
				viewPerm: {
					kind: 'tracker_status_json',
					mask: 1,
				},
				// edit perm is checked manually by repo pre-apply hook (not by core authrepo)
			},
		],
	},

	command: {
		parent: ['vehicle'],
		fields: ['command', 'name', 'is_massive', 'connection_type', 'description', 'parameters_json'],
	},

	maintenance_schedule: {
		parent: ['vehicle'],
		fields: ['name', 'period_mileage_km', 'period_days', 'initial_mileage_km', 'initial_timestamp'],
	},

	vehicle_fence: {
		parent: ['vehicle'],
		fields: ['from_timestamp', 'to_timestamp', 'fence_staticid'],
	},

	event_filter: {
		parent: ['group'],
		fields: [
			'enabled',
			'disable_after',
			'title',
			'eventtype',
			'byscreen',
			'bysms',
			'byemail',
			'smsphone',
			'email',
			'smstemplate',
			'emailtemplate',
			'screentemplate',
			'device_list_json',
			'group_list_json',
			'fence_list_json',
			'weekday',
			'time_interval_list',
			'parameters_json',
			'locale',
			'role_staticid',
			'is_reaction_required',
			'is_hidden',
			'min_duration',
		],
	},

	sensor_condition: {
		parent: ['event_filter'],
		fields: ['change_id', 'sensor_id', 'sensor_is_custom', 'type', 'value_json', 'ignore_null'],
	},

	fence: {
		parent: ['group'],
		fields: ['title', 'fence', 'displaycolor'],
	},

	reporttemplate: {
		parent: ['group'],
		fields: ['title', 'settings', 'type'],
	},

	place: {
		parent: ['group'],
		fields: ['title', 'point'],
	},

	route: {
		parent: ['group'],
		fields: ['color', 'title', 'vehicle_type', 'optimization_type', 'corridor_width', 'route_line_control'],
	},

	route_variant: {
		parent: ['route'],
		fields: ['index'],
	},

	waypoint: {
		parent: ['route_variant'],
		fields: ['lon', 'lat', 'durationfromprev', 'distancefromprev', 'seqno', 'type', 'tags_json'],
	},

	schedule: {
		parent: ['route'],
		fields: [
			'name',
			'repetition',
			'repetition_pause',
			'vehicle_list_json',
			'generate_trip_until',
			'time_limit_interval_list',
			'time_limit_weekday_list',
			'time_limit_day_list',
			'time_limit_month_list',
			'notification_settings',
			'creation_type',
			'shuffle',
			'aborted_time',
			'start_time',
			'route_outdated',
			'timezone',
			'auto_creation_enabled',
			'required_points_threshold',
		],
	},

	schedule_variant: {
		parent: ['schedule'],
		fields: ['index'],
	},

	schedule_point: {
		parent: ['schedule_variant'],
		fields: ['waypoint_id', 'active', 'stay_time', 'arrival_time', 'arrival_delta', 'start_delta'],
	},

	relaying: {
		parent: ['group'],
		fields: ['name', 'host', 'port', 'device_list_json', 'enabled', 'protocol', 'parameters_json', 'sensors_enabled'],
	},

	task: {
		parent: ['group'],
		fields: [
			'name',
			'description',
			'type',
			'enabled',
			'timezone',
			'activation_timestamp',
			'timeoffset',
			'timeoffset_type',
			'repeats',
			'time_limit_interval_list',
			'time_limit_weekday_list',
			'time_limit_day_list',
			'time_limit_month_list',
			'parameters_json',
		],
	},

	geolayer: {
		parent: ['group'],
		fields: [
			'name',
			'external_id',
			'visibility',
			'priority',
			'login',
			'password',
			'url',
			'type',
			'public_layer',
		],
	},

	sensors_set: {
		parent: ['group'],
		fields: ['name', 'data_json'],
	},

	store_scheme: {
		parent: ['group'],
		fields: ['name', 'scheme_json', 'extension_for', 'inherit_staticid', 'description'],
	},

	store: {
		parent: ['store_scheme'],
		fields: ['data_json', 'extension_for'],
	},

	catalog_vehicle: {
		parent: ['group'],
		fields: ['name'],
	},

	trip: {
		parent: ['schedule'],
		fields: [],
	},
}

export default dbStructure
