export type EventSubscription = {
	remove: () => void
}

export type EventHandler = (...data: unknown[]) => void

export class EventEmitter {
	private events = new Map<string, EventHandler[]>()

	on(type: string, handler: EventHandler): EventSubscription {
		const handlers = this.events.get(type) || []
		if (!this.events.has(type)) {
			this.events.set(type, handlers)
		}
		handlers.push(handler)
		return {
			remove: () => {
				const index = handlers.indexOf(handler)
				if (index < 0) return
				handlers.splice(index, 1)
			},
		}
	}

	emit(type: string, ...data: unknown[]) {
		const handlers = this.events.get(type)
		if (!handlers?.length) return
		handlers.forEach((handler) => handler(...data))
	}
}
