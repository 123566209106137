import wapi from 'webapi/rpc/web'

export class ChatService {
	getUnreadCount() {
		return wapi.send('chat.unreadMessagesCount')
	}

	getUnreadList(limit) {
		return wapi.send('chat.getUnreadMessages', [limit])
	}

	getHistory(vehicleId, offset, count) {
		return wapi.send('chat.getChatHistory', [vehicleId, count, offset])
	}

	sendMessage(vehicleId, message) {
		return wapi.send('chat.sendMessageTo', [vehicleId, message, ''])
	}

	massiveSendMessage({
		portionFetchReq, fetchReq, message, subject = '',
	}) {
		return wapi.send('chat.massiveSendMessageTo', [
			portionFetchReq,
			fetchReq,
			message,
			subject,
		])
	}

	markMessageList(vehicleId, messageIdList) {
		return wapi.send('chat.onReadMessage', [vehicleId, messageIdList])
	}
}

export default new ChatService()
