import WKT from 'terraformer-wkt-parser'
import config from 'webapi/rpc/config'
import wapi from 'webapi/rpc/web'
import { PromiseIterator } from '../main/PromiseIterator'
import { MonitoringObject } from '../main/MonitoringObject'
import MonitoringObjectFactory from '../main/MonitoringObjectFactory'
import { MaintenanceSchedule } from './MaintenanceSchedule'
import { Tracker } from '../main/Tracker'
import Repository from '../main/Repository'
import { DriverAssignment } from './DriverAssignment'
import { extensionPropertyMixin } from '../main/_extensionPropertyMixin'
import { Icon } from '../main/Icon'
import { EventEmitter } from '../utils/EventEmitter'

function jsMonthToDispatch(value) {
	const v = parseInt(value, 10)
	return (v || v === 0) ? v + 1 : 0
}

function dispatchMonthToJs(value) {
	const v = parseInt(value, 10)
	return v ? v - 1 : 0
}

function compareInt(oldVal, newVal) {
	return parseInt(oldVal, 10) === parseInt(newVal, 10)
}

function compareFloat(oldVal, newVal) {
	return parseFloat(oldVal) === parseFloat(newVal)
}

const emitter = new EventEmitter()

export const SpeedMode = {
	Unset: 'unset',
	Limit: 'limit',
	Excess: 'excess',
}

export const on = emitter.on.bind(emitter)
export const emit = emitter.emit.bind(emitter)

export class Vehicle extends extensionPropertyMixin(MonitoringObject, 'vehicle') {
	static entityName = 'vehicle'

	static SpeedMode = SpeedMode

	static on = on
	static emit = emit

	getName() { return this.get('name') }

	setName(name) { return this.set('name', name) }

	getColor() { return this.get('displaycolor') || '#000000' }

	setColor(color) { return this.set('displaycolor', color) }

	getDisplayIcon() {
		return new Icon({
			id: this.getDisplayIconId(),
			format: this.getDisplayIconFormat(),
			type: 'static',
			version: this.getDisplayIconVersion(),
			name: '',
			readonly: true,
		})
	}

	setDisplayIcon(icon) {
		this.setDisplayIconId(icon?.getId() ?? null)
	}

	getDisplayIconId() { return this.get('displayicon') }

	setDisplayIconId(icon) { return this.set('displayicon', icon) }

	getDisplayIconFormat() { return this.get('displayicon_format') }

	getDisplayIconVersion() { return this.get('displayicon_version') }

	getMapIcon() {
		return new Icon({
			id: this.getMapIconId(),
			format: this.getMapIconFormat(),
			type: this.getMapIconType(),
			version: this.getMapIconVersion(),
			name: '',
			readonly: true,
		})
	}

	setMapIcon(icon) {
		this.setMapIconId(icon?.getId() || null)
	}

	getMapIconId() { return this.get('mapicon') }

	setMapIconId(icon) { return this.set('mapicon', icon) }

	getMapIconType() { return this.get('mapicon_type') }

	getMapIconFormat() { return this.get('mapicon_format') }

	getMapIconVersion() { return this.get('mapicon_version') }

	getDescription() { return this.get('description') }

	setDescription(description) { return this.set('description', description) }

	getModelId() {
		return this.get('model_id')
	}
	setModelId(id) {
		return this.set('model_id', id)
	}

	getModelName() {
		return this.get('model_name')
	}
	getManufacturerName() {
		return this.get('manufacturer_name')
	}
	getCreationTimestamp() {
		return this.get('create_timestamp')
	}
	getFirstConnectionTimestamp() {
		return this.get('first_connect_timestamp')
	}
	getMaxReceivedTimestamp() {
		return this.get('max_received_timestamp')
	}
	getTrackerDetachTimestamp() {
		return this.get('unplug_timestamp')
	}

	isMarkedAsDeleted() {
		return Boolean(this.get('deleted'))
	}

	getConsumptionParameters() {
		return {
			winterbeginday: parseInt(this.get('winterbeginday'), 10) || 0,
			winterbeginmonth: dispatchMonthToJs(this.get('winterbeginmonth')),
			wintercityrate: parseFloat(this.get('wintercityrate')) || 0,
			winteroutrate: parseFloat(this.get('winteroutrate')) || 0,
			summerbeginday: parseInt(this.get('summerbeginday'), 10) || 0,
			summerbeginmonth: dispatchMonthToJs(this.get('summerbeginmonth')),
			summercityrate: parseFloat(this.get('summercityrate')) || 0,
			summeroutrate: parseFloat(this.get('summeroutrate')) || 0,
		}
	}

	setConsumptionParameters(p) {
		this.set('winterbeginday', p.winterbeginday, compareInt)
		this.set('winterbeginmonth', jsMonthToDispatch(p.winterbeginmonth), compareInt)
		this.set('wintercityrate', p.wintercityrate, compareFloat)
		this.set('winteroutrate', p.winteroutrate, compareFloat)
		this.set('summerbeginday', p.summerbeginday, compareInt)
		this.set('summerbeginmonth', jsMonthToDispatch(p.summerbeginmonth), compareInt)
		this.set('summercityrate', p.summercityrate, compareFloat)
		this.set('summeroutrate', p.summeroutrate, compareFloat)
		return this
	}

	getFuelWindowDuration() {
		return this.get('fuelwindowduration') ?? 0
	}

	setFuelWindowDuration(value) {
		return this.set('fuelwindowduration', value ?? 0)
	}

	_trackerObject(value) {
		if (typeof (value) !== 'undefined') {
			this._trackerObjectValue = value
		}
		return this._trackerObjectValue
	}

	_trackerProperties(value) {
		if (typeof (value) !== 'undefined') {
			this._properties.tracker = value
		}
		if (this._properties.tracker instanceof Array) {
			return this._properties.tracker[0] // Note: api1.vehicle.get returns single tracker as array of trackers for compatibility reasons.
		}
		return this._properties.tracker
	}

	_trackerHistoryPromise(value) {
		if (typeof (value) !== 'undefined') {
			this._trackerHistoryPromiseHandler = value
		}
		return this._trackerHistoryPromiseHandler
	}

	getTracker() {
		const trackerProperties = this._trackerProperties()
		if (!trackerProperties) return

		const tracker = this._trackerObject()
		if (tracker || tracker === null) {
			return tracker
		}
		return this._trackerObject(new Tracker(this.getId(), trackerProperties))
	}

	changeTracker(tracker) {
		this._trackerObject(tracker)
		this._trackerProperties(tracker._properties)
		this._trackerHistoryPromise(null) // reset tracker history 'cache'
	}

	getTrackerHistory() {
		return this._trackerHistoryPromise() || this._trackerHistoryPromise(
			Tracker.getByVehicleId(this.getId()).then((trackers) => trackers
				.sort((a, b) => b.from_timestamp - a.from_timestamp)
				.map((props) => new Tracker(props && props.parent, props))
			)
		)
	}

	getTrackerId() {
		if (this._trackerObject()) {
			return this._trackerObject().getId()
		} if (this._trackerProperties()) {
			return this._trackerProperties().id
		}
		return null
	}

	getSensorList() {
		const { sensorList } = this.getParameters()
		const tracker = this.getTracker()
		const list = tracker ? tracker.getSensorList() : sensorList
		return list ?? []
	}

	async getMaintenanceSchedules() {
		return MaintenanceSchedule.getByVehicle(this)
	}

	// returns map:
	//   key of the entry is id(uuid) of MaintenanceSchedule.
	//   value - the following object:
	//     {
	//       timestamp: Number/integer
	//       mileageKm: [optional] Number/double
	//     }
	async getLastMaintenances() {
		return wapi.send('Tracks.getLastVehicleMaintenances', [this.getId()])
	}

	async buildBufferByTrack(beginDate, endDate, _width, _simplifyFactor) {
		const width = _width ?? 50
		const simplifyFactor = _simplifyFactor ?? 20

		const reqArg = {
			begin: beginDate.valueOf() / 1000,
			end: endDate.valueOf() / 1000,
			id: this.getId(),
			width,
			simplifyFactor,
		}

		const buffer = await wapi.send('Tracks.buildTrackBuffer', reqArg)
		if (buffer) {
			try {
				return WKT.parse(buffer)
			} catch (e) {
				if (config.isDebug) console.error(e)

				return null
			}
		}

		if (config.isDebug) console.error('fence.getByRoute return empty buffer')

		return null
	}

	copy(transaction) {
		const result = super.copy(transaction)
		const tracker = this.getTracker()?.copy(transaction)
		if (tracker) {
			tracker.setParentId(result.getId(), transaction)
			result.changeTracker(tracker)
		}
		return result
	}

	getDriverAssignments(fromTime, toTime) {
		return wapi.send('driverAssignment.getByVehicleId', [this.getId(), fromTime.valueOf() / 1000, toTime.valueOf() / 1000]).then(
			(driverAssignmentList) => (driverAssignmentList || []).map((properties) => new DriverAssignment(properties && properties.parent, properties)),
		)
	}

	getControlGeofenceName() {
		return wapi.send('vehicle.getControlGeofenceNameById', [this.getId()])
	}

	getControlFence() {
		return this.get('control_fence') || { name: '' }
	}

	getMileage(isByOdometer) {
		return wapi.send('Tracks.getMileage', [this.getId(), isByOdometer || false])
	}

	getVin() {
		return this.get('vin') || ''
	}

	setVin(value) {
		return this.set('vin', value || null)
	}

	// Assume that all monitoring objects are Vehicle instances
	static testProperties(/* properties */) { return true }
}

function fromJsonRpc(parameters) {
	return new Vehicle(parameters && parameters.parent, parameters)
}

export function get(recursive, group) {
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.vehicle.bind(null, recursive)),
		fromJsonRpc,
	)
}
Vehicle.get = get

export async function getById(id) {
	const props = await Repository.byIdGetter.vehicle(id)
	return fromJsonRpc(props)
}
Vehicle.getById = getById

// NOTE: The order in response is equal idList
export async function getList(idList) {
	const list = await Repository.byIdListGetter.vehicle(idList)
	const vehList = list.map((props) => fromJsonRpc(props))
	const vehMap = {} // order vehicles
	vehList.forEach((veh) => {
		vehMap[veh.getId()] = veh
	})

	return idList.reduce((res, id) => {
		if (vehMap[id]) res.push(vehMap[id])
		return res
	}, [])
}
Vehicle.getList = getList

export async function getAllCustomSensors() {
	return wapi.send('vehicle.allCustomSensors')
}
Vehicle.getAllCustomSensors = getAllCustomSensors

export async function getCustomSensors(idList) {
	return wapi.send('vehicle.customSensors', [idList])
}
Vehicle.getCustomSensors = getCustomSensors

export async function getSummary(id) {
	return wapi.send('vehicle.getSummary', [id])
}
Vehicle.getSummary = getSummary

function _invalidate() {
	wapi.cacheInvalidate('vehicle.getControlGeofenceNameById')
	setTimeout(() => this.emit('changed'), 0) // there may be another invalidators in Repository, wait for it...
}

/**
 * @deprecated use getVehiclesCount instead
 */
export async function getOnlineCount(now, args, extraArgs) {
	return MonitoringObject.getVehiclesCount(now, 'filtered', args, extraArgs)
}
Vehicle.getOnlineCount = getOnlineCount

export async function getLastReceivedTimes(idList) {
	const result = await wapi.send('vehicle.lastReceivedTimes', [idList])
	return result.reduce((res, pair) => {
		const [key] = Object.keys(pair)
		const value = pair[key]
		// eslint-disable-next-line no-param-reassign
		res[key] = value
		return res
	}, {})
}
Vehicle.getLastReceivedTimes = getLastReceivedTimes

export async function getTrackerCertificatesByVehicleIdList(idList) {
	return wapi.send('tracker_certificates.getByVehicleIdList', [idList])
}
Vehicle.getTrackerCertificatesByVehicleIdList = getTrackerCertificatesByVehicleIdList

MonitoringObjectFactory.register(Vehicle)

Repository.registerInvalidator('vehicle', _invalidate.bind(Vehicle))

wapi.cacheRegister('vehicle.getControlGeofenceNameById', {
	getPath: (params) => [params[0]],
	maxSize: 100,
})
