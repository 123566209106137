import wapi from '../rpc/web'

export class DispatchServer {
	on = wapi.on.bind(wapi)

	// TODO protect against multiple invocation

	/**
	 * @private
	 */
	async _makeSessionPromise(silent = false) {
		// TODO load in parallel with auth request (Promise.all)
		const Session = await import('./Session')
		try {
			const User = await import('./User')
			const user = await User.current()
			return Session.create({ server: this, user, silent })
		} catch (e) {
			Session.reject(e)
		}
	}

	async endSession(sessionId) {
		wapi.setAuthToken(sessionId) // NOTE: set session which we wanna kill
		return this.logout()
	}

	/**
	 * @internal loads group tree
	 * @fn login(String login, String password)
	 * @memberof DispatchServer
	 */
	async login(/** String */ login, /** String */ password, /** bool */ longSession) {
		return wapi.sendAnonymously('login', [login, password, Boolean(longSession)])
			.then(this._loginOk.bind(this), this._loginError.bind(this))
			.then(() => this._makeSessionPromise(true))
			.then(() => wapi.sessionId)
	}

	async logout() {
		return wapi.send('logout')
	}

	/**
	 * @private
	 * @param {string} authtoken
	 */
	_loginOk(authtoken) {
		wapi.setAuthToken(authtoken)
	}

	/**
	 * @private
	 * @param {Error} error
	 * @returns {never}
	 */
	_loginError(error) {
		wapi.setAuthToken(null)
		throw error
	}

	/**
	 * @param {string} authtoken
	 */
	async loginToken(authtoken) {
		wapi.setAuthToken(authtoken)
		return this._makeSessionPromise()
	}

	async registerTrial(user, password, welcomeName, companyName, email) {
		// TODO: get rid of dojoConfig here
		return wapi.sendAnonymously(
			'repo.registerNewTrial',
			[user, password, welcomeName, companyName, email, window.dojoConfig.locale, window.location.hostname],
		)
	}

	async canRegisterTrial() {
		return wapi.sendAnonymously(
			'repo.canRegisterNewTrial', [window.location.hostname],
		)
	}

	async getServerReplyEmail() {
		return wapi.sendAnonymously(
			'getServerReplyEmail', [window.location.hostname],
		)
	}

	/**
	 * @private
	 */
	async _getObjectGroups() {
		// FIXME: Is it really needed? user from _makeSessionPromise
		return wapi.send('group.roots')
	}

	/**
	 * @private
	 */
	async _getItems() {
		return wapi.send('Devices.trackers')
	}

	async getApiKey(login, password) {
		return wapi.sendPublic('apikey.get', [login, password])
	}

	async getClientUiSettings() {
		return wapi.sendAnonymously('getPublicClientUiSettings')
	}

	async getServerTime() {
		return wapi.send('getServerTime')
	}

	/**
	 * @typedef {any} BuildVersion
	 * @returns {Promise<BuildVersion>}
	 */
	async getBackendVersion() {
		const version = await wapi.send('getVersion')
		return {
			major: version.major | 0,
			minor: version.minor | 0,
			revision: version.revision | 0,
			build: version.build | 0,
		}
	}
}

export default new DispatchServer()
