define(
	[
		"exports",
		"dojo/has",
		"lodash"
	],
	function(
		exports,
		has,
		_
	) {

		// Private component reference storage
		exports._componentList = [];
		exports._activeComponentList = [];

		// Attach component to a lifecycle, to be called inside compoent's describing module
		exports.attach = function(obj) {

			if(obj.name && !has(obj.name)) {
				has.add(obj.name, true);
			}

			this._componentList.push(obj);
		};

		// Run the lifecycle phases in specified sequence for all the attached components, to be called on the page
		exports.startup = async function() {
			const activityList = await Promise.all(
				this._componentList.map(component => component.onInit && component.onInit())
			)

			this._activeComponentList = this._componentList.filter((component, index) => {
				const isActive = activityList[index] !== false
				if (isActive && component.onStart) component.onStart()
			})
		}
	}
);
