import { ReportTemplate } from './ReportTemplate'

export class ReportTemplateMORegionReportByInn extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set(
			'type',
			ReportTemplate.Type.MonitoringObjectsRegionalReportByInn,
		)
	}

	getTimeType() {
		return ReportTemplate.TimeType.None
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.MonitoringObjectsRegionalReportByInn
	}
}
