define(/*"xhr", */[], function() {
	return function xhr(url, options) {
		return new Promise(function(resolve, reject) {

			var req = new XMLHttpRequest();
			req.open(options.method, url);

			if (options.headers) {
				for (var h in options.headers) {
					if (options.headers.hasOwnProperty(h)) {
						req.setRequestHeader(h, options.headers[h]);
					}
				}
			}

			req.onreadystatechange = function() {
				if (req.readyState > 1 && req.status === 0) {
					reject({ error: Error("Unknown status"), code: req.status });
				}
			};
			req.onload = function() {
				if (req.status == 200) {
					resolve(req.response);
				} else {
					reject({ error: Error("Network Error"), code: req.status });
				}
			};
			req.onerror = function() {
				reject({ error: Error("Network Error"), code: req.status });
			};
			req.onabort = function() {
				reject({ error: Error("Aborted"), code: req.status });
			};

			req.send(options.data);
		});
	}
});
