import wapi from 'webapi/rpc/web'
import { PromiseIterator } from './PromiseIterator'
import { DispatchObject } from './DispatchObject'
import { RoleCommand } from './RoleCommand'
import { AsyncLoaders } from './AsyncLoaders'
import Repository from './Repository'
import { MonitoringObject } from './MonitoringObject'

export class Command extends DispatchObject {
	static entityName = 'command'

	/**
	 * @private
	 */
	_parentConstructor = MonitoringObject

	getCommandId() {
		return this.get('command')
	}

	setCommandId(commandId) {
		return this.set('command', commandId)
	}

	getName() {
		return this.get('name')
	}

	setName(name) {
		return this.set('name', name)
	}

	isMassive() {
		return this.get('is_massive')
	}

	setMassive(isMassive) {
		return this.set('is_massive', isMassive)
	}

	isImplicit() {
		return !!this._properties._is_implicit
	}

	setImplicit(isImplicit) {
		this._properties._is_implicit = isImplicit
		return this
	}

	getConnectionType() {
		return this.get('connection_type')
	}

	setConnectionType(connectionType) {
		return this.set('connection_type', connectionType)
	}

	getDescription() {
		return this.get('description')
	}

	setDescription(description) {
		return this.set('description', description)
	}

	getParameters() {
		try {
			return JSON.parse(this.get('parameters_json') || '{}')
		} catch (e) {
			console.error("Couldn't parse Command parameters", this, e)
			return {}
		}
	}

	setParameters(parameters) {
		return this.set('parameters_json', JSON.stringify(parameters))
	}

	getAssignedRoleCommands() {
		return wapi.send('role_command.getByCommandId', [this.getId()]).then(
			(parametersList) => parametersList.map((parameters) => new RoleCommand(parameters.parent, parameters)),
		)
	}
}

export const implicitExecutableList = ['assistance_request', 'send_route']
Command.implicitExecutableList = implicitExecutableList

function fromJsonRpc(properties) {
	return new Command(properties && properties.parent, properties)
}

export function get(recursive, group) {
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.command.bind(null, recursive)),
		fromJsonRpc,
	)
}
Command.get = get

export async function getMassiveCommands(group) {
	const groupIds = await group.getIds()
	const cmdMap = await wapi.send('command.getMassiveNameMap', {
		recursive: true,
		groups: groupIds,
	})
	this.implicitExecutableList.forEach((cmd) => {
		if (cmdMap[cmd]) delete cmdMap[cmd]
	})
	return cmdMap
}
Command.getMassiveCommands = getMassiveCommands

export function getMassiveVehicles(massiveCommandName, group) {
	return new AsyncLoaders({
		objectFactory(arg) { return arg },
		getExtraArguments: async () => {
			const fetchParams = await AsyncLoaders.extraArguments.groupRecursive(group)
			return fetchParams.concat([massiveCommandName])
		},
		requestGet: 'command.getMassiveVehicles',
	})
}
Command.getMassiveVehicles = getMassiveVehicles

export async function getCommonNamesByVehicles(vehicleIdList) {
	return wapi.send('command.getCommonNamesByVehicles', [vehicleIdList])
}
Command.getCommonNamesByVehicles = getCommonNamesByVehicles

// Command.massiveExecute
//
// args: Object {
//   name: string,
//   commands: array of command execution objects (see for details MonitoringObject.executeCommands)
// }
export async function massiveExecute(args) {
	return wapi.send('command.massiveExecute', [args])
}
Command.massiveExecute = massiveExecute

export async function massiveExecuteByName(group, massiveCommandName) {
	const groups = await group.getIds()
	return wapi.send('command.massiveExecuteByName', [{ recursive: true, groups }, massiveCommandName])
}
Command.massiveExecuteByName = massiveExecuteByName

// Command.massiveLaunchs
//
// returns array of following objects:
// {
//   id: string - massive launch id
//   name: string
//   executed: number - seconds timestamp
// }
export async function massiveLaunchs() {
	return wapi.send('command.massiveLaunchs')
}
Command.massiveLaunchs = massiveLaunchs

// Command.statusByVehicle
//
// returns array of following objects:
// {
//   id: string - launch id
//   name: string
//   executed: number - seconds timestamp
//   status_code: enum LaunchStatus - see below
//   status_description: string [optional]
//   received: number - seconds timestamp [optional]
// }
export async function statusByVehicle(object) {
	return wapi.send('command.statusByVehicleId', [object.getId()])
}
Command.statusByVehicle = statusByVehicle

// Command.statusByMassiveLaunch
//
// see .statusByTracker for results description
export async function statusByMassiveLaunch(massiveLaunchId) {
	return wapi.send('command.statusByMassiveLaunch', [massiveLaunchId])
}
Command.statusByMassiveLaunch = statusByMassiveLaunch

export const LaunchStatus = {
	InProgress: 0,
	Done: 1,
	Error: 2,
	TimeOut: 3,
}
Command.LaunchStatus = LaunchStatus

export const FilterColumns = {
	Name: 'name',
	IsSupported: 'is_supported',
}
Command.FilterColumns = FilterColumns

Repository.registerListGetter('command', (recursive, groups) => wapi.send('command.get', { recursive, groups }))
