import { ReportTemplate } from './ReportTemplate'

export class ReportTemplateEventsByPeriod extends ReportTemplate {
	setFilter(filter) {
		return this._setSetting('filter', filter)
	}
	getFilter() {
		return this._getSetting('filter') || []
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.EventsByPeriodGeneral
			|| properties.type === ReportTemplate.Type.EventsByPeriodDetailed
			|| properties.type === ReportTemplate.Type.EventsByPeriodNotSoDetailed
	}
}
