import 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw-drag'

import 'lib/Leaflet.MagnifyingGlass/leaflet.magnifyingglass'
import 'lib/Leaflet.MagnifyingGlass/leaflet.magnifyingglass.css'
import 'lib/leaflet/ds.route.layer'

import 'dojo/resources/dojo.css'
import 'dijit/themes/claro/document.css'
import 'dijit/themes/claro/claro.css'
import 'dojox/widget/ColorPicker/ColorPicker.css'
import 'libfix/dojox/form/resources/TriStateCheckBox.less'

import 'lib/gridx/resources/claro/Gridx.css'
import 'lib/cbtree/themes/claro/claro.css'

import 'lib/djtree/css/tree.less'
import 'lib/djtree/css/tree-md.less'
import 'lib/djtree/css/checkbox.less'

import '@/theme.css'

import { config } from './config'

const importLess = require.context('@/', true, /(core|monitoring|report|tracker).*\.less/)
importLess.keys().forEach(path => importLess(path))

if (config.isDebug) {
	console.time('Loading')
}

import './bootstrap'
