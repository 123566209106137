import { ReportTemplate } from './ReportTemplate'

export const AggregationType = {
	Day: 'gb_day',
	Month: 'gb_month',
}

const AggregationTypeInverse = {}
Object.entries(ReportTemplate.AggregationType)
	.forEach(([key, value]) => {
		AggregationTypeInverse[value] = key
	})

export class ReportTemplateDrivingQualitySummary extends ReportTemplate {
	static AggregationType = AggregationType

	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.DrivingQualitySummary)
	}

	setAggregationType(type) {
		if (!(type in AggregationTypeInverse)) {
			throw new TypeError(`ReportTemplateDrivingQualitySummary.setAggregationType: invalid type: ${type}`)
		}
		this._setSetting('groupBy', type)
		return this
	}

	getAggregationType() {
		return this._getSetting('groupBy')
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.DrivingQualitySummary
	}
}
