export const timeBlockFromTimeStamp = (t) => Math.floor(t / (1024 * 128))

export const toTimestamp = (dateOrTimestamp) => {
	if (typeof dateOrTimestamp === 'number') {
		return dateOrTimestamp
	} if (dateOrTimestamp instanceof Date) {
		return Math.round(dateOrTimestamp.valueOf() / 1000)
	}
	throw new Error('Utils.toTimestamp: invalid argument type')
}

export const timeBlockFromDate = (dateOrTimestamp) => timeBlockFromTimeStamp(toTimestamp(dateOrTimestamp))

export const timeBlockListFromDateInterval = (begin, end) => {
	if (begin > end) {
		throw new Error('Utils.timeBlockListFromDateInterval: begin > end')
	}

	const blockFrom = timeBlockFromDate(begin)
	const blockTo = timeBlockFromDate(end)

	const blockList = []

	for (let blockId = blockFrom; blockId <= blockTo; ++blockId) {
		blockList.push(blockId)
	}

	return blockList
}

/**
 * @return [lod, samplingIntervalSeconds ] Array
 * @internal Keep in sync with chartaggregatingfilter.cpp.
 * This is the only way to avoid server requests for cached lods
 */
export const normalizeChartLod = (lodBase) => {
	const lodMin = 5; const lodMax = 15; const
		lodStep = 2
	if (lodBase < lodMin) {
		return [0, 0]
	}
	let lod = Math.floor((lodBase - lodMin + lodStep - 1) / lodStep) * lodStep + lodMin
	lod = Math.min(lod, lodMax)
	// eslint-disable-next-line no-bitwise
	return [lod, 1 << lod]
}

const toString = Object.prototype.toString.apply.bind(Object.prototype.toString)

export const isArray = function isArray(arg) {
	return toString(arg) === '[object Array]'
}

export const isNumber = function isNumber(arg) {
	return toString(arg) === '[object Number]'
}

export const isObject = function isObject(arg) {
	return toString(arg) === '[object Object]'
}

export const isString = function isString(arg) {
	return toString(arg) === '[object String]'
}
