define(['core/jed!core/nls/common'], function(i18n) {
return {
	get appTitle() {
		return {
			glonass: {
				short: i18n.gettext("AMS ERA"),
				full: i18n.gettext("Automated Monitoring System ERA"),
			}
		}[VERSION_CONSUMER.toLowerCase()] || {
			short: i18n.gettext("Dispatch System"),
			full: i18n.gettext("Dispatch System"),
		}
	},
	userNameNodeText: i18n.gettext("User: ${0}"),
	exitLabel: i18n.gettext("Exit"),
	toolsMenuLabel: i18n.gettext("Tools"),
	viewLabel: i18n.gettext("View"),
	editLabel: i18n.gettext("Edit"),
	addLabel: i18n.gettext("Add"),
	delLabel: i18n.gettext("Delete"),
	copyLabel: i18n.gettext("Copy"),
	copiedMark: i18n.pgettext("copiedMark", "copy"),
	showLabel: i18n.gettext("Show"),
	yesLabel: i18n.gettext("Yes"),
	noLabel: i18n.gettext("No"),
	okLabel: i18n.gettext("OK"),
	closeLabel: i18n.gettext("Close"),
	saveLabel: i18n.gettext("Save"),
	cancelLabel: i18n.gettext("Cancel"),
	applyLabel: i18n.gettext("Apply"),
	updateLabel: i18n.gettext("Update"),
	changeLabel: i18n.gettext("Change"),
	errorLabel: i18n.gettext("Error"),
	nextLabel: i18n.gettext("Next"),
	backLabel: i18n.gettext("Back"),
	moveUp: i18n.gettext("Up"),
	moveDown: i18n.gettext("Down"),
	nameLabel: i18n.gettext("Name"),
	fullName: i18n.gettext("Full name"),
	groupLabel: i18n.pgettext("label", "Group"),
	geofenceLabel: i18n.gettext("Geofence"),
	geofencesLabel: i18n.gettext("Geofences"),
	colorLabel: i18n.gettext("Color"),
	propertyLabel: i18n.gettext("Properties"),
	beginLabel: i18n.gettext("Begin"),
	endLabel: i18n.pgettext("label", "End"),
	displayLabel: i18n.gettext("Display"),
	statusLabel: i18n.pgettext("label", "Status"),
	paramsLabel: i18n.gettext("Parameters"),
	objectLabel: i18n.pgettext("label", "Object"),
	runLabel: i18n.gettext("Run"),
	tableLabel: i18n.gettext("Table"),
	chartLabel: i18n.gettext("Pie chart"),
	valueLabel: i18n.gettext("Value"),
	visibleLabel: i18n.gettext("Visibility"),
	prioLabel: i18n.gettext("Priority"),
	changeColorLabel: i18n.gettext("Change color"),
	editTitleLabel: i18n.gettext("Edit Title"),
	selectGroupLabel: i18n.gettext("Select the group"),
	enterNameLabel: i18n.gettext("Enter the name"),
	filterNameLabel: i18n.gettext("Filter by name"),
	filterLabel: i18n.gettext("Filter"),
	filtersLabel: i18n.gettext("Filters"),
	filterSettingsLabel: i18n.gettext("Filter settings"),
	fieldsLabel: i18n.gettext("Fields"),
	fieldTitleLabel: i18n.gettext("Field title"),
	selectLabel: i18n.gettext("Select"),
	selectFieldLabel: i18n.gettext("Select field"),
	recalculateLabel: i18n.gettext("Recalculate history"),
	parentGroup: i18n.gettext("Access group"),
	loadingLabel: i18n.gettext("Loading..."),
	editingLabel: i18n.gettext("Editing"),
	deletingLabel: i18n.gettext("Deleting..."),
	notImplemented: i18n.gettext('Not implemented'),
	notAvailable: i18n.gettext("Not available"),
	notAvailableShort: i18n.gettext("N/A"),
	longitude: i18n.gettext("Longitude"),
	fromLabel: i18n.gettext("From"),
	toLabel: i18n.gettext("To"),
	latitude: i18n.gettext("Latitude"),
	kilometersPerHour: i18n.gettext("km/h"),
	kmUnitLabel: i18n.gettext("km"),
	mUnitLabel: i18n.gettext("m"),
	onState: i18n.gettext("On"),
	offState: i18n.gettext("Off"),
	yesState: i18n.gettext("Yes"),
	noState: i18n.gettext("No"),
	serverError: i18n.gettext("Server error"),
	timeNotAvailable: i18n.gettext("time n/a"),
	nameNotAvailable: i18n.gettext("name n/a"),
	speedNotAvailable: i18n.gettext("speed n/a"),
	directionNotAvailable: i18n.gettext("direction n/a"),
	coordinatesNotAvailable: i18n.gettext("coordinates n/a"),
	mapMenu: i18n.gettext("Map"),
	mixedState: i18n.gettext("—"),
	totalCount: i18n.gettext("total: ${0}"),
	totalLabel: i18n.gettext("Total"),
	clearFilter: i18n.gettext("Clear filter"),
	unknownRegion: i18n.gettext("Region is not defined"),
	calendar: {
		minutes: function(idx) { return i18n.ngettext("minute", "minutes", idx); },
		hours: function(idx) { return i18n.ngettext("hour", "hours", idx); },
		days: function(idx) { return i18n.ngettext("day", "days", idx); },
		weeks: function(idx) { return i18n.ngettext("week", "weeks", idx); },
		months: function(idx) { return i18n.ngettext("month", "months", idx); }
	},
	time: {
		minutes: idx => i18n.npgettext("basic", "minute", "minutes", idx),
	},
	permView: i18n.gettext("view"),
	permEdit: i18n.gettext("edit"),
	permViewDeleted: i18n.gettext('view deleted'),
	helpLabel: i18n.gettext("Help"),
	dateLabel: i18n.gettext("Date"),
	locationLabel: i18n.gettext("Location"),
	// TRANSLATORS: before date-time in summary line above report result tables
	obtained: i18n.gettext("Obtained"),
	send: i18n.gettext("Send"),
	fetch: i18n.pgettext("fetch", "Export"),
	export: i18n.gettext("Export"),
	import: i18n.gettext("Import"),
	exportError: i18n.gettext("Export failed"),
	formattedCSV: i18n.gettext("Formatted CSV"),
	CSV1C: i18n.gettext("CSV for 1C"),
	print: i18n.gettext("Print"),
	menuItemsSettingsTitle: i18n.gettext("Menu settings"),
	saveOk: i18n.gettext("Changes applied successful"),
	saveError: i18n.gettext("Couldn't apply changes"),
	areaLabel: i18n.gettext("Area"),
	perimeterLabel: i18n.gettext("Perimeter"),
	distanceLabel: i18n.gettext("Distance"),
	statistics: i18n.gettext("Statistics"),
	selectObjects: i18n.gettext("Select objects"),
	unionConjunction: i18n.gettext("OR"),
	maximumStringLength: function(idx) {
		return i18n.ngettext(
			"The maximum string length is ${0} character",
			"The maximum string length is ${0} characters",
			idx
		);
	},
	extraFields: {
		title: {
			store: i18n.gettext("Ex. fields"),
			classifier: i18n.gettext("Classifier")
		},
		object: {
			store: i18n.gettext("Ex. fields (object)"),
			classifier: i18n.gettext("Classifier (object)")
		},
		company: {
			store: i18n.gettext("Ex. fields (company)"),
			classifier: i18n.gettext("Classifier (company)")
		},
	},
	extraObjectFields: {
		title: {
			store: i18n.gettext("Ex. MO fields"),
			classifier: i18n.gettext("MO classifier")
		},
	},
	extraCompanyFields: {
		title: {
			store: i18n.gettext("Ex. company fields"),
			classifier: i18n.gettext("Company classifier")
		},
	},
	extraTripFields: {
		title: {
			store: i18n.gettext("Ex. trip fields"),
			classifier: i18n.gettext("Trip classifier")
		},
	},
	loginError: {
		loginIsShort: function(idx) {
			return i18n.ngettext(
				"Login is too short, at least ${0} letter required",
				"Login is too short, at least ${0} letters required",
				idx
			);
		},
		passwordIsShort: function(idx) {
			return i18n.ngettext(
				"Password is too short, at least ${0} letter required",
				"Password is too short, at least ${0} letters required",
				idx
			);
		}
	},
	"Group cannot be deleted, because it contains objects of following types: %s" :
		i18n.gettext("Group cannot be deleted, because it contains objects of following types: ${0}"),

	objectKinds: {
		// Non-autodeletable object types
		"group" : i18n.gettext("group"),
		"user" : i18n.gettext("user"),
		"driver" : i18n.gettext("driver"),
		"vehicle" : i18n.gettext("vehicle"),
		"event_filter" : i18n.gettext("notification"),
		"fence" : i18n.gettext("fence"),
		"place" : i18n.gettext("place"),
		"route" : i18n.gettext("route"),
		"relaying" : i18n.gettext("relaying"),
		"task" : i18n.gettext("task"),
		"store_scheme" : i18n.gettext("store scheme"),
		"store" : i18n.gettext("store data"),
		"geolayer" : i18n.gettext("map layer")
	},
	invalidObjectSelection: i18n.gettext("Object is not selected"),
	savingLabel: i18n.gettext("Saving..."),
	confirmDeleteLabel: i18n.gettext("Are you sure to delete selected item?"),
	confirmDelete: i18n.gettext('Confirm delete'),

	// time interval widget
	ti_today: i18n.gettext("today"),
	ti_yesterday: i18n.gettext("yesterday"),
	ti_lastMonth: i18n.gettext("last month"),
	ti_lastWeek: i18n.gettext("last week"),
	ti_currentWeek: i18n.gettext("current week"),
	ti_currentMonth: i18n.gettext("current month"),
	ti_lastQuarter: i18n.gettext("last quarter"),
	ti_currentQuarter: i18n.gettext("current quarter"),
	ti_fixed: i18n.gettext("fixed date"),

	notSelectedLabel: i18n.gettext("Not selected"),
	selectedCountLabel: i18n.gettext("Selected - {0}"),
	selectedOnlyLabel: i18n.gettext("Show selected only"),

	commonErrors: {
		"License limit for \"%s\" in group \"%s\" exceeded": i18n.gettext("License limit for \"${0}\" in group \"${1}\" exceeded")
	},
	loadModuleLabel: i18n.gettext("Loading module..."),
	loadModuleError: i18n.gettext("Unable to load module"),
	builtinFields: {
		number_of_vehicles_under_contract: i18n.gettext("Number of vehicles under contract"),
		contract: i18n.gettext("Contract"),
		status: i18n.pgettext("label", "Status"),
		message: i18n.gettext("Message")
	},
	resetLabel: i18n.gettext("Reset"),
	noData: i18n.gettext('No data'),
	eventType: i18n.gettext('Event type'),
	confirmItemsDelete: (len, values) => i18n.sprintf(
		i18n.ngettext(
			"Are you sure you want to delete %d selected item: %s?",
			"Are you sure you want to delete %d selected items: %s?",
			len
		),
		[len, values]
	),
	deleteUsersSuccess: (len, values) => i18n.sprintf(
		i18n.ngettext(
			"User %s has been successfully deleted!",
			"Users %s has been successfully deleted!",
			len
		),
		[values]
	),
	timePeriod: {
		from: i18n.pgettext('timePeriod', 'from'),
		to: i18n.pgettext('timePeriod', 'to'),
		presentTimeShort: i18n.pgettext('timePeriod', 'p.t.'),
	},
	techSupport: i18n.gettext('Technical support'),
	idLabel: i18n.gettext('ID'),
	imeiLabel: i18n.gettext('ID1'),
	hardwareIdLabel: i18n.gettext('ID2'),
}
});
