/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-bitwise */
/* eslint-disable class-methods-use-this */

import LRUCache from 'lib/LRUCache'
import { TrackerExtendedPermssions } from '../main/Role'
import * as Utils from '../main/Utils'

interface Point {
	x: number
	y: number
}

interface Block {
	x: number
	y: number
	z: number
	t: number
}

interface TrackTileProps {
	trackCache: LRUCache<any>
}

type TrackCacheKey =
	| [string]
	| [string, number]
	| [string, number, number, number, number]

// FIXME
export interface TrackTileMixin {
	tilesMassive(id: string, reqBlocks: unknown): Promise<any>
	getId(): string
}

async function hasExtTrackerPerms(mask: ValueOf<typeof TrackerExtendedPermssions>): Promise<boolean> {
	// NOTE: Detect if user's role has extendend track pemissions
	// TODO: need to check mask against object's group, like SecurityContext does.
	// However as long as user has single role it is ok to check just role's permission.
	const { Session } = await import('webapi/main/Session')
	const session = await Session.ready()
	const user = session.getUser()
	const role = user.getProp('role')
	return (role.tracker & mask) === mask
}

export const canViewTrack = async () => hasExtTrackerPerms(TrackerExtendedPermssions.ViewTrack)
export const canViewLocation = async () => hasExtTrackerPerms(TrackerExtendedPermssions.ViewLocation)

export function trackCacheKey(id: string, b?: number | Block): TrackCacheKey {
	if (b == null) return [id]
	if (typeof b === 'number') return [id, b]
	return [id, b.t, b.z, b.x, b.y]
}

export function getTrackTileList(obj: TrackTileMixin, { trackCache }: TrackTileProps) {
	return async (from: Date | number, to: Date | number, zoom: number, plist: Point[]): Promise<any[]> => {
		const hasMask = await canViewTrack()
		if (!hasMask) return []

		const blocks = plist.reduce((l, p) => {
			const blockList = Utils.timeBlockListFromDateInterval(from, to)
				.map((t) => ({
					x: p.x,
					y: p.y,
					z: zoom,
					t,
				}))
			return l.concat(blockList)
		}, [] as Block[])

		const id = obj.getId()
		const cached: { [k: string]: any } = {}
		const reqBlocks = blocks.filter((b) => {
			const data = trackCache.get(trackCacheKey(id, b))
			if (data != null) {
				const key = trackCacheKey(id, b)
				cached[key.toString()] = data
				return false
			}
			return true
		})

		const data = await (reqBlocks.length ? obj.tilesMassive(id, reqBlocks) : Promise.resolve(null))

		if (data instanceof Array) {
			data.forEach((d, i) => {
				const key = trackCacheKey(id, reqBlocks[i])
				trackCache.put(key, d)
				cached[key.toString()] = d
			})
		}
		return blocks
			.map((b) => cached[trackCacheKey(id, b).toString()])
			.reduce((l, d, i) => {
				const last = l[l.length - 1]

				if (!last.length) {
					last.push(d)
					return l
				}

				const b = blocks[i]
				const bp = blocks[i - 1]

				if (bp.x === b.x && bp.y === b.y && bp.z === b.z) {
					last.push(d)
				} else {
					l.push([d])
				}
				return l
			}, [[]])
	}
}
