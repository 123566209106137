import wapi from '../rpc/web'
import * as Session from './Session'

export class Transaction {
	/**
	 * @private
	 */
	_patch = {}

	constructor(patch) {
		if (patch) this._patch = patch
	}

	add(dispatchObject, objectPatch) {
		if (!objectPatch) return

		const patch = {
			[dispatchObject.entityName]: {
				[dispatchObject.getId()]: objectPatch,
			},
		}
		const tr = new Transaction(patch)
		tr.save(this)
	}

	hasChanges() {
		return this._patch && JSON.stringify(this._patch) !== '{}'
	}

	/**
	 * Applies patch to another transaction
	 * @param {Transaction} transactionLike
	 */
	applyTo(transactionLike) {
		const transaction = transactionLike
		if (!this._patch) return
		Object.entries(this._patch).forEach(([kind, value]) => {
			if (!(kind in transaction._patch)) {
				transaction._patch[kind] = value
			} else {
				Object.entries(value).forEach(([id, curPatch]) => {
					const transactionPatch = transaction._patch[kind][id]

					if (!transactionPatch) {
						transaction._patch[kind][id] = curPatch
					} else if (curPatch.op === 'delete' && transactionPatch.op === 'create') {
						delete transaction._patch[kind][id]
					} else {
						if (curPatch.fields) {
							transactionPatch.fields = transactionPatch.fields || {}
							transactionPatch.fields = { ...transactionPatch.fields, ...curPatch.fields }
						}

						if (curPatch.parent) {
							transactionPatch.parent = transactionPatch.parent || {}
							transactionPatch.parent = { ...transactionPatch.parent, ...curPatch.parent }
						}

						if (transactionPatch.op === 'edit' && curPatch.op === 'delete') {
							transactionPatch.op = 'delete'
						}
					}
				})
			}
		})
	}

	/**
	 * Save patch to backend or to another transaction without saving to backend
	 * @param {Transaction} transaction
	 * @returns {Promise<*>}
	 */
	async save(transaction) {
		if (transaction) return this.applyTo(transaction)
		if (!this.hasChanges()) return undefined

		const result = await wapi.send('repo.apply', this._patch)
		if (!result) return undefined
		const { commitId, changeId } = result

		if (Session.current) await Session.current.changeToCommit(commitId, changeId)
		return commitId
	}

	clone() {
		const patch = JSON.parse(JSON.stringify(this._patch))
		return new Transaction(patch)
	}

	static delete(kind, id) {
		if (!kind || typeof kind !== 'string') throw new Error('"kind" argument is required!')
		if (!id || typeof id !== 'string') throw new Error('"id" argument is required!')

		const retval = new Transaction({
			[kind]: {
				[id]: { op: 'delete' },
			},
		})
		return retval
	}
}
