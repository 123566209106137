import wapi from 'webapi/rpc/web'
import { PromiseIterator } from './PromiseIterator'
import { DispatchObject } from './DispatchObject'
import { dateTimePropertyMixin } from './_dateTimePropertyMixin'
import Repository from './Repository'

export class Task extends dateTimePropertyMixin(DispatchObject, 'activation_timestamp', 'ActivationDateTime') {
	static entityName = 'task'

	getName() {
		return this.get('name')
	}

	setName(value) {
		return this.set('name', value)
	}

	getDescription() {
		return this.get('description')
	}

	setDescription(value) {
		return this.set('description', value)
	}

	getType() {
		return this.get('type')
	}

	setType(value) {
		return this.set('type', value)
	}

	getEnabled() {
		return Boolean(this.get('enabled'))
	}

	setEnabled(value) {
		return this.set('enabled', String(value), (a, b) => String(a) === String(b))
	}

	getTimezone() {
		return this.get('timezone')
	}

	setTimezone(value) {
		return this.set('timezone', value)
	}

	// seconds after midnight in task timezone
	getTimeOffset() {
		return this.get('timeoffset')
	}

	setTimeOffset(value) {
		return this.set('timeoffset', value)
	}

	getTimeOffsetType() {
		return this.get('timeoffset_type')
	}

	setTimeOffsetType(value) {
		return this.set('timeoffset_type', value)
	}

	getRepeats() {
		const value = this.get('repeats')
		return value < 0 ? null : value
	}

	setRepeats(value) {
		return this.set('repeats', value == null ? -1 : value)
	}

	getTimeLimitIntervalList() {
		try {
			return JSON.parse(this.get('time_limit_interval_list') || '[]')
		} catch (e) {
			return []
		}
	}

	setTimeLimitIntervalList(list) {
		return this.set('time_limit_interval_list', JSON.stringify(list))
	}

	getTimeLimitWeekDayList() {
		try {
			return JSON.parse(this.get('time_limit_weekday_list') || '[]')
		} catch (e) {
			return []
		}
	}

	setTimeLimitWeekDayList(list) {
		return this.set('time_limit_weekday_list', JSON.stringify(list))
	}

	getTimeLimitDayList() {
		try {
			return JSON.parse(this.get('time_limit_day_list') || '[]')
		} catch (e) {
			return []
		}
	}
	setTimeLimitDayList(list) {
		return this.set('time_limit_day_list', JSON.stringify(list))
	}

	getTimeLimitMonthList() {
		try {
			return JSON.parse(this.get('time_limit_month_list') || '[]')
		} catch (e) {
			return []
		}
	}
	setTimeLimitMonthList(list) {
		return this.set('time_limit_month_list', JSON.stringify(list))
	}

	getParametersJson() {
		try {
			return JSON.parse(this.get('parameters_json') || '{}')
		} catch (e) {
			return {}
		}
	}

	setParametersJson(value) {
		return this.set('parameters_json', JSON.stringify(value))
	}

	getExecCount() {
		return Number(this.get('exec_count')) || 0
	}
}

export const TimeOffsetType = {
	daily: 'daily',
	interval: 'interval',
}
Task.TimeOffsetType = TimeOffsetType

export function fromJsonRpc(parameters) {
	return new Task(parameters && parameters.parent, parameters)
}

export function get(recursive, group) {
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.task.bind(null, recursive)),
		fromJsonRpc,
	)
}
Task.get = get

export async function getById(id) {
	const properties = await Repository.byIdGetter.task(id)
	return fromJsonRpc(properties)
}
Task.getById = getById

Repository.registerListGetter('task', (recursive, groups) => wapi.send('task.get', { recursive, groups }))
Repository.registerByIdGetter('task', (id) => wapi.send('task.getById', id))
