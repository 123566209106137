import Vue, { PluginFunction } from 'vue'

import CompositionAPI, { computed, reactive, ref } from '@vue/composition-api'
import Jed, { InterpolationValue } from 'jed'

// FIXME: временный фикс. ругается что композиция не зарегана во вью
Vue.use(CompositionAPI)

export interface Messages {
	[locale: string]: object;
}

export interface Vue2JedOptions {
	messages: Messages;
	locale: string;
	languageVariant?: string;
}

function create(options: Vue2JedOptions) {
	const locale = ref(options.locale)
	const languageVariant = ref(options.languageVariant)
	const jed = computed(() => new Jed(options.messages[
		`${locale.value}${(languageVariant.value?.length ?? 0) > 0 ? `@${languageVariant.value}` : ''}`
	] || {}))

	function interpolate(text: string, values?: InterpolationValue) {
		if (values != null) {
			try {
				return jed.value.sprintf(text, values)
			} catch (e) {
				console.error(`Error while string interpolation "${text}"`, e)
			}
		}
		return text
	}

	return reactive({
		locale,
		languageVariant,
		gettext: (key: string, value?: InterpolationValue) => (
			interpolate(jed.value.gettext(key), value)
		),
		ngettext: (skey: string, pkey: string, value: string | number, values?: InterpolationValue) => (
			interpolate(jed.value.ngettext(skey, pkey, value), values || value)
		),
		pgettext: (ctx: string, key: string, values?: InterpolationValue) => (
			interpolate(jed.value.pgettext(ctx, key), values)
		),
		npgettext: (ctx: string, skey: string, pkey: string, value: string | number, values?: InterpolationValue) => (
			interpolate(jed.value.npgettext(ctx, skey, pkey, value), values || value)
		),
	})
}

let installed = false

const install: PluginFunction<{ instance: ReturnType<typeof create> }> = (_Vue, options) => {
	if (installed) return
	installed = true

	if (options?.instance) {
		const i18n = options.instance
		/**
		 * @deprecated
		 * For SFC templates only
		 */
		Object.defineProperties(Vue.prototype, {
			$i18n: { writable: false, value: i18n },
			$t: { writable: false, value: i18n.gettext.bind(i18n) },
			$tp: { writable: false, value: i18n.ngettext.bind(i18n) },
			$tc: { writable: false, value: i18n.pgettext.bind(i18n) },
			$tcp: { writable: false, value: i18n.npgettext.bind(i18n) },
		})
	}
}

export default {
	install,
	create,
}
