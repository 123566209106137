import { ReportTemplate } from './ReportTemplate'

export class ReportTemplatePairEventsDetailed extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.PairEventsDetailed)
	}

	getEventTypes() {
		return this._getSetting('eventTypes')
	}

	setEventTypes(types) {
		return this._setSetting('eventTypes', types)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.PairEventsDetailed
	}
}
