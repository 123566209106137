import config from 'webapi/rpc/config'
import wapi from 'webapi/rpc/web'
import * as Session from 'webapi/main/Session'
import DefaultDisplayIcon from '@/core/images/car.png'
import DefaultMapIcon from '@/external/assets/svg/delta.svg'
import { AsyncLoaders } from './AsyncLoaders'

type RemoveIconsRequest = [string[]]
type RemoveIconsResponse = Promise<void>
type AddIconResponse = Promise<void>
type GetIconPortionRequest = { offset: number; limit: number }
type GetIconPortionResponse = Promise<Required<IconParams>[]>

export type IconType = 'static' | 'float'
export type IconId = string

export const IconFormats = ['png', 'jpg', 'jpeg', 'bmp', 'svg'] as const
export type IconFormat = typeof IconFormats[number]

export const DefaultMapIconSize = 30
export const IconBorderWidth = 2

export type IconParams = {
	id: IconId | null
	format?: IconFormat
	type: IconType
	name: string
	readonly: boolean
	version: number
}

export class Icon {
	private paramsJson: string

	private params: IconParams

	private _changed: boolean

	constructor(params?: IconParams) {
		this._changed = false
		this.paramsJson = JSON.stringify(params)
		this.params = {
			id: null,
			type: 'static',
			name: '',
			readonly: false,
			version: 0,
			...params,
		}
	}

	getId() {
		return this.params.id
	}

	getType() {
		return this.params.type
	}

	setType(value: IconType) {
		this.params.type = value
	}

	getName() {
		return this.params.name
	}

	setName(value: string) {
		this.params.name = value
	}

	getFormat() {
		return this.params.format
	}

	getReadonly() {
		return this.params.readonly
	}

	getVersion() {
		return this.params.version
	}

	isNew() {
		return !this.getFormat() || !this.paramsJson
	}

	hasChanges(changed?: boolean) {
		if (typeof changed === 'boolean') this._changed = changed
		if (this.isNew() || this._changed) return true
		return JSON.stringify(this.params) !== this.paramsJson
	}

	clone() {
		return Icon.fromJsonRpc(this.params)
	}

	async save(file: File): AddIconResponse {
		const body = new FormData()
		if (!this.isNew()) {
			const id = this.getId()
			if (id) body.append('id', id)
		}
		if (file) body.append('upload', file)
		body.append('type', this.getType())
		body.append('name', this.getName())
		const response = await fetch(`${config.uploadUrl}icon`, { method: 'POST', body })
		return response.json()
	}

	getLink() {
		if (Icon.isDefaultIcon(this)) {
			return this.getType() === 'static' ? DefaultDisplayIcon : DefaultMapIcon
		}
		return `${config.iconsUrl}${this.getId()}.${this.getFormat()}?version=${this.getVersion()}`
	}

	getSize() {
		return Icon.getSizeByType(this.getType())
	}

	static getDefaultDisplayIcon() {
		return new Icon({
			id: null,
			name: '',
			readonly: true,
			format: 'png',
			type: 'static',
			version: 0,
		})
	}

	static getDefaultMapIcon() {
		return new Icon({
			id: null,
			name: '',
			readonly: true,
			format: 'svg',
			type: 'float',
			version: 0,
		})
	}

	static isDefaultIcon(icon: Icon) {
		return icon.getId() == null || icon.getFormat() == null
	}

	static getSizeByType(type: IconType) {
		const { staticIconSize, floatIconSize } = this.getAllSizes()
		return type === 'static' ? staticIconSize : floatIconSize
	}

	static getAllSizes() {
		const clientUiSettings = Session.current.getClientUiSettings() ?? {}
		const staticIconSize = clientUiSettings.staticIconSize ?? DefaultMapIconSize
		const floatIconSize = clientUiSettings.floatIconSize ?? DefaultMapIconSize
		return { staticIconSize, floatIconSize }
	}

	static remove(ids: string[]) {
		return wapi.send<RemoveIconsRequest, RemoveIconsResponse>('icons.remove', [ids])
	}

	static async getPortion(offset: number, limit: number) {
		const icons = await wapi.send<GetIconPortionRequest, GetIconPortionResponse>('icons.getPortion', { offset, limit })
		return icons?.map((icon) => Icon.fromJsonRpc(icon)) || []
	}

	static getAsyncLoaders() {
		return new AsyncLoaders({
			requestGet: 'icons.getPortion',
			objectFactory: Icon.fromJsonRpc,
		})
	}

	static fromJsonRpc(params: IconParams) {
		return new Icon(params)
	}

	static async getByIds(ids: IconId[]): Promise<Icon[]> {
		const icons: IconParams[] = await wapi.send('icons.getByIds', [ids])
		return icons.map((icon) => Icon.fromJsonRpc(icon))
	}
}
