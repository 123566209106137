import config from 'webapi/rpc/config'
import LRUCache from 'lib/LRUCache'
import wapi from 'webapi/rpc/web'
import {
	canViewTrack, canViewLocation, trackCacheKey,
	getTrackTileList,
} from '../utils/TrackTileUtils'
import { AsyncLoaders } from './AsyncLoaders'
import * as Utils from './Utils'
import { DispatchObject } from './DispatchObject'
import { PromiseIterator } from './PromiseIterator'
import { DispatchIterator } from './DispatchIterator'
import MonitoringObjectFactory from './MonitoringObjectFactory'
import { Command } from './Command'
import Repository from './Repository'
import chatService from './ChatService'
import { EventEmitter } from '../utils/EventEmitter'

/**
 * @typedef Dimention
 * @property {number} width
 * @property {number} height
 *
 * @typedef Viewport
 * @property {number} width
 * @property {number} height
 * @property {number} zoom
 */

export const onlineActuality = 5 * 60

const trackCache = new LRUCache({ maxSize: 10000 })

const emitter = new EventEmitter()

export const on = emitter.on.bind(emitter)
export const emit = emitter.emit.bind(emitter)

export class MonitoringObject extends DispatchObject {
	static on = on
	static emit = emit

	static onlineActuality = onlineActuality

	constructor(parentGroupItem, props) {
		super(parentGroupItem, props)
		// FIXME check item
		this._item = props
	}

	get getTrackTileList() {
		return getTrackTileList(this, { trackCache })
	}

	/**
	 * @returns {object}
	 */
	getEventGeneratorSettings() {
		return this.getJson('eventgenerator_settings_json', {})
	}

	/**
	 * @param {object} settings
	 */
	setEventGeneratorSettings(settings) {
		return this.setJson('eventgenerator_settings_json', settings || {})
	}

	/**
	 * @returns {Promise<object>}
	 */
	async getDefaultEventGeneratorSettings() {
		return wapi.send('Events.defaultGeneratorSettings')
	}

	/**
	 * @returns {Promise<unknown>}
	 */
	async getAutoAccelerationCalibrationSettings() {
		return wapi.send('Tracks.getVehicleAutoAccelerationCalibration', this.getId())
	}

	/**
	 * @param {string} id
	 * @param {*} reqBlocks
	 * @returns {Promise<unknown[]>}
	 */
	async tilesMassive(id, reqBlocks) {
		return wapi.send('Tracks.tilesMassive', [id, reqBlocks])
	}

	/**
	 * @private
	 * @param {unknown[]} points
	 * @returns {unknown[]?}
	 */
	_onRecentPoints(points) {
		const p = points && ((points.length && points[0]) || points)

		if (!p.staticId || !p.staticId.length || p.staticId !== this.getId()) {
			if (this._recentPos()) {
				this.clearCache()
				MonitoringObject.changePosition(this._recentPos())
				this._recentPos(null)
			}

			return null // NOTE: invalid recent position
		}

		if (!this._recentPos()) {
			// NOTE: initial recent position
			this.clearCache()
			MonitoringObject.changePosition(p)
			this._recentPos(p)
		}

		if (p && this._recentPos().captured !== p.captured) {
			const curBlock = Utils.timeBlockFromTimeStamp(p.captured)
			wapi.cacheInvalidate('Tracks.chartlod', [this.getId(), curBlock])
			trackCache.clear(trackCacheKey(this.getId(), curBlock))

			if (p.captured > this._recentPos().captured) {
				// NOTE: one may get "recent" position form the past, do not update if so, but fire signal!
				this._recentPos(p)
			}

			MonitoringObject.changePosition(p)
		}

		return points
	}

	clearCache() {
		wapi.cacheInvalidate('Tracks.chartlod', [this.getId()])
		trackCache.clear(trackCacheKey(this.getId()))
	}

	getEnabled() {
		return Boolean(this.get('enabled'))
	}

	/**
	 * @param {boolean} value
	 */
	setEnabled(value) {
		return this.set('enabled', Boolean(value))
	}

	/**
	 * @private
	 * @param {RecentPosition} value
	 */
	_recentPos(value) {
		if (typeof (value) !== 'undefined') this._properties._recentPos = value

		return this._properties._recentPos
	}

	/**
	 * @returns {Promise<RecentPosition?>}
	 */
	async getRecentPosition() {
		const hasMask = await canViewLocation()
		if (!hasMask) return this._onRecentPoints([]) // NOTE: in order not to break anything

		const lang = config.locale

		const result = await wapi.send('Tracks.recentPoints', [[this.getId()], lang])
		return this._onRecentPoints(result)
	}

	async hasRecentPosition() {
		if (this._recentPos()) return [this._recentPos()]
		return this.getRecentPosition()
	}

	/**
	 * @returns {RecentPosition}
	 */
	getCurrentPosition() {
		return this._recentPos()
	}

	/// @return Promise(Position)
	async getCapturedPosition(captured) {
		const hasMask = await canViewTrack()
		if (!hasMask) return null
		return wapi.send('Tracks.pointByTimestamp', [this.getId(), captured])
	}

	/**
	 * FIXME Do not wait while all blocks become ready, return DispatchIterator
	 * but for position arrays within blocks, not for positions
	 * FIXME: Update API
	 *
	 * @typedef Curve
	 * @property {string} value - curve id
	 * @property {string} value - curve id type: "positionFieldName", "positionCustomFieldId", "fuelingCurveTankId" or "eventCurveId"
	 * @property {string?} sensor - sensor reprensenting in custom field
	 *
	 * @param {Date | number} from - Date or Number timestamp
	 * @param {Date | number} to - Date or Number timestamp
	 * @param {number} lodLevel - desired sampling interval. Actually choosen from
	 * 0, 32, 128, ... 2^13, 2^15
	 * @param {Curve[]} curveIds - array of curves
	 * @return {Promise<Line[]>}
	 */
	async getTrackValue(from, to, lodLevel, curveIds) {
		if (!Array.isArray(curveIds)) {
			throw new Error('MonitoringObject.getTrackValue: curveIds is not an Array')
		}

		const hasMask = await canViewTrack()
		if (!hasMask) return []

		const requestAggregator = wapi.getRequestAggregator()
		const lod = Utils.normalizeChartLod(lodLevel)[0]

		const reqPromiseList = Utils.timeBlockListFromDateInterval(from, to)
			.map((t) => wapi.send('Tracks.chartlod', [this.getId(), t, lod, curveIds], { requestAggregator }))

		requestAggregator.send()
		return Promise.all(reqPromiseList)
	}

	/**
	 * @param {number} positionTimeStamp
	 * @param {object} kvArgs - begin, end [,desc]
	 */
	async getPositionIndex(positionTimeStamp, kvArgs) {
		const hasMask = await canViewTrack()
		if (!hasMask) return undefined
		return wapi.send('Tracks.positionIndex', [positionTimeStamp, { ...kvArgs, id: this.getId() }])
	}

	/**
	 * {
	 *  begin: number,
	 *  end: number,
	 *  desc: boolean,
	 * }
	 */
	async getPositionGroupedIndex(positionTimeStamp, kvArgs) {
		const hasMask = await canViewTrack()
		if (!hasMask) return undefined
		return wapi.send('Tracks.positionGroupedIndex', [positionTimeStamp, { ...kvArgs, id: this.getId() }])
	}

	/**
	 *
	 * @param {object} kvArgs - begin, end [,limit] [,offset] [,desc]
	 * @returns {DispatchIterator}
	 */
	getTrackDetails(kvArgs) {
		return new DispatchIterator(async (onObjectCallback, onDoneCallback) => {
			const hasMask = await canViewTrack()
			if (!hasMask) return onDoneCallback && onDoneCallback()
			let total = 0
			let columns = []
			try {
				const result = await wapi.send('Tracks.trackDetails', { ...kvArgs, id: this.getId() })
				if (result.rows) result.rows.forEach(onObjectCallback)
				total = result.total
				columns = result.columns
			} finally {
				if (onDoneCallback) onDoneCallback(total, columns)
			}
			return undefined
		})
	}

	/**
	 * {
	 *  begin: number,
	 *  end: number,
	 *  columns: { key: string, is_custom?: boolean }[],
	 *  limit: number,
	 *  offset: number,
	 *  desc: boolean,
	 * }
	 * @returns {DispatchIterator}
	 */
	getShortTrackDetails(kvArgs) {
		return new DispatchIterator(async (onObjectCallback, onDoneCallback) => {
			try {
				const hasMask = await canViewTrack()
				if (!hasMask) return onDoneCallback && onDoneCallback()

				const result = await wapi.send('Tracks.shortTrackDetails', { ...kvArgs, id: this.getId() })
				if (result.rows) result.rows.forEach(onObjectCallback)
				if (onDoneCallback) onDoneCallback(result.total, result.columns)
			} catch {
				if (onDoneCallback) onDoneCallback(0, [])
			}
			return undefined
		})
	}

	/**
	 * {
	 *  begin: number,
	 *  end: number,
	 *  analog: { key: string, is_custom?: boolean }[],
	 *  digital: { key: string, is_custom?: boolean }[],
	 *  limit: number,
	 *  offset: number,
	 *  desc: boolean,
	 * }
	 * @returns {DispatchIterator}
	 */
	getTrackGroupedDetails(kvArgs) {
		return new DispatchIterator(async (onObjectCallback, onDoneCallback) => {
			try {
				const hasMask = await canViewTrack()
				if (!hasMask) return onDoneCallback && onDoneCallback()

				const result = await wapi.send('Tracks.trackGroupedDetails', { ...kvArgs, id: this.getId() })
				if (result.rows) result.rows.forEach(onObjectCallback)
				if (onDoneCallback) onDoneCallback(result.total, result.columns)
			} catch {
				if (onDoneCallback) onDoneCallback(0, [])
			}
			return undefined
		})
	}

	/**
	 * @param {number} param0.begin
	 * @param {number} param0.end
	 * @param {object} param0.filter
	 * @param {object} param0.customFilter
	 * @returns {DispatchIterator}
	 */
	async getTrackEvents({ begin, end, customFilter, filter }) {
		return wapi.send('Tracks.trackEvents', { begin, end, filter, customFilter, id: this.getId() })
	}

	/**
	 * @param {number} from
	 * @param {number} to
	 * @param {Viewport} viewport
	 * @param {LatLng} viewpoint
	 * @param {Dimention} groupingSize
	 * @returns {Promise<*>}
	 */
	async getTrackEventsByViewport(from, to, viewport, viewpoint, groupingSize) {
		const hasMask = await canViewTrack()
		if (!hasMask) return []

		return wapi.send('Events.groupedEvents', [
			this.getId(),
			from,
			to,
			{
				width: viewport.width,
				height: viewport.height,
				zoom: viewport.zoom,
				geopoint: { lon: viewpoint.longitude, lat: viewpoint.latitude },
			},
			groupingSize,
		])
	}

	/**
	 * @abstract
	 * @returns {DispatchIterator}
	 */
	getRule() { // FIXME stub
		return new DispatchIterator((onObjectCallback, onDoneCallback) => {
			if (onDoneCallback) onDoneCallback()
		})
	}

	/**
	 * @returns {Proimse<Command[]>}
	 */
	async getCommands() {
		const commands = await wapi.send('command.getByVehicleIds', [[this.getId()]])
		return commands.map((commandProperties) => new Command(this, commandProperties))
	}

	/**
	 * MonitoringObject.executeCommand
	 *
	 * args Object: {
	 *   command: "..COMMAND_ID..",
	 *   arguments: {
	 *     "...PARAMETER_NAME...": "...VALUE...",
	 *     "...": "...",
	 *     ...
	 *   }
	 * }
	 *
	 * @returns {Promise<string>} - that resolves with identifier of launch object
	 */
	async executeCommand(args) {
		return wapi.send('command.execute', [args])
	}

	/**
	 * @param {string} geolayerGeometryId
	 * @returns {Promise<string>} - launch id
	 */
	async sendAssistanceRequest(geolayerGeometryId) {
		return wapi.send('navitag.sendAssistanceRequest', [geolayerGeometryId, this.getId()])
	}

	/**
	 *
	 * @param {number} offset
	 * @param {number} count
	 */
	getChatHistory(offset, count) {
		return chatService.getHistory(this.getId(), offset, count)
	}

	/**
	 * @param {string} message
	 */
	sendChatMessage(message) {
		return chatService.sendMessage(this.getId(), message)
	}

	/**
	 * @param {string[]} messageIdList
	 */
	markChatMessageList(messageIdList) {
		return chatService.markMessageList(this.getId(), messageIdList)
	}

	/**
	 * @returns {object}
	 */
	getParameters() {
		const params = this.getJson('parameters_json', {})
		return {
			sensorTriggers: [],
			...params,
		}
	}

	/**
	 * @param {object} value
	 */
	setParameters(value) {
		this.setJson('parameters_json', value)
	}
}

/**
 * @param {Group} group
 * @param {boolean} recursive
 * @returns {PromiseIterator}
 */
export function get(group, recursive) {
	return new PromiseIterator(
		group.getIds().then(Repository.listGetter.vehicle.bind(null, recursive)),
		MonitoringObjectFactory.create.bind(MonitoringObjectFactory),
	)
}
MonitoringObject.get = get

/**
 * NOTE: The order in response can be different
 * @param {string[]} idList
 * @returns {Promise<MonitoringObject[]>}
 */
export async function getList(idList) {
	const list = await Repository.byIdListGetter.vehicle(idList)
	return list.map((item) => MonitoringObjectFactory.create(item))
}
MonitoringObject.getList = getList

/**
 * @param {Group} group
 * @param {object} filter
 * @returns {Promise<string[]>}
 */
export async function getAvailableIdList(group, filter) {
	return wapi.send('vehicle.availableIds', [
		group ? group.getId() : null,
		filter ?? null,
	])
}
MonitoringObject.getAvailableIdList = getAvailableIdList

/**
 * @param {string[]} objectIdList
 * @returns {Promise<Command[]>}
 */
export async function getExecutableCommandList(objectIdList) {
	return wapi.send('command.getExecutableByVehicleIds', [objectIdList]).then((commands) => commands.map((props) => {
		const cmd = new Command(props && props.parent, props)
		return cmd.setImplicit(Command.implicitExecutableList.indexOf(props.command) >= 0)
	}))
}
MonitoringObject.getExecutableCommandList = getExecutableCommandList

/**
 * @param {MonitoringObject[]} objectList
 * @param {string} lang
 * @param {boolean} silent
 * @returns {Promise<Recentposition[]>}
 */
export async function loadRecentPositions(objectList, lang, silent) {
	const hasMask = await canViewLocation()
	if (!hasMask) return null

	const _lang = lang || config.locale
	const points = await wapi.send('Tracks.recentPoints', [
		objectList.map((object) => object.getId()),
		_lang,
	])
	if (points.length !== objectList.length) {
		// NOTE: something wrong happened
		return null
	}
	return objectList.map((object, idx) => (silent ? points[idx] : object._onRecentPoints(points[idx])))
}
MonitoringObject.loadRecentPositions = loadRecentPositions

/**
 * Request report by objects in CSV format
 * @param {object} params
 * @returns {Promise<string>} - CSV string
 */
export async function getVehiclesWithTrackersReport(params) {
	const lang = config.locale
	const reportType = 'vehiclesWithTrackersReport'
	const reqParam = [{ ...params, reportType }, lang]
	return wapi.send('report.buildReport', reqParam, { withoutTimeout: true })
}
MonitoringObject.getVehiclesWithTrackersReport = getVehiclesWithTrackersReport

/**
 * @deprecated use service instead
 * @param {RecentPosition} newPosition
 */
export function changePosition(newPosition) {
	this.emit('positionChanged', newPosition)
}
MonitoringObject.changePosition = changePosition

/**
 * Get params:
 * offset: number
 * limit: number
 * customFilter: object
 * sort: array
 * ---
 * id: string - vehicle id
 * begin: number - begin time
 * end: number end time
 *
 * @param {object} param0
 * @param {string} param0.id
 * @param {number} param0.begin
 * @param {number} param0.end
 * @returns {AsyncLoaders<DispatchEvent>}
 */
export function getTrackEventsPortion({ id, begin, end }) {
	return new AsyncLoaders({
		getExtraArguments: () => ({ id, begin, end }),
		requestGet: 'Tracks.trackEventsPortion',
		objectsKey: 'events',
	})
}
MonitoringObject.getTrackEventsPortion = getTrackEventsPortion

/**
 * @param {Group} group
 * @param {object} options
 * @param {boolean} recursive
 * @returns {AsyncLoaders<MonitoringObject>}
 */
export function asyncLoaders(group, options, recursive) {
	const opt = options || {}
	return new AsyncLoaders({
		objectFactory: MonitoringObjectFactory.create.bind(MonitoringObjectFactory),
		getExtraArguments: AsyncLoaders.extraArguments.groupRecursive.bind(null, group, recursive),
		applyArguments(arg1) {
			const { withFence, objectsType } = opt
			const args = arg1
			args.with_fence = Boolean(withFence)
			if (objectsType) {
				args.objectsType = objectsType
			}
		},
		requestGet: 'vehicle.getPortion',
		countGet: 'vehicle.count',
		getCountArguments: (params) => [(Date.now() / 1000 | 0) - onlineActuality, ...params],
		entityName: 'vehicle',
	})
}
MonitoringObject.asyncLoaders = asyncLoaders

wapi.cacheRegister('vehicle.getPortion', {
	getPath: (params) => {
		return params.map((value) => JSON.stringify(value))
	},
	maxSize: 10,
	expire: config.portionPollingInterval,
})
Repository.registerInvalidator('vehicle', () => wapi.cacheInvalidate('vehicle.getPortion'))

/**
 * @param {Group} group
 * @param {object} options
 * @returns {AsyncLoaders<MonitoringObject>}
 */
export function asyncLoadersRecursive(group, options) {
	return asyncLoaders(group, options, true)
}
MonitoringObject.asyncLoadersRecursive = asyncLoadersRecursive

/**
 * actual - vehicles online
 * filtered - vehicles sutiable for filter
 * total - count of vehicles in system visible for curent user
 *
 * @param {number} now actuality timestamp
 * @param {'actual'|'filtered'|'total'} level level of count details (incremental value)
 * @param {*} args request params
 * @param {*} extraArgs additional request params
 * @returns {{ total?: number, filtered?: number: actual: number }}
 */
export async function getVehiclesCount(now, level, args, extraArgs) {
	let allArgs = [Math.floor(now.valueOf() / 1000) - onlineActuality, level, args]
	if (extraArgs) allArgs = allArgs.concat(extraArgs)
	return wapi.send('vehicle.count', allArgs)
}
MonitoringObject.getVehiclesCount = getVehiclesCount

wapi.cacheRegister('vehicle.count', {
	getPath: ([now, level, args, extra]) => {
		delete args.limit
		delete args.offset
		return [level, JSON.stringify(args), JSON.stringify(extra)].filter(Boolean)
	},
	maxSize: 10,
	expire: config.portionPollingInterval,
})

function _invalidateOnlineCount() {
	wapi.cacheInvalidate('vehicle.count')
}

Repository.registerInvalidator('vehicle', _invalidateOnlineCount)


/**
 * @param {Viewport} viewport
 * @param {LatLng} viewpoint
 * @param {Dimention} groupingSize
 * @param {string[]} excludeVehicleIds
 * @returns {Promise<RecentPositionGrid[]>}
 */
export async function getRecentPositionGrid(params) {
	const {
		viewport,
		viewpoint,
		groupingSize,
		portionFetchReq,
		fetchReq,
		excludeVehicleIds,
	} = params

	const list = await wapi.send('Tracks.recentPositionGrid', [
		{
			width: viewport.width,
			height: viewport.height,
			zoom: viewport.zoom,
			geopoint: { lon: viewpoint.longitude, lat: viewpoint.latitude },
		},
		groupingSize,
		portionFetchReq,
		fetchReq,
		excludeVehicleIds || [],
	])

	return list.map((item) => item.captured
		? item
		: ({ ...item, captured: Date.now() / 1000 }))
}
MonitoringObject.getRecentPositionGrid = getRecentPositionGrid

// Returns array of the following objects
// {
//   id: (string/Uuid) vehicle id
//   longitude: (number)
//   latitude: (number)
//   status: (number) see NavitagCommandsService::assistantVehicles
//   requestTime: (number) [optional, only if status == 1]
// }
// TODO describe status field when it will be ready
/**
 * @typedef Assistance
 * @property {string} id - vehicle id
 * @property {number} latitude
 * @property {number} longitude
 * @property {number} status
 * @property {number} requestTime
 *
 * @param {number} longitude
 * @param {number} latitude
 * @param {number} numberToSearch
 * @param {string} geolayerGeometryId
 * @returns {Promise<Assistance[]>}
 */
export async function findAssistantVehicles(longitude, latitude, numberToSearch, geolayerGeometryId) {
	return wapi.send('navitag.assistantVehicles', [longitude, latitude, numberToSearch, geolayerGeometryId])
}
MonitoringObject.findAssistantVehicles = findAssistantVehicles

/**
 * @returns {Promise<*>}
 */
export async function getSupportedPositionFields() {
	return wapi.send('Tracks.chartlod.supportedPositionFields')
}
MonitoringObject.getSupportedPositionFields = getSupportedPositionFields

/**
 * @param {*} params
 * @returns {Promise<*>}
 */
export async function regenerateHistory(params) {
	return wapi.send('regeneration.regenerate', params)
}
MonitoringObject.regenerateHistory = regenerateHistory

/**
 * @param {string[]} ids - vehicle ids
 * @returns {Promise<Record<string, string[]>>} - map of fence lists
 */
export async function getVehiclesGeofences(ids) {
	return wapi.send('vehicle.getVehiclesGeoFences', [ids])
}
MonitoringObject.getVehiclesGeofences = getVehiclesGeofences

Repository.registerListGetter('vehicle', (recursive, groups) => wapi.send('vehicle.get', { recursive, groups }))
Repository.registerByIdGetter('vehicle', (id) => wapi.send('vehicle.getById', [id]))
Repository.registerByIdListGetter('vehicle', (idList) => wapi.send('vehicle.getByIdList', [idList]))

// FIXME This cache is not the best way to cache charts.
// FIXME It caches whole request, but it's better to cache curves separately.
// TODO Make custom cache (see above).
wapi.cacheRegister('Tracks.chartlod', {
	getPath: (params) => [params[0], params[1], params[2], JSON.stringify(params[3])],
	maxSize: 1000,
})

export const FilterColumns = {
	Name: 'name',
	IMEI: 'imei',
	Description: 'description',
	FirstTripPoint: 'firstTripPoint',
	LastTripPoint: 'lastTripPoint',
	TripStatus: 'tripStatus',
	TripBeginTimestamp: 'tripBeginTimestamp',
	TripEndTimestamp: 'tripEndTimestamp',
	Tracker: 'tracker',
	Phone: 'phonenumber',
	MaxReceivedTimestamp: 'max_received_timestamp',
	MaxCapturedTimestamp: 'max_captured_timestamp',
	Enabled: 'onlyEnabled',
	DeviceIdent: 'deviceident',
	VIN: 'vin',
}
MonitoringObject.FilterColumns = FilterColumns

export const SortColumns = {
	Name: 'name',
	ControlFenceName: 'control_fence_name',
}
MonitoringObject.SortColumns = SortColumns

export const TripStatusFilter = {
	InProgress: 'inProgress',
	Planned: 'planned',
	SoonEnd: 'soonEnd',
}
MonitoringObject.TripStatusFilter = TripStatusFilter

export const AssistantVehicleStatus = {
	NoRequest: 0,
	InProgress: 1,
	Accepted: 2,
	Refused: 3,
	TimeOut: 4, // FIXME timeout includes any errors.. Ok for feature #69202
	Accomplished: 5,
	Delivered: 6,
}
MonitoringObject.AssistantVehicleStatus = AssistantVehicleStatus
