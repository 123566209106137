import { uuid } from '../utils/uuid'
import DBStructure from './DBStructure'
import { Transaction } from './Transaction'
import Repository from './Repository'
import { Group } from './Group'
import { EventEmitter } from '../utils/EventEmitter'
import * as Session from './Session'

/**
 * Take entity from DBStructure and return its fields name list
 * @param {string} kind - entity name
 * @returns {string[]}
 */
function getEntityFields(kind) {
	return DBStructure[kind].fields.reduce((res, field) => {
		if (typeof field === 'string') {
			res.push(field)
		} else if (typeof field === 'object') {
			const { name, mask } = field

			if (mask == null) {
				res.push(name)
			} else {
				const permMask = Session.current.getUser().getRole().getPermissionMask(kind)
				if (mask === (mask & permMask)) res.push(name)
			}
		}
		return res
	}, [])
}

/**
 * @abstract
 * @template {DispatchObject<any>} Parent
 */
export class DispatchObject extends EventEmitter {
	static entityName = ''

	/**
	 * @private
	 */
	_parentConstructor = Group

	/**
	 * @return {string}
	 */
	get entityName() {
		return this.constructor.entityName
	}

	/**
	 * @param {Parent | string?} parent
	 * @param {object | undefined} properties
	 */
	constructor(parent, properties) {
		super()

		this._patch = null

		if (properties) {
			this._properties = typeof properties === 'string'
				? Repository.data(this.entityName, properties)
				: properties
		} else { // Create new object
			this._properties = { isNewObject: true }
			this._properties.id = uuid()

			const fields = getEntityFields(this.entityName)

			fields.forEach((f) => {
				this._properties[f] = ''
			})
		}

		if (parent instanceof DispatchObject) {
			this._properties.parent = parent.getId()
		} else if (typeof parent === 'string') {
			this._properties.parent = parent
		}

		if (this._properties?.isNewObject) {
			this._create()
		}
	}

	/**
	 * @template {object} T
	 * @param {string} propertyName
	 * @param {T} value
	 * @param {((a: T, b: T) => boolean) | undefined} comparator
	 * @returns {this}
	 */
	set(propertyName, value, comparator) {
		if (comparator && comparator(this._properties[propertyName], value)) return this
		if (!comparator && this._properties[propertyName] === value) return this

		this._properties[propertyName] = value

		const patch = this._patch || { op: 'edit' }
		patch.fields = patch.fields || {}
		patch.fields[propertyName] = value != null ? String(value) : null
		this._addPatch(patch)

		return this
	}

	/**
	 * @template {object} T
	 * @param {string} propertyName
	 * @returns {T | null}
	 */
	get(propertyName) {
		const value = this._properties[propertyName]
		if (typeof value === 'boolean') return value
		return value !== undefined ? value : ''
	}

	/**
	 * Calls special setter for prop if it exists
	 * @template {object} T
	 * @param {string} prop
	 * @param {T} value
	 * @param {((a: T, b: T) => boolean) | undefined} comparator
	 * @returns {this}
	 */
	setProp(prop, value, comparator) {
		const key = `__setter__${prop}`
		const field = this[key]
		return typeof field === 'function'
			? field(value)
			: this.set(prop, value, comparator)
	}

	/**
	 * Calls special getter for prop if it exists
	 * @template {object} T
	 * @param {string} prop
	 * @returns {T}
	 */
	getProp(prop) {
		const key = `__getter__${prop}`
		const field = this[key]
		return typeof field === 'function'
			? field()
			: this.get(prop)
	}

	/**
	 * @template {object} T
	 * @param {string} propName
	 * @param {T | undefined} fallback
	 * @returns {T | undefined}
	 */
	getJson(propName, fallback) {
		const value = this.get(propName)
		if (value == null) return fallback
		try {
			return JSON.parse(value)
		} catch {
			return fallback
		}
	}

	/**
	 * @template {object} T
	 * @param {string} propName
	 * @param {T} value - JSON serializable
	 * @param {((a: string, b: string) => boolean) | undefined} comparator
	 */
	setJson(propName, value, comparator) {
		const json = JSON.stringify(value)
		return this.set(propName, json, comparator)
	}

	/**
	 * @return {void}
	 */
	dirty() {
		Repository.clearData(this.entityName, this.getId())
	}

	/**
	 * @returns {boolean}
	 */
	isNew() {
		return Boolean(this.get('isNewObject'))
	}

	/**
	 * @returns {string}
	 */
	getId() {
		return this.get('id')
	}

	/**
	 *
	 * @returns {string | undefined}
	 */
	getParentId() {
		return this.get('parent')
	}

	/**
	 * @param {string} parentId
	 * @param {Transaction | undefined} transaction
	 * @returns {this}
	 */
	setParentId(parentId, transaction) {
		if (this.getParentId() === parentId) return this

		this._properties.parent = parentId
		const parentName = this._getParentEntityName()

		const patch = this._patch || { op: 'edit' }
		patch.parent = {
			table: parentName,
			staticid: parentId,
		}
		this._addPatch(patch, transaction)

		return this
	}

	/**
	 * @deprecated use getParentPromise instead
	 * @returns {Parent | null}
	 */
	getParent() {
		const parentId = this.getParentId()
		return parentId ? new this._parentConstructor(null, parentId) : null
	}

	/**
	 * @returns {Promise<Parent | undefined>}
	 */
	async getParentPromise() {
		const parentId = this.getParentId()
		const parentName = this._getParentEntityName()
		if (!parentId || !parentName) return undefined
		const parentProps = await Repository.byIdGetter[parentName](parentId)
		if (!parentProps) return undefined
		return new this._parentConstructor(null, parentProps)
	}

	/**
	 * @deprecated
	 * @returns {this}
	 */
	reload() {
		this._properties = Repository.data(this.entityName, this.getId())
		return this
	}

	/**
	 * Creates object clone
	 * @returns {DispatchObject}
	 */
	clone() {
		return new this.constructor(this.getParentId(), JSON.parse(JSON.stringify(this._properties)))
	}

	/**
	 * Creates object copy with new uuid
	 * @param {Transaction} transaction
	 * @returns {DispatchObject}
	 */
	copy(transaction) {
		const obj = new this.constructor(this.getParentId())
		const fields = getEntityFields(this.entityName)

		fields.forEach((field) => {
			obj.setProp(field, this.getProp(field))
		})

		if (transaction) obj.save(transaction)

		return obj
	}

	/**
	 * Deletes object on backend or patch transaction if passed
	 * @param {Transaction | undefined} transaction
	 * @returns {this}
	 */
	delete(transaction) {
		const patch = {}
		// NOTE: delete always has priority over edit and create
		patch.op = 'delete'
		this._addPatch(patch, transaction)
		return this
	}

	/**
	 * Send patch to backend
	 * @param {Transaction} transaction
	 * @returns {Promise<void>} // todo: define transactions.save() return type
	 */
	async save(transaction) {
		if (this.entityName.length === 0) {
			throw new Error(`${typeof this} does not contains entity/table name for save changes`)
		}

		if (transaction) {
			transaction.add(this, this._patch)
			this._saveExternalTransaction(transaction)
			this._patch = null
		} else {
			const tr = new Transaction()
			tr.add(this, this._patch)
			this._saveExternalTransaction(tr)
			this._patch = null
			return tr.save()
		}
		return undefined
	}

	/**
	 * Check if there are changed in the objects patch
	 * @returns {boolean}
	 */
	hasChanges() {
		return this._patch && JSON.stringify(this._patch) !== '{}'
	}

	/**
	 * Return changed fields from the patch
	 * @returns {object}
	 */
	getChanges() {
		const fields = this._patch?.fields ?? {}
		return { ...fields }
	}

	/**
	 * Check that object has deletion patch
	 * @returns {boolean}
	 */
	isDeleted() {
		return this._patch && this._patch.op === 'delete'
	}

	/**
	 * Check that object has creation patch
	 * @returns {boolean}
	 */
	isCreated() {
		return this._patch && this._patch.op === 'create'
	}

	/**
	 * Apply external transaction to object
	 * @param {Transaction} transaction
	 */
	addExternalTransaction(transaction) {
		if (!this._externalTransaction) {
			this._externalTransaction = new Transaction()
		}

		transaction.save(this._externalTransaction)
	}

	/**
	 * Apply external transaction to another transaction if passed
	 * @private
	 * @param {Transaction | undefined} transaction
	 */
	_saveExternalTransaction(transaction) {
		if (this._externalTransaction && transaction) {
			this._externalTransaction.save(transaction)
			this._externalTransaction = null
		}
	}

	/**
	 * Creates patch to create object and puts data to repository
	 * @private
	 */
	_create() {
		const patch = {}
		patch.op = 'create'
		patch.fields = {}

		const parentId = this.getParentId()
		if (parentId) {
			// FIXME group has two parents and we can use only last one
			patch.parent = {
				table: this._getParentEntityName(),
				staticid: parentId,
			}
		}
		this._patch = patch
		Repository.setData(this.entityName, this._properties)
	}

	/**
	 * Replace local patch or add it to transaction if passed
	 * @private
	 * @param {object} patch
	 * @param {Transaction | undefined} transaction
	 */
	_addPatch(patch, transaction) {
		if (transaction) {
			transaction.add(this, patch)
			this._saveExternalTransaction(transaction)
		} else {
			this._patch = patch
		}
	}

	/**
	 * @private
	 * @returns {string}
	 */
	_getParentEntityName() {
		// FIXME hack (dbstructure parent is array)
		const lastId = DBStructure[this.entityName].parent.length - 1
		return DBStructure[this.entityName].parent[lastId]
	}
}
