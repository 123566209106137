/* eslint-disable max-classes-per-file */
import { DispatchObject } from '../main/DispatchObject'
import { dateTimePropertyMixin } from '../main/_dateTimePropertyMixin'
import { Driver } from './Driver'
import { AsyncLoaders } from '../main/AsyncLoaders'
import MonitoringObjectFactory from '../main/MonitoringObjectFactory'

class FromTimestamp extends dateTimePropertyMixin(DispatchObject, 'from_timestamp', 'FromTimestamp') {}
class ToTimestamp extends dateTimePropertyMixin(FromTimestamp, 'to_timestamp', 'ToTimestamp') {}

export class DriverAssignment extends ToTimestamp {
	static entityName = 'driver_assignment'

	/**
	 * @private
	 */
	_parentConstructor = Driver

	constructor(parent, properties) {
		super(parent, properties)

		if (parent instanceof DispatchObject && this._properties?.isNewObject) {
			this._properties['driver_name'] = parent.getName()
		}
	}

	getVehicleId() {
		return this.get('vehicle_id')
	}

	setVehicleId(value) {
		return this.set('vehicle_id', value)
	}

	getSource() {
		return this.get('source')
	}

	getActivity() {
		return this.get('activity')
	}

	// Do not set vehicle_name and driver_name, its useless and stupid
	getVehicleName() {
		return this.get('vehicle_name')
	}

	getDriverName() {
		return this.get('driver_name')
	}
}

export function create(driver, transaction) {
	const retval = new DriverAssignment(driver)
	retval.set('source', DriverAssignment.Sources.user)
	if (transaction) {
		retval.save(transaction)
	}
	return retval
}
DriverAssignment.create = create

export const vehiclesWithoutTachoAssignments = {
	asyncLoadersRecursive: (group) => new AsyncLoaders({
		objectFactory: MonitoringObjectFactory.create.bind(MonitoringObjectFactory),
		getExtraArguments: AsyncLoaders.extraArguments.groupRecursive.bind(null, group),
		requestGet: 'vehicle.getWithoutTachoAssignmentsPortion',
		entityName: 'vehicle',
	}),
}
DriverAssignment.vehiclesWithoutTachoAssignments = vehiclesWithoutTachoAssignments

export const Sources = {
	user: 'user',
	tachograph: 'tachograph',
}
DriverAssignment.Sources = Sources

export const Activity = {
	breakRest: 'breakRest',
	availability: 'availability',
	work: 'work',
	driving: 'driving',
}
DriverAssignment.Activity = Activity

vehiclesWithoutTachoAssignments.asyncLoadersRecursive.FilterColumns = {
	Name: 'name',
	IMEI: 'imei',
}

vehiclesWithoutTachoAssignments.asyncLoadersRecursive.SortColumns = {
	Name: 'name',
}
