/** A dialog with Ok button to display e.g. an error message.
 * Use it just like dijit/ConfirmDialog
 * message = new MessageBox({ title: "Warning", content: "Server error" });
 * message.on("execute", function() { conslole.log("done") });
 * message.show();
 */
define([
	"dojo/_base/declare"
	, "dijit/Dialog"
	, "dijit/_WidgetsInTemplateMixin"
	, "dojo/i18n!dijit/nls/common"
	, "core/tpl!./MessageBox"
	, "dijit/form/Button"
], function(
	declare
	, Dialog
	, _WidgetsInTemplateMixin
	, strings
	, actionBarMarkup
) {
	return declare([Dialog, _WidgetsInTemplateMixin], {
		actionBarTemplate: actionBarMarkup
		, buttonOk: strings.buttonOk
		, buttonCancel: strings.buttonCancel
		, _setButtonOkAttr: { node: "okButton", attribute: "label" }
		, _setButtonCancelAttr: { node: "cancelButton", attribute: "label" }
		, style: "width: 30em"
		, showCancel: false

		// signal
		, onCancel: function() {}

		, postCreate: function() {
			this.inherited(arguments);
			if (!this.showCancel) {
				this.cancelButton.domNode.style.display = 'none';
			}
		},

		show() {
			this._lastShowPromise = this.inherited(arguments)
			return this._lastShowPromise
		},

		hide() {
			this._lastHidePromise = this.inherited(arguments)
			this.destroy()
			return this._lastHidePromise
		},

		destroy() {
			Promise.all([this._lastShowPromise, this._lastHidePromise])
				.then(() => this.inherited(arguments))
		},
	});
});
