function isNull(object) {
	return !object || typeof (object.id) !== 'string'
}

// TODO: export Repository class
class Repository {
	listGetter = {}
	byIdGetter = {}
	byIdListGetter = {}
	customGetter = {}

	/**
	 * @private
	 */
	_invalidator = {}

	/**
	 * @private
	 */
	_data = {}

	/**
	 *
	 * @param {string} entityName
	 * @param {Function} listGetter
	 */
	registerListGetter(entityName, listGetter) {
		this.listGetter[entityName] = (...args) => (
			listGetter(...args).then((result) => result.map((item) => {
				const old = this.data(entityName, item.id)
				if (old && !old.isNewObject) return old

				this.setData(entityName, item)
				return item
			}))
		)
	}

	/**
	 * @param {string} entityName
	 * @param {string} customGetterName
	 * @param {Function} getter
	 */
	registerCustomGetter(entityName, customGetterName, getter) {
		this.customGetter[entityName] = this.customGetter[entityName] || {}
		this.customGetter[entityName][customGetterName] = (...args) => (
			getter(...args).then((result) => {
				const map = (item) => {
					if (isNull(item)) return null

					const old = this.data(entityName, item.id)
					if (old && !old.isNewObject) return old

					this.setData(entityName, item)
					return item
				}

				if (result && result.length >= 0) return result.length ? result.map(map) : []

				return map(result)
			})
		)
	}

	registerByIdGetter(entityName, byIdGetter) {
		this.byIdGetter[entityName] = (id, ...args) => {
			const old = this.data(entityName, id)
			if (old && !old.isNewObject) return Promise.resolve(old)

			return byIdGetter(id, ...args).then((result) => {
				if (isNull(result)) return null
				this.setData(entityName, result)
				return result
			})
		}
	}

	/**
	 *
	 * @param {string} entityName
	 * @param {Function} byIdListGetter
	 */
	registerByIdListGetter(entityName, byIdListGetter) {
		this.byIdListGetter[entityName] = (idList, ...args) => {
			const dataMap = {}

			idList.forEach((id) => {
				dataMap[id] = this.data(entityName, id)
			})

			const old = []
			const reqId = []

			Object.entries(dataMap).forEach(([id, value]) => {
				if (value && !value.isNewObject) old.push(value)
				else reqId.push(id)
			})

			if (reqId.length) {
				return byIdListGetter(reqId, ...args).then((result) => {
					result.forEach((item) => {
						this.setData(entityName, item)
					}, this)
					return result.concat(old)
				})
			}

			return Promise.resolve(old)
		}
	}

	/**
	 * @param {string} entityName
	 * @param {Function} invalidator
	 */
	registerInvalidator(entityName, invalidator) {
		if (!this._invalidator[entityName]) this._invalidator[entityName] = []
		this._invalidator[entityName].push(invalidator)
	}

	/**
	 *
	 * @param {string} entityName
	 * @param {string} id
	 * @returns {DispatchObject | null}
	 */
	data(entityName, id) {
		if (this._data[entityName]) {
			return id
				? this._data[entityName][id]
				: this._data[entityName]
		}
		return null
	}

	/**
	 * @param {string} entityName
	 * @param {string} id
	 */
	clearData(entityName, id) {
		if (this._data[entityName]) {
			if (id) {
				delete this._data[entityName][id]
				if (this._invalidator[entityName]) this._invalidator[entityName].forEach((cb) => cb(id))
			} else {
				const data = this._data[entityName]

				Object.entries(data).forEach(([key, value]) => {
					if (!value.isNewObject) delete data[key]
				})

				if (this._invalidator[entityName]) this._invalidator[entityName].forEach((cb) => cb(id))
			}
		}
	}

	/**
	 * @param {string} entityName
	 * @param {*} data
	 */
	setData(entityName, data) {
		if (!this._data[entityName]) this._data[entityName] = {}
		this._data[entityName][data.id] = data
	}
}

export default new Repository()
