import { ReportTemplate } from './ReportTemplate'

export class ReportTemplateScheduleTrip extends ReportTemplate {
	constructor(...args) {
		super(...args)
		this.set('type', ReportTemplate.Type.ScheduleTrip)
	}

	getTripStatusFilter() {
		return this._getSetting('tripStatusFilter') || []
	}

	setTripStatusFilter(value) {
		return this._setSetting('tripStatusFilter', value)
	}

	getOverlapsFilter() {
		return this._getSetting('overlapsFilter') || []
	}

	setOverlapsFilter(value) {
		return this._setSetting('overlapsFilter', value)
	}

	static testProperties(properties) {
		return properties.type === ReportTemplate.Type.ScheduleTrip
	}
}
