import { Group, getRoots } from './Group'

/**
 * @deprecated use isRoot() method for Groups
 * @class RootGroup
 * @extends Group
 *
 * User's set of available groups
 *
 * FIXME fix API: Group.isRoot() -> bool
 * if null parent means deleted object
 */
export class RootGroup extends Group {
	getIds() {
		return getRoots()
	}

	isRoot() {
		return true
	}
}
