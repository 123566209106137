/**
 * @internal
 * Construct tracker of proper type derived from Tracker while iterating over trackers.
 * Loads necessary .js files defining tracker types
 */

export class Factory {
	_types = []
	_default = null

	/**
	 * @brief the idea behind this factory is that it tries number of testProperties over each property set
	 * and the first returning true considered as matching object type for current properties.
	 */
	create(properties) {
		for (let i = 0; i < this._types.length; ++i) {
			if (
				this._types[i].testProperties(properties)
			) {
				return new this._types[i](properties && properties.parent, properties)
			}
		}
		if (this._default) {
			return new this._default(properties && properties.parent, properties)
		}
		// eslint-disable-next-line no-console
		console.log('Cannot create object bacause of no matching constructor found', properties)
		throw Error('No constructor found in object factory!')
	}

	register(typeCtor) {
		if (typeCtor instanceof Function) {
			if (typeCtor.testProperties instanceof Function) {
				this._types.push(typeCtor)
			} else {
				this._default = typeCtor
			}
		} else {
			// eslint-disable-next-line no-console
			console.log('Cannot register non-function within a factory')
			throw Error('Attempt to register non-function in factory')
		}
	}
}
