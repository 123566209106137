import BaseAuth from "core/BaseAuth"
import dispatchServer from "webapi/main/DispatchServer"
import load from "core/load"

import app from 'core/application'

async function showLogin(title) {
	const { default: Login } = await import("core/login/loginWidget")
	var _login = new Login({
		class: 'dispatchLoginDialog',
		title,
	})
	_login.placeAt(dojo.body())
	_login.startup()

	load.hide()
}

async function loadApp() {
	const { default: jed } = await import("core/jed")
	return jed.loadTranslationVariants()
}

async function main() {
	load.show()

	// FIXME isAuthenticated cause sessionExpired signal on server but it isn't handled
	// FIXME because we call component.startup() after (application connects on this signal in onInit method)
	Promise.all([
		BaseAuth.isAuthenticated(),
		dispatchServer.getClientUiSettings(),
	])
		.then(async ([session, uiSettings]) => {
			app.initTitle(uiSettings.appTitle?.short)
			app.parseHashParamList() // NOTE: Parse hash url string
			await loadApp()
			if (session) {
				const { start } = await import('@/core/startup')
				start(session)
			} else {
				showLogin(uiSettings.appTitle?.full)
			}
		})
}

main()
