define([
	'jed',
	'core/config',
	'webapi/main/Session',
], function(
	Jed,
	{ config },
	{ Session },
) {
	async function loadTranslationVariants() {
		try {
			if (!Session.current) {
				// FIXME Special case for 'load' module. It imports translations before session initializing.
				// FIXME Also for login page, there is no session here.
				return {}
			}

			const session = await Session.ready()
			const settings = await session.getClientUiSettingsPromise()
			return settings?.features?.translationVariants ?? {}
		} catch (e) {
			return {}
		}
	}

	let variants = {}

	const ignoreList = []

	return {
		async loadTranslationVariants() {
			variants = await loadTranslationVariants()
		},

		getTranslationVariants() {
			return variants
		},

		// WARNING: Do not make this function async!
		// WARNING: Base javascript 'import' is synchronous.
		// WARNING: In case of async load a promise will be returned as result of 'import' operation.
		load(id, require, callback) {
			const idArray = id.split('/')
			let messages = {}

			if (!/^(en|auto)$/.test(config.locale) && ignoreList.indexOf(id) < 0) {
				const languageVariant = variants[config.locale] || ""
				const language = config.locale + (languageVariant.length > 0 ? ("@" + languageVariant) : "")
				try {
					if (idArray[0] !== 'modules') {
						messages = cjsRequire(`@/locales/${idArray.join('/')}/messages.${language}.po`)
					} else {
						idArray.shift()
						const moduleName = idArray.shift()
						messages = cjsRequire(`@/modules/${moduleName}/locales/${idArray.join('/')}/messages.${language}.po`)
					}
				} catch (error) {
					console.error(error)
				}
			}

			callback(new Jed(messages))
		}
	}
})
